import { NotificationManager } from "react-notifications";
import { BehaviorSubject } from "rxjs";
import {
  CreateDraftResult,
  Draft2,
  Drafts2,
  ErrorResponse,
  NewDratfUpdateRequest,
} from "../models/drafts";
import AuthStore from "./AuthStore";
import { postData } from "./BaseStore";

class DraftStore {
  private static _instance: DraftStore;
  public static get Instance() {
    return this._instance || (this._instance = new DraftStore());
  }

  private currentSessionToken = "";
  public constructor() {
    AuthStore.Instance.getUser().subscribe((x) => {
      this.currentSessionToken = x?.SessionToken || "";
    });
  }

  private currentDraftsRequestLoading = new BehaviorSubject<boolean>(false);
  public getDraftsLoading() {
    return this.currentDraftsRequestLoading.asObservable();
  }
  private currentDraftsRequest?: {
    abort: () => void;
    ready: Promise<Response>;
  };
  private draftQueue: {
    editDraft?: { /*uuid: string,*/ draftuuid: string; draft?: Draft2 };
    addDraft?: { uuid?: string; amount?: number };
    deleteDraft?: { draftuuid: string };
    // deleteItemFromDraft?: { uuid: string, draftuuid: string }
  }[] = []; // the idea is to store data from parameters of method calls
  private drafts = new BehaviorSubject<Draft2[]>([]);

  public getDrafts() {
    return this.drafts.asObservable();
  }
  private postProcessDrafts(x: Drafts2 | undefined) {
    x?.drafts.sort((a, b) => {
      // TODO: ask about this
      // if (a.creationdate && b.creationdate) {
      //   return b.creationdate.localeCompare(a.creationdate);
      // }
      return a.number.localeCompare(b.number);
    });
    x?.drafts.forEach((d) => {
      d.ndses = (
        Array.from(new Set(d.items?.map((i) => i.nds || "") || []).keys()) || []
      ).join(",");
    });
  }
  public initDrafts() {
    if (this.currentDraftsRequest) {
      // this.currentDrafts.abort();
      return;
    }

    const fin = () => {
      this.currentDraftsRequest = undefined;
      this.currentDraftsRequestLoading.next(false);
      if (this.draftQueue.length > 0) {
        this.processDraftQueue();
      }
      // else { this.serverProcessing.next(false); }
    };
    this.currentDraftsRequestLoading.next(true);
    this.currentDraftsRequest = postData(
      `${process.env.REACT_APP_BASE_URL}/f/getdrafts2`,
      {
        SessionToken: this.currentSessionToken,
      }
    );
    return this.currentDraftsRequest.ready
      .then((x) => (x.ok ? x.json() : fin()))
      .then((x: Drafts2 | undefined) => {
        this.postProcessDrafts(x);
        this.drafts.next(x?.drafts || []);
        fin();
      })
      .catch((error) => {
        fin();
      });
  }
  private initDraftUpdate(
    draftuuid: string,
    alreadyQueued: (draft: Draft2) => void,
    finalProcessing: (draft: Draft2) => void
  ) {
    const drafts = this.drafts.value || [];
    let alreadyQueuedDraft = this.draftQueue.find(
      (d) => d.editDraft && d.editDraft.draftuuid === draftuuid
    );
    if (!alreadyQueuedDraft) {
      const draft = drafts.find((d) => d.draftuuid === draftuuid);
      if (draft) {
        alreadyQueuedDraft = { editDraft: { draftuuid, draft } };
        alreadyQueued(draft);
        this.draftQueue.push(alreadyQueuedDraft);
      }
    }

    if (
      alreadyQueuedDraft &&
      alreadyQueuedDraft.editDraft &&
      alreadyQueuedDraft.editDraft.draft
    ) {
      const d = alreadyQueuedDraft.editDraft.draft;
      finalProcessing(d);
      this.drafts.next(drafts);
      this.processDraftQueue();
    }
  }

  public deleteLineFormDraft(uuid: string, draftuuid: string) {
    this.initDraftUpdate(
      draftuuid,
      (draft) => {},
      (draft) => {
        draft.items = draft.items?.filter((i) => i.uuid !== uuid);
      }
    );
    // const drafts = this.drafts.value || [];
    // let alreadyQueuedDraft = this.draftQueue.find(d => d.editDraft && d.editDraft.draftuuid === draftuuid);
    // if (!alreadyQueuedDraft) {
    //     const draft = drafts.find(d => d.draftuuid === draftuuid);
    //     if (draft) {
    //         alreadyQueuedDraft = { editDraft: { draftuuid, draft } };
    //         this.draftQueue.push(alreadyQueuedDraft);
    //     }
    // }

    // if (alreadyQueuedDraft && alreadyQueuedDraft.editDraft && alreadyQueuedDraft.editDraft.draft) {
    //     const d = alreadyQueuedDraft.editDraft.draft;
    //     d.items = d.items?.filter(i => i.uuid !== uuid);
    //     this.drafts.next(drafts);
    //     this.processDraftQueue();
    // }
  }
  public addToDraft(
    uuid: string,
    draftuuid: string,
    amount: number,
    setAmount?: boolean
  ) {
    this.initDraftUpdate(
      draftuuid,
      (draft) => {
        let item = draft.items?.find((x) => x.uuid === uuid);
        if (item) {
          item.serverProcessing = true;
        }
      },
      (draft) => {
        let item = draft.items?.find((i) => i.uuid === uuid);
        if (!item) {
          item = { uuid, quantity: amount };
          draft.items?.push(item);
        } else {
          item.quantity = setAmount ? amount : (item.quantity || 0) + amount;
          item.amount = item.quantity;
        }
      }
    );

    // const drafts = this.drafts.value || [];
    // let alreadyQueuedDraft = this.draftQueue.find(d => d.editDraft && d.editDraft.draftuuid === draftuuid);
    // if (!alreadyQueuedDraft) {
    //     const draft = drafts.find(d => d.draftuuid === draftuuid);
    //     if (draft) {
    //         alreadyQueuedDraft = { editDraft: { draftuuid, draft } };
    //         let item = draft.items?.find(x => x.uuid === uuid);
    //         if (item) { item.serverProcessing = true; }
    //         this.draftQueue.push(alreadyQueuedDraft);
    //     }
    // }

    // if (alreadyQueuedDraft && alreadyQueuedDraft.editDraft && alreadyQueuedDraft.editDraft.draft) {
    //     // alreadyQueuedDraft.editDraft.draft. = setAmount ? amount : (alreadyQueuedDraft.addDraft.amount +);
    //     const d = alreadyQueuedDraft.editDraft.draft;
    //     let item = d.items?.find(i => i.uuid === uuid);
    //     if (!item) {
    //         item = { uuid, quantity: amount };
    //         d.items?.push(item);
    //     } else {
    //         item.quantity = setAmount ? amount : ((item.quantity || 0) + amount);
    //         item.amount = item.quantity;
    //     }

    //     this.drafts.next(drafts);
    //     this.processDraftQueue();
    // }
  }
  public updateComment(draftuuid: string, comment: string) {
    this.initDraftUpdate(
      draftuuid,
      (draft) => {
        draft.comment = comment;
        draft.commentSP = true;
      },
      (draft) => {
        draft.comment = comment;
        draft.commentSP = true;
      }
    );

    // const drafts = this.drafts.value || [];
    // let alreadyQueuedDraft = this.draftQueue.find(d => d.editDraft && d.editDraft.draftuuid === draftuuid);
    // if (!alreadyQueuedDraft) {
    //     const draft = drafts.find(d => d.draftuuid === draftuuid);
    //     if (draft) {
    //         alreadyQueuedDraft = { editDraft: { draftuuid, draft } };
    //         draft.comment = comment;
    //         draft.commentSP = true;
    //         this.draftQueue.push(alreadyQueuedDraft);
    //     }
    // }

    // if (alreadyQueuedDraft && alreadyQueuedDraft.editDraft && alreadyQueuedDraft.editDraft.draft) {

    //     const d = alreadyQueuedDraft.editDraft.draft;

    //     d.comment = comment;
    //     d.commentSP = true;

    //     this.drafts.next(drafts);
    //     this.processDraftQueue();
    // }
  }

  public updateCredWeek(draftuuid: string, credit_week: number) {
    this.initDraftUpdate(
      draftuuid,
      (draft) => {
        draft.credit_week = credit_week;
        draft.credWeekSP = true;
      },
      (draft) => {
        draft.credit_week = credit_week;
        draft.credWeekSP = true;
      }
    );
  }

  public updateBuyer(draftuuid: string, buyer_guid: string | undefined) {
    this.initDraftUpdate(
      draftuuid,
      (draft) => {
        draft.buyer_guid = buyer_guid;
        draft.buyerSP = true;
      },
      (draft) => {
        draft.buyer_guid = buyer_guid;
        draft.buyerSP = true;
      }
    );
  }

  public updateAdress(draftuuid: string, adress_guid: string) {
    this.initDraftUpdate(
      draftuuid,
      (draft) => {
        draft.adress_guid = adress_guid;
        draft.addrSP = true;
      },
      (draft) => {
        draft.adress_guid = adress_guid;
        draft.addrSP = true;
      }
    );
  }

  public updateOurLegal(draftuuid: string, org_guid: string) {
    this.initDraftUpdate(
      draftuuid,
      (draft) => {
        draft.org_guid = org_guid;
        draft.orgSP = true;
      },
      (draft) => {
        draft.org_guid = org_guid;
        draft.orgSP = true;
      }
    );
  }

  public updateWh(draftuuid: string, wh_guid: string) {
    this.initDraftUpdate(
      draftuuid,
      (draft) => {
        draft.warehouse_guid = wh_guid;
        draft.wh_guidSP = true;
      },
      (draft) => {
        draft.warehouse_guid = wh_guid;
        draft.wh_guidSP = true;
      }
    );
  }

  public addEmptyDraft(): Promise<unknown> {
    var promiseResolve: { (arg0: string): void; (value: unknown): void },
      promiseReject: { (arg0: null): void; (reason?: any): void };
    var promise = new Promise(function (resolve, reject) {
      promiseResolve = resolve;
      promiseReject = reject;
    });

    let req = postData(`${process.env.REACT_APP_BASE_URL}/f/createdraft2`, {
      SessionToken: this.currentSessionToken,
      item: null,
    });
    req.ready
      .then((x) => (x.ok ? x.json() : promiseReject(null)))
      .then((x: CreateDraftResult | undefined) => {
        if (x) {
          this.initDrafts();
          promiseResolve(x.orderuuid);
        } else {
          promiseReject(null);
        }
      })
      .catch((error) => {
        promiseReject(error);
      });

    return promise;
  }
  public deleteDraft(uuid: string): Promise<unknown> {
    var promiseResolve: { (arg0: string): void; (value: unknown): void },
      promiseReject: { (arg0: null): void; (reason?: any): void };
    var promise = new Promise(function (resolve, reject) {
      promiseResolve = resolve;
      promiseReject = reject;
    });

    let req = postData(`${process.env.REACT_APP_BASE_URL}/f/deletedraft2`, {
      SessionToken: this.currentSessionToken,
      uuid: uuid,
    });
    req.ready
      .then((x) => (x.ok ? x.json() : promiseReject(null)))
      .then((x: CreateDraftResult | undefined) => {
        this.initDrafts();
        promiseResolve(true);
      })
      .catch((error) => {
        promiseResolve(true);
      });

    return promise;
  }
  private processDraftQueue() {
    if (this.currentDraftsRequest) {
      return;
    }

    const fin = () => {
      this.currentDraftsRequest = undefined;
      if (this.draftQueue.length > 0) {
        this.processDraftQueue();
      }
      // else { this.serverProcessing.next(false); }
    };

    // this.serverProcessing.next(true);

    // TODO: change all of this to perform ONE operation in one time. Block UI unil it ends
    if (this.draftQueue.length === 0) {
      fin();
      return;
    }

    let item = this.draftQueue.shift();
    if (item?.addDraft) {
      let req: any = { SessionToken: this.currentSessionToken };
      if (item?.addDraft?.uuid && item?.addDraft?.amount) {
        req = {
          SessionToken: this.currentSessionToken,
          item: {
            uuid: item?.addDraft?.uuid,
            quantity: item?.addDraft?.amount || 0,
          },
        };
      }
      this.currentDraftsRequest = postData(
        `${process.env.REACT_APP_BASE_URL}/f/createDraft`,
        req
      );
    } else if (item?.deleteDraft) {
    } else if (item?.editDraft) {
      let req: NewDratfUpdateRequest = {
        session_token: this.currentSessionToken,
        adress_guid:
          item?.editDraft?.draft?.adress_guid ||
          "46f73d74-660c-11ee-a945-00155d4b0700",
        order_guid: item?.editDraft?.draft?.draftuuid || "",
        comment: item?.editDraft?.draft?.comment || "",
        credit_week: item?.editDraft?.draft?.credit_week || 2,
        buyer_guid: item?.editDraft?.draft?.buyer_guid || undefined,
        org_guid: item?.editDraft?.draft?.org_guid || undefined,
        delivery_method: "delivery",
        wh_guid: item?.editDraft?.draft?.warehouse_guid,
      };
      req.items = item?.editDraft?.draft?.items?.map((i) => ({
        sku_uuid: i.uuid,
        quantity: i.quantity,
      }));
      this.currentDraftsRequest = postData(
        `${process.env.REACT_APP_BASE_URL}/f/newupdatedraft`,
        req
      );
    }

    if (!this.currentDraftsRequest) {
      fin();
      return;
    }

    this.currentDraftsRequest.ready
      .then((x) => (x.ok ? x.json() : fin()))
      .then((x: Drafts2 & ErrorResponse) => {
        if (x.success !== "false") {
          this.postProcessDrafts(x);
          this.drafts.next(this.updateDraftsByQueue(x?.drafts || []));
          NotificationManager.success("Черновик успешно обновлен!");
        } else {
          throw new Error(x.status);
        }
        fin();
      })
      .catch((error) => {
        NotificationManager.error(error.message);
        fin();
      });
  }

  private updateDraftsByQueue(drafts: Draft2[]) {
    this.draftQueue.forEach((dq) => {
      const d = drafts.find((x) => x.draftuuid === dq.editDraft?.draftuuid);
      if (d) {
        dq.editDraft?.draft?.items?.forEach((xx) => {
          const r = d.items?.find((yy) => yy.uuid === xx.uuid);
          if (r) {
            r.serverProcessing = xx.serverProcessing;
            r.amount = xx.amount;
            r.quantity = xx.quantity;
          }
        });
      }
    });
    return drafts;
  }
}

export default DraftStore;
