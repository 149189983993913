import * as React from 'react';
import Icons from '../img/calider_icons.svg';

export const enum CaliderIconName {
    late = 'late',
    now = 'now',
    incoming = 'incoming',
    awaiting = 'awaiting',
    newc = 'newc',
    la = 'la',
    ra = 'ra',
    bla = 'bla',
    bra = 'bra',
}

const iconSizes: { [id in CaliderIconName]: { w: number, h: number } } = {
    late: { w: 20, h: 24 },
    now: { w: 20, h: 24 },
    incoming: { w: 20, h: 24 },
    awaiting: { w: 20, h: 24 },
    newc: { w: 21, h: 20 },
    la: { w: 6, h: 10 },
    ra: { w: 6, h: 10 },
    bla: { w: 11, h: 20 },
    bra: { w: 11, h: 20 },
};

const CaliderIcon = ({ name, className }: { name: CaliderIconName, className?: string }) => {
    const size = iconSizes[name] || { w: 20, h: 24 };
    return (
        <svg width={size.w} height={size.h} className={className}>
            <use href={`${Icons}#icon-${name}`} />
        </svg>
    )
}

export default CaliderIcon;
