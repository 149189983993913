import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { WhItem } from "../models/info";

interface MainWhState {
  mainWh?: string;
  whList?: Array<WhItem>;
  loading: boolean;
  setMainWh: (value: string) => void;
  setWhList: (value: Array<WhItem>) => void;
  setLoadingWhList: (value: boolean) => void;
}

const useMainWhStore = create<MainWhState>()(
  immer(
    devtools(
      persist(
        (set) => ({
          mainWh: undefined,
          whList: undefined,
          loading: false,
          setMainWh(value: string) {
            set({ mainWh: value });
          },
          setWhList(value: Array<WhItem>) {
            set({ whList: value });
          },
          setLoadingWhList(value: boolean) {
            set({ loading: value });
          },
        }),
        {
          name: "main-wh",
          partialize: (state) => ({ mainWh: state.mainWh }),
        }
      )
    )
  )
);

export default useMainWhStore;
