import * as React from 'react';
import Icons from '../img/listing_icons.svg';

export const enum ListingIconName {
    heart = 'heart',
    heartfilled = 'heartfilled',
    burger = 'burger',
    squares = 'squares',
}

const iconSizes: { [id in ListingIconName]: { w: number, h: number } } = {
    heart: { w: 12, h: 11 },
    heartfilled: { w: 10, h: 9 },
    burger: { w: 25, h: 22 },
    squares: { w: 23, h: 22 },
};

const ListingIcon = ({ name, className }: { name: ListingIconName, className?: string }) => {
    const size = iconSizes[name] || { w: 20, h: 24 };
    return (
        <svg width={size.w} height={size.h} className={className}>
            <use href={`${Icons}#icon-${name}`} />
        </svg>
    )
}

export default ListingIcon;
