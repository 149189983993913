import { CircularProgress } from "@material-ui/core";
import React from "react";
import { Draft2 } from "../../models/drafts";
import DraftList from "./DraftList";
import styles from "./DraftUndReservesSelector.module.css";

// const DraftUndReservesSelector = ({ onDraftSelect }: { onDraftSelect: (draft: Draft) => void }) => {
//     return <div className={styles.modal_pane}>
//         <DraftList onDraftSelect={onDraftSelect}></DraftList>
//         <DraftList onDraftSelect={onDraftSelect}></DraftList>
//     </div>
// }
interface DraftUndReservesSelectorProps {
  onDraftSelect: (draft: Draft2) => void;
  loading: boolean;
}

class DraftUndReservesSelector extends React.Component<DraftUndReservesSelectorProps> {
  public onDraftSelect: (draft: Draft2) => void;
  public loading: boolean;
  // private hndlr: (draft: Draft) => void;
  constructor(props: DraftUndReservesSelectorProps) {
    super(props);
    this.onDraftSelect = props.onDraftSelect;
    this.loading = props.loading;
  }

  render() {
    return (
      <div className={styles.modal_pane}>
        {this.loading ? (
          <div className={styles.loadingWrapper}>
            <CircularProgress />
          </div>
        ) : (
          <DraftList
            onDraftSelect={(i) => {
              this.onDraftSelect(i);
            }}
          />
        )}
      </div>
    );
  }
}

export default DraftUndReservesSelector;
