import { NotificationManager } from "react-notifications";
import { GetSalesResponse, ReleaseResponse, SaleItem } from "../models/release";
import { postData } from "../stores/BaseStore";

export const release = async (
  session_token: string,
  order_guid: string
): Promise<ReleaseResponse | undefined> => {
  let data: ReleaseResponse | undefined;
  const response = await postData(
    `${process.env.REACT_APP_BASE_URL}/f/release`,
    {
      session_token,
      order_guid,
    }
  ).ready;
  data = await response.json();
  if (!response.ok || data?.success !== "true") {
    NotificationManager.error(`Ошибка при создании заказа: ${data?.status}`);
    return;
  }

  return data;
};

export const getSales = async (
  session_token: string
): Promise<Array<SaleItem> | undefined> => {
  try {
    const response = await postData(
      `${process.env.REACT_APP_BASE_URL}/f/getsales`,
      {
        session_token,
      }
    ).ready;
    let data: GetSalesResponse | undefined;
    data = await response.json();
    return data?.drafts;
  } catch (error) {
    console.error(error);
    NotificationManager.error("Ошибка при получении списка отгрузок");
  }
};
