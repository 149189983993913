import { IconButton, Tooltip } from "@material-ui/core";
import CloudDownload from "@material-ui/icons/CloudDownload";
import { MouseEventHandler } from "react";
import { Draft2 } from "../../models/drafts";
import { OneReserveResponse } from "../../models/reserves";
import styles from "./MerchDraftLine.module.css";

interface MerchDraftLineProps {
  position: Draft2 | OneReserveResponse;
  onClick: MouseEventHandler<HTMLDivElement> | undefined;
  onDelete: (draft: Draft2 | OneReserveResponse) => void;
  type: "reserve" | "draft";
  // only for type: "reserve"
  handleLoadInvoice?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    uuid: string
  ) => void;
}

const MerchDraftLine = (props: MerchDraftLineProps) => {
  const { position, onClick, onDelete, type, handleLoadInvoice } = props;

  return (
    <div className={styles.line} onClick={onClick}>
      {type === "draft" && (
        <div
          className={styles.deleter}
          onClick={(e) => {
            onDelete(position);
            e.stopPropagation();
            return true;
          }}
        >
          <svg width='13' height='13' viewBox='0 0 13 13' fill='none'>
            <path d='M3.18213 9.54599L9.54609 3.18203' stroke='#3A3D4B' />
            <path d='M9.5459 9.54599L3.18194 3.18203' stroke='#3A3D4B' />
          </svg>
        </div>
      )}
      <div className={styles.date}>
        {position.cdate || position.creationdate}
      </div>
      <div className={styles.order}>{position.number}</div>
      <div className={styles.legal}>{position.buyer_name || ""}</div>
      <div className={styles.sum}>{position.sum}</div>
      <div className={styles.comment}>
        <Tooltip title={<>{position.comment || ""}</>} placement='top'>
          <p className={styles.commentText}>{position.comment}</p>
        </Tooltip>
      </div>
      {type === "reserve" && (
        <div className={styles.getInvoice}>
          <Tooltip title='Скачать счет' placement='top'>
            <IconButton
              aria-label='Скачать счет'
              onClick={(event) =>
                handleLoadInvoice?.(event, position.draftuuid)
              }
            >
              <CloudDownload />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default MerchDraftLine;
