import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { LegalEntity, OurLegalEntity } from "../../models/info";
import { BankSuggest, OrgSuggest } from "../../models/orgs";
import { getOurLegal } from "../../services/infoServices";
import AuthStore from "../../stores/AuthStore";
import InfoStore from "../../stores/InfoStore";
import useCheckoutStore from "../../zustand/checkoutStore";
import styles from "./CheckoutLegalEntsSelector.module.css";

// for test dadata
//7707083893
//044525225

interface CheckoutLegalEntsSelectorProps {
  onFinish: () => void;
}

const CheckoutLegalEntsSelector = (props: CheckoutLegalEntsSelectorProps) => {
  const { onFinish } = props;
  const store = InfoStore.Instance;
  const authStore = AuthStore.Instance;
  const token = authStore.currentSessionToken;
  const { legal, setLegal, ourLegaL, setOurLegal } = useCheckoutStore();
  const [addMode, setAddMode] = useState(false);
  const [orgFound, setOrgFound] = useState<
    { org?: OrgSuggest; bank?: BankSuggest; error?: string } | undefined
  >(undefined);
  const [INN, setINN] = useState<string>("");
  const [BIK, setBIK] = useState<string>("");
  const [orgProgress, setOrgProgress] = useState<boolean>(false);
  const [loadingLegals, setLoadingLegals] = useState<boolean>(false);
  const [loadingOurLegals, setLoadingOurLegals] = useState<boolean>(false);

  const [legalState, setLegalState] = useState<string>("");
  const [ourLegalState, setOurLegalState] = useState<string>("");
  const [acc, setAcc] = useState("");
  const [accepted, setAccepted] = useState(false);

  const [legals, setLegals] = useState<Array<LegalEntity>>([]);
  const [ourLegals, setOurLegals] = useState<Array<OurLegalEntity>>([]);
  const [allowedOurLegals, setAllowedOurLegals] = useState<Array<string>>([]);

  const handleSetAddMode = (val: boolean) => {
    setAddMode(val);
  };

  const handleLegalChange = (event: any) => {
    setLegalState(event.target.value);
    setOurLegalState("");
    const legalObject = legals.find(
      (legal) => legal.buyer_guid === event.target.value
    );
    const ourLegalOfLegalObject =
      legalObject?.org.map((item) => item.org_guid) || [];
    setAllowedOurLegals(ourLegalOfLegalObject);
  };

  const handleBIKChange = (event: any) => {
    setBIK(event.target.value);
  };

  const handleInnChange = (event: any) => {
    setINN(event.target.value);
  };

  const handleSearchOrg = () => {
    store.suggestByInn(INN, BIK);
  };

  const handleClearINN = () => {
    setINN("");
    setBIK("");
    setOrgFound(undefined);
  };

  const handleAccChange = (event: any) => {
    setAcc(event.target.value);
  };

  const handleAcceptChange = (event: any) => {
    setAccepted(event.target.checked);
  };

  const handleContinueWithNew = () => {
    setLegal({
      buyer_guid: "",
      newLegal: {
        bank: { ...orgFound?.bank, checkingAccount: acc },
        org: orgFound?.org,
      },
    });
    onFinish();
  };

  const handleContinueWithSelected = () => {
    setLegal({
      buyer_guid: legalState,
      newLegal: {
        bank: undefined,
        org: undefined,
      },
    });
    setOurLegal(ourLegalState);
    onFinish();
  };

  useEffect(() => {
    const loading = store.getGetLegalLoading().subscribe((value) => {
      setLoadingLegals(value);
    });
    const subscription = store.getGetLegal().subscribe((value) => {
      if (value?.ents) {
        setLegals(value.ents);
      }
    });
    store.getLegal();
    return () => {
      subscription.unsubscribe();
      loading.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callGetOurLegal = async () => {
      try {
        setLoadingOurLegals(true);
        const data = await getOurLegal(token);
        if (data) {
          setOurLegals(data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingOurLegals(false);
      }
    };
    callGetOurLegal();
  }, [token]);

  useEffect(() => {
    const subscription = store.getSuggestByInn().subscribe((value) => {
      setOrgFound(value);
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = store.getSuggestByInnLoading().subscribe((value) => {
      setOrgProgress(value);
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: некорректно фильтруется список юрлиц
  // useEffect(() => {
  //   if (legal.buyer_guid) {
  //     setLegalState(legal.buyer_guid);
  //   }
  //   if (ourLegaL) {
  //     setOurLegalState(ourLegaL);
  //   }
  //   if (legal.newLegal) {
  //     setAcc(legal.newLegal.bank?.checkingAccount || "");
  //     setINN(legal.newLegal.org?.data?.inn || "");
  //     setBIK(legal.newLegal.bank?.data?.bic || "");
  //   }
  // }, [legal.buyer_guid, legal.newLegal, ourLegaL]);

  return (
    <>
      <div className={styles.top_pane}>
        <div className={styles.pane}>
          <div className={styles.pane_header}>Юридическое лицо Контрагент</div>
          {loadingLegals ? (
            <div className={styles.loadingWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <FormControl variant='outlined' className={styles.form_control}>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Юридическое лицо Контрагент
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  value={legalState}
                  onChange={handleLegalChange}
                  label='Юридическое лицо Контрагент'
                >
                  {legals.map((legal) => (
                    <MenuItem key={legal.buyer_guid} value={legal.buyer_guid}>
                      {legal.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <Button
                className={styles.continue}
                variant='contained'
                color='primary'
                disableElevation
                disabled={!legalState}
                onClick={() => {
                  handleContinueWithSelected();
                }}
              >
                Продолжить с выбранным юридическим лицом
              </Button> */}
            </>
          )}
        </div>

        <div className={styles.pane2}>
          <div className={styles.pane_header}>Юридическое лицо Поставщик</div>
          {loadingOurLegals ? (
            <div className={styles.loadingWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <FormControl
                disabled={!legalState}
                variant='outlined'
                className={styles.form_control}
              >
                <InputLabel id='demo-simple-select-outlined-label'>
                  Юридическое лицо Поставщик
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  value={ourLegalState}
                  onChange={(e) => setOurLegalState(e.target.value as string)}
                  disabled={!legalState}
                  label='Юридическое лицо Поставщик'
                >
                  {ourLegals
                    .filter((value) =>
                      allowedOurLegals.includes(value.org_guid)
                    )
                    .map((legal) => (
                      <MenuItem key={legal.org_guid} value={legal.org_guid}>
                        {legal.Name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Button
                className={styles.continue}
                variant='contained'
                color='primary'
                disableElevation
                disabled={!legalState || !ourLegalState}
                onClick={() => {
                  handleContinueWithSelected();
                }}
              >
                Продолжить с выбранными юридическими лицами
              </Button>
            </>
          )}
          {/* <div className={styles.pane_header}>
            Создать новое юридическое лицо
          </div>
          <InputMask mask='9999999999' value={INN} onChange={handleInnChange}>
            {() => (
              <TextField
                id='outlined-basic'
                label='ИНН'
                variant='outlined'
                className={styles.txt_input_first}
              />
            )}
          </InputMask>
          <InputMask mask='999999999' value={BIK} onChange={handleBIKChange}>
            {() => (
              <TextField
                id='outlined-basic'
                label='БИК'
                variant='outlined'
                className={styles.txt_input}
              />
            )}
          </InputMask>
          <InputMask
            mask='99999999999999999999'
            value={acc}
            onChange={handleAccChange}
          >
            {() => (
              <TextField
                id='outlined-basic'
                label='Расчетный счет'
                variant='outlined'
                className={styles.txt_input_last}
              />
            )}
          </InputMask>
          <Button
            className={styles.continue}
            variant='contained'
            color='primary'
            disabled={!accepted || !INN || !BIK || !acc}
            disableElevation
            onClick={() => {
              handleSearchOrg();
              handleSetAddMode(true);
            }}
          >
            Добавить и продолжить заказ
          </Button>
          <FormControlLabel
            className={styles.check_cb}
            onChange={handleAcceptChange}
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                name='checkedI'
              />
            }
            label={
              <span className={styles.check_cb_txt}>
                {' '}
                Нажимая на кнопку «Продолжить» вы соглашаетесь с офертой и
                условиями обработки персональных данных
              </span>
            }
          /> */}
        </div>
      </div>
      {/* For create legal with dadata */}
      {/* <Modal
        open={addMode}
        onClose={() => {
          handleSetAddMode(false);
        }}
      >
        <DialogContent className={styles.modal}>
          <div className={styles.modal_pane}>
            {orgProgress ? (
              <div className={styles.loadingDadataWrapper}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <div className={styles.org_info}>
                  <div className={styles.org_info_header}>
                    Проверьте данные о компании
                  </div>
                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>
                      Полное наименование
                    </div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.org?.data?.name?.full_with_opf || '-'}
                    </div>
                  </div>

                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>
                      Сокращенное наименование
                    </div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.org?.data?.name?.short_with_opf || '-'}
                    </div>
                  </div>

                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>
                      Юридический адрес
                    </div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.org?.data?.address?.unrestricted_value || '-'}
                    </div>
                  </div>

                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>ОГРН</div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.org?.data?.ogrn || '-'}
                    </div>
                  </div>

                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>ИНН</div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.org?.data?.inn || '-'}
                    </div>
                  </div>

                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>КПП</div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.org?.data?.kpp || '-'}
                    </div>
                  </div>

                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>ОКПО</div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.org?.data?.okpo || '-'}
                    </div>
                  </div>

                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>ОКОГУ</div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.org?.data?.okogu || '-'}
                    </div>
                  </div>

                  <div
                    className={[styles.org_info_line, styles.line_hard].join(
                      ' '
                    )}
                  >
                    <div className={styles.org_info_line_header}>ОКВЭД</div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.org?.data?.okved || '-'}
                    </div>
                  </div>

                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>
                      Расчетный счет
                    </div>
                    <div className={styles.org_info_line_val}>{acc}</div>
                  </div>

                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>Банк</div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.bank?.unrestricted_value &&
                      orgFound?.bank?.data?.payment_city
                        ? orgFound?.bank?.unrestricted_value +
                          ', ' +
                          orgFound?.bank?.data?.payment_city
                        : '-'}
                    </div>
                  </div>

                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>БИК</div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.bank?.data?.bic || '-'}
                    </div>
                  </div>

                  <div
                    className={[styles.org_info_line, styles.line_hard].join(
                      ' '
                    )}
                  >
                    <div className={styles.org_info_line_header}>
                      Корреспондентский счет
                    </div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.bank?.data?.correspondent_account || '-'}
                    </div>
                  </div>

                  <div className={styles.org_info_line}>
                    <div className={styles.org_info_line_header}>
                      Генеральный директор
                    </div>
                    <div className={styles.org_info_line_val}>
                      {orgFound?.org?.data?.management?.name || '-'}
                    </div>
                  </div>
                </div>
                <div className={styles.modal_pane_buttons}>
                  <Button
                    className={styles.modal_yes_button}
                    variant='contained'
                    color='primary'
                    disabled={!orgFound}
                    disableElevation
                    onClick={() => {
                      handleContinueWithNew();
                    }}
                  >
                    Добавить и продолжить
                  </Button>
                  <div>&nbsp;</div>
                  <Button
                    className={styles.modal_no_button}
                    variant='contained'
                    color='secondary'
                    disableElevation
                    onClick={() => {
                      handleSetAddMode(false);
                    }}
                  >
                    Отмена
                  </Button>
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Modal> */}
    </>
  );
};

export default CheckoutLegalEntsSelector;
