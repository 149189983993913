import { CircularProgress } from "@material-ui/core";
import { useEffect } from "react";
import AuthStore from "../../../../stores/AuthStore";
import useSalesStore from "../../../../zustand/salesStore";
import SubHeader from "../../../layout/SubHeader";
import styles from "../styles.module.css";
import SalesTable from "./SalesTable/SalesTable";

export const Sales = () => {
  const authStore = AuthStore.Instance;
  const token = authStore.currentSessionToken;
  const loading = useSalesStore((state) => state.loading);
  const fetchSales = useSalesStore((state) => state.fetchSales);

  useEffect(() => {
    fetchSales(token);
  }, [fetchSales, token]);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.header}>
        <SubHeader />
      </div>
      {loading ? (
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <SalesTable />
      )}
    </div>
  );
};
