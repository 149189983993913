import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { BankSuggest, OrgSuggest } from '../models/orgs';

interface CheckoutState {
  legal: {
    buyer_guid: string;
    newLegal: {
      org?: OrgSuggest;
      bank?: BankSuggest;
    };
  };
  ourLegaL: string;
  address: {
    adress_guid: string;
    comment: string;
    newAdress?: {
      city: string;
      street: string;
      houseNumber: string;
      buildingNumber?: string;
      entranceNumber?: string;
      structureNumber?: string;
      officeNumber?: string;
    };
  };
  pdn?: {
    name: string;
    surname: string;
    patronimic: string;
    phone: string;
    mail: string;
    password: string;
  };
  setOurLegal: (value: CheckoutState['ourLegaL']) => void;
  setLegal: (value: CheckoutState['legal']) => void;
  setPdn: (value: CheckoutState['pdn']) => void;
  setAddress: (value: CheckoutState['address']) => void;
  reset: () => void;
}

const initialState = {
  legal: {
    buyer_guid: '',
    newLegal: {
      bank: undefined,
      org: undefined,
    },
  },
  ourLegaL: '',
  address: {
    adress_guid: '',
    comment: '',
    newAdress: undefined,
  },
  pdn: undefined,
};

const useCheckoutStore = create<CheckoutState>()(
  devtools((set) => ({
    ...initialState,
    setOurLegal(value: CheckoutState['ourLegaL']) {
      set((state) => ({
        ...state,
        ourLegaL: value,
      }));
    },
    setLegal(value: CheckoutState['legal']) {
      set((state) => ({ ...state, legal: value }));
    },
    setAddress(value: CheckoutState['address']) {
      set((state) => ({ ...state, address: value }));
    },
    setPdn(value: CheckoutState['pdn']) {
      set((state) => ({ ...state, pdn: value }));
    },
    reset: () => {
      set(initialState);
    },
  }))
);

export default useCheckoutStore;
