import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import { ChangeEvent, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import { SaleItem } from "../../../../../models/release";
import { getInvoiceFile } from "../../../../../services/infoServices";
import AuthStore from "../../../../../stores/AuthStore";
import { formatDate } from "../../../../../utils/utils";
import useSalesStore from "../../../../../zustand/salesStore";
import styles from "../../styles.module.css";

// TODO: for scroll display
// const useStyles = makeStyles({
//   container: {
//     maxHeight: 640,
//   },
// });

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#3A3D4B",
    fontWeight: 600,
    fontFamily: "Raleway, Arial, Helvetica, sans-serif",
  },
  body: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Raleway, Arial, Helvetica, sans-serif",
  },
}))(TableCell);

const initialOrderDirection = "desc";
const initialValueToOrderBy = "creationdate";

const SalesTable = () => {
  const sales = useSalesStore((state) => state.sales);
  const changeSort = useSalesStore((state) => state.changeSort);
  const history = useHistory();
  const authStore = AuthStore.Instance;
  const token = authStore.currentSessionToken;
  const [orderDirection, setOrderDirection] = useState<"desc" | "asc">(
    initialOrderDirection
  );
  const [valueToOrderBy, setValueToOrderBy] = useState<"creationdate">(
    initialValueToOrderBy
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSort = (value: "creationdate") => {
    const isAscending = valueToOrderBy === value && orderDirection === "asc";
    const order = isAscending ? "desc" : "asc";
    setValueToOrderBy(value);
    setOrderDirection(order);
    changeSort(value, order);
  };

  const handleRowClick = (row: SaleItem) => {
    history.push(`/sales/${row.draftuuid}`);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleLoadInvoice = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    uuid: string
  ) => {
    event.stopPropagation();
    const data = await getInvoiceFile({
      order_uuid: uuid,
      session_token: token as string,
    });
    if (data) {
      if (data.base64string) {
        let pdfData = Buffer.from(data.base64string, "base64");
        const blob = new Blob([pdfData], { type: "application/pdf" });
        const link = document.createElement("a");
        link.download = "Счет.pdf";
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        NotificationManager.error("Счет в данный момент не доступен.");
      }
    }
  };

  return (
    <>
      {sales && sales.length ? (
        <>
          <TableContainer>
            <Table aria-label='sales table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Номер</StyledTableCell>
                  <StyledTableCell width='130px'>
                    <TableSortLabel
                      active={valueToOrderBy === "creationdate"}
                      direction={
                        valueToOrderBy === "creationdate"
                          ? orderDirection
                          : "asc"
                      }
                      onClick={() => handleSort("creationdate")}
                    >
                      Дата создания
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>Юридическое лицо Контрагент</StyledTableCell>
                  <StyledTableCell>Юридическое лицо Поставщик</StyledTableCell>
                  <StyledTableCell align='right'>Сумма</StyledTableCell>
                  <StyledTableCell>Примечания</StyledTableCell>
                  <StyledTableCell>Счет</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sales
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((sale) => (
                    <TableRow
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleRowClick(sale);
                      }}
                      hover
                      key={sale.draftuuid}
                    >
                      <StyledTableCell>{sale.number}</StyledTableCell>
                      <StyledTableCell>
                        {formatDate(sale.creationdate)}
                      </StyledTableCell>
                      <StyledTableCell>{sale.buyer_name}</StyledTableCell>
                      <StyledTableCell>{sale.org_name}</StyledTableCell>

                      <StyledTableCell align='right'>
                        {sale.sum}
                      </StyledTableCell>
                      <StyledTableCell>{sale.comment}</StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title='Скачать счет' placement='top'>
                          <IconButton
                            aria-label='Скачать счет'
                            onClick={(event) =>
                              handleLoadInvoice(event, sale.draftuuid)
                            }
                          >
                            <CloudDownload />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            classes={{
              root: styles.pagination,
            }}
            rowsPerPageOptions={[10, 15, 20]}
            component='div'
            count={sales.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Позиций на странице:'
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`
            }
          />
        </>
      ) : (
        <div className={styles.empty}>Нет отгрузок</div>
      )}
    </>
  );
};
export default SalesTable;
