import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router";
import { AdressData, WhItem } from "../../../models/info";
import { getAdresses, saveAdress } from "../../../services/addressServices";
import { createDraftFromCart } from "../../../services/cartServices";
import { getWhList } from "../../../services/infoServices";
import AuthStore from "../../../stores/AuthStore";
import { displayAddress } from "../../../utils/utils";
import useCartStore from "../../../zustand/cartStore";
import useCheckoutStore from "../../../zustand/checkoutStore";
import useMainWhStore from "../../../zustand/mainWhStore";
import styles from "./CheckoutAddressSelector.module.css";

const CheckoutAddressSelector = () => {
  const authStore = AuthStore.Instance;
  const token = authStore.currentSessionToken;
  const { ourLegaL, legal, reset: resetCheckout } = useCheckoutStore();
  const { cartItems, reset: resetCart } = useCartStore();
  const [addressValue, setAddressValue] = useState<string>("");
  const [addresses, setAddresses] = useState<Array<AdressData>>([]);
  const mainWh = useMainWhStore((state) => state.mainWh);
  const [wh, setWh] = useState<string>("");
  const [whList, setWhList] = useState<Array<WhItem>>([]);
  const [comment, setComment] = useState<string>("");
  const [addMode, setAddMode] = useState(false);
  const [agree, setAgree] = useState(false);
  const handleAddressChange = (event: any) => {
    setAddressValue(event.target.value);
  };
  const [loadingFieldData, setLoadingFieldData] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const history = useHistory();
  const [saveAdressForm, setSaveAdressForm] = useState<
    Pick<
      AdressData,
      | "adress_city"
      | "adress_dom"
      | "adress_street"
      | "adress_korpus"
      | "adress_kvartira"
    >
  >({
    adress_city: "",
    adress_street: "",
    adress_dom: "",
    adress_korpus: "",
    adress_kvartira: "",
  });

  const handleSetAddMode = (val: boolean) => {
    setAddMode(val);
  };

  const handleCreateNewDraft = useCallback(
    async (newAdressGuid?: string) => {
      setIsPageLoading(true);
      const adressGuid = newAdressGuid || addressValue;
      const newDraftResponse = await createDraftFromCart(
        token,
        cartItems,
        ourLegaL as string,
        legal.buyer_guid as string,
        adressGuid,
        wh,
        comment
      );
      if (newDraftResponse) {
        NotificationManager.success(
          "Черновик успешно создан! Перевожу на страницу черновика..."
        );
        resetCart();
        resetCheckout();
        useCartStore.persist.clearStorage();
        history.push(`/drafts/${newDraftResponse.order_uuid}`);
      }
      setIsPageLoading(false);
    },
    [
      addressValue,
      cartItems,
      comment,
      history,
      legal.buyer_guid,
      ourLegaL,
      resetCart,
      resetCheckout,
      token,
      wh,
    ]
  );

  const saveNewAdressAndCreateDraft = useCallback(async () => {
    setIsPageLoading(true);
    const {
      adress_city,
      adress_dom,
      adress_street,
      adress_korpus,
      adress_kvartira,
    } = saveAdressForm;
    const data = await saveAdress({
      adress_city,
      SessionToken: token,
      adress_dom,
      adress_street,
      adress_korpus,
      adress_kvartira,
      buyer_guid: legal.buyer_guid,
      // TODO: add type selectors
      dom_type: "дом",
      korpus_type: "корп",
      kvartira_type: "кв.",
    });
    if (data) {
      handleCreateNewDraft(data.adress_guid);
    } else {
      setIsPageLoading(false);
    }
  }, [handleCreateNewDraft, legal.buyer_guid, saveAdressForm, token]);

  const handleSaveAdressField = useCallback(
    (field: keyof AdressData, data: string) => {
      setSaveAdressForm({ ...saveAdressForm, [field]: data });
    },
    [saveAdressForm]
  );

  useEffect(() => {
    const getData = async () => {
      if (legal.buyer_guid) {
        setLoadingFieldData(true);
        try {
          const addressesList = await getAdresses(token, legal.buyer_guid);
          const whListData = await getWhList(token);
          if (addressesList) {
            setAddresses(addressesList);
          }
          if (whListData) {
            setWhList(whListData);
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoadingFieldData(false);
        }
      }
    };
    getData();
  }, [legal.buyer_guid, token]);

  useEffect(() => {
    if (mainWh) {
      setWh(mainWh);
    }
  }, [mainWh]);

  return (
    <>
      {isPageLoading ? (
        <div className={styles.pageLoadingWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <div className={styles.top_pane}>
          <div className={styles.label}>
            <span className={styles.labelText}>
              Ранее использованный адрес доставки
            </span>
          </div>
          {loadingFieldData ? (
            <div className={styles.loadingWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <div className={styles.field}>
              <FormControl variant='outlined' className={styles.form_control}>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Адрес доставки
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  value={addressValue}
                  onChange={handleAddressChange}
                  label='Адрес доставки'
                >
                  {addresses.map((address) => (
                    <MenuItem key={address.guid} value={address.guid}>
                      {displayAddress(address)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div className={styles.label}>
            <span className={styles.labelText}>Склад</span>
          </div>
          {loadingFieldData ? (
            <div className={styles.loadingWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <div className={styles.field}>
              <FormControl variant='outlined' className={styles.form_control}>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Склад
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  value={wh}
                  onChange={(e) => setWh(e.target.value as string)}
                  label='Склад'
                >
                  {whList.map((wh) => (
                    <MenuItem key={wh.warehouse_guid} value={wh.warehouse_guid}>
                      {wh.warehouse_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div className={styles.label}>
            <span className={styles.labelText}>Примечание</span>
          </div>
          <div className={styles.field}>
            <TextField
              className={styles.comment_text}
              id='outlined-multiline-static'
              label='Введите примечание'
              multiline
              rows={4}
              defaultValue=''
              variant='outlined'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className={styles.label}>
            <Button
              className={styles.save_comment_button}
              variant='contained'
              color='primary'
              disabled={!agree || !addressValue || !wh}
              // disabled={!addressValue || !agree}
              disableElevation
              onClick={() => handleCreateNewDraft()}
            >
              Продолжить с выбранным адресом
            </Button>
          </div>
          <div className={styles.label}>
            <span className={styles.labelText}> </span>
          </div>
          <div className={styles.label}>
            <span className={styles.labelText}>+ Добавить новый адрес</span>
          </div>
          <div className={styles.label}>
            <TextField
              id='outlined-basic'
              label='Город'
              variant='outlined'
              className={styles.txt_input}
              value={saveAdressForm.adress_city}
              onChange={(e) =>
                handleSaveAdressField("adress_city", e.target.value)
              }
            />
            <TextField
              id='outlined-basic'
              label='Улица'
              variant='outlined'
              className={styles.txt_input2}
              value={saveAdressForm.adress_street}
              onChange={(e) =>
                handleSaveAdressField("adress_street", e.target.value)
              }
            />
          </div>
          <div className={styles.label}>
            <TextField
              id='outlined-basic'
              label='Дом'
              variant='outlined'
              className={styles.txt_input_small}
              value={saveAdressForm.adress_dom}
              onChange={(e) =>
                handleSaveAdressField("adress_dom", e.target.value)
              }
            />
            <TextField
              id='outlined-basic'
              label='Корпус'
              variant='outlined'
              className={styles.txt_input_small}
              value={saveAdressForm.adress_korpus}
              onChange={(e) =>
                handleSaveAdressField("adress_korpus", e.target.value)
              }
            />
            <TextField
              id='outlined-basic'
              label='Строение'
              variant='outlined'
              className={styles.txt_input_small}
            />
            <TextField
              id='outlined-basic'
              label='Подъезд'
              variant='outlined'
              className={styles.txt_input_small}
            />
            <TextField
              id='outlined-basic'
              label='Офис'
              variant='outlined'
              className={styles.txt_input_small2}
              value={saveAdressForm.adress_kvartira}
              onChange={(e) =>
                handleSaveAdressField("adress_kvartira", e.target.value)
              }
            />
          </div>
          <div className={styles.label}>
            <Button
              className={styles.save_comment_button}
              variant='contained'
              color='primary'
              disabled={!agree || !wh}
              disableElevation
              onClick={saveNewAdressAndCreateDraft}
            >
              Добавить и продолжить заказ
            </Button>
          </div>
          <div className={styles.label}>
            <FormControlLabel
              className={styles.check_cb}
              onChange={(event: any) => {
                setAgree(event.target.checked);
              }}
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                  checkedIcon={<CheckBoxIcon fontSize='small' />}
                  name='checkedI'
                  checked={agree}
                />
              }
              label={
                <span className={styles.check_cb_txt}>
                  {" "}
                  Нажимая на кнопку «Продолжить» вы соглашаетесь с офертой и
                  условиями обработки персональных данных{" "}
                </span>
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutAddressSelector;
