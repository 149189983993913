import Icons from "../img/nav_icons.svg";

export const enum NavIconName {
  menu = "menu",
  basket = "basket",
  bag = "bag",
  search = "search",
  home = "home",
  money = "money",
  diff = "diff",
  profile = "profile",
}

const iconSizes: { [id in NavIconName]: { w: number; h: number } } = {
  menu: { w: 18, h: 12 },
  basket: { w: 21, h: 20 },
  bag: { w: 16, h: 20 },
  search: { w: 18, h: 18 },
  home: { w: 20, h: 17 },
  money: { w: 20, h: 20 },
  diff: { w: 16, h: 16 },
  profile: { w: 20, h: 20 },
};

interface NavIconProps {
  name: NavIconName;
  className: string;
}

const NavIcon = (props: NavIconProps) => {
  const { name, className } = props;
  const size = iconSizes[name] || { w: 20, h: 20 };
  return (
    <svg width={size.w} height={size.h} className={className}>
      <use href={`${Icons}#icon-${name}`} />
    </svg>
  );
};

export default NavIcon;
