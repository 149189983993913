import { Button, TableCell, TableRow } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { withStyles } from "@material-ui/styles";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import {
  CartPosition,
  ItemData,
  ProductDetails,
  ProductPosition,
} from "../../../../models/product";
import { getPositionRubPrice } from "../../../../utils/utils";
import useCartStore from "../../../../zustand/cartStore";
import styles from "./InkTableItem.module.css";

interface InkTableItemProps {
  ink: ProductDetails;
  course_our?: string;
  handleAddToDraft: (position: ProductPosition, amount: number) => void;
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#3A3D4B",
    fontWeight: 600,
    fontFamily: "Raleway, Arial, Helvetica, sans-serif",
  },
  body: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Raleway, Arial, Helvetica, sans-serif",
  },
}))(TableCell);

export const InkTableItem = (props: InkTableItemProps) => {
  const { ink, course_our, handleAddToDraft } = props;
  const { addToCart, cartItems, changeCartItemQty } = useCartStore();
  const finalUsdPrice = ink.promoPrice || ink.usdPrice;
  const rubPrice = getPositionRubPrice(1, finalUsdPrice, course_our).toFixed(2);
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("1");
  const inputRef = useRef<HTMLInputElement>(null);
  const [itemData, setItemData] = useState<ItemData>({
    amount: 1,
    inFavs: false,
    inCart: false,
  });

  const handleAddToCart = useCallback(
    (position: ProductDetails) => {
      const qty: number = itemData.amount || 1;
      const totalrub = Number(rubPrice) * qty;
      const totalusd: CartPosition["totalusd"] = Number(finalUsdPrice) * qty;
      const positionInCart: CartPosition | undefined = cartItems.find(
        (item) => item.uuid === position.uuid
      );
      if (positionInCart) {
        changeCartItemQty(position.uuid, qty, "add");
      } else {
        addToCart({
          ...position,
          promoPrice: finalUsdPrice,
          usdPrice: finalUsdPrice,
          qty,
          totalusd,
          totalrub,
        });
      }
      setItemData({ ...itemData, inCart: true, amount: 1 });
      setInputValue("1");
      NotificationManager.success("Позиция успешно добавлена в корзину!");
    },
    [addToCart, cartItems, changeCartItemQty, finalUsdPrice, itemData, rubPrice]
  );

  const handleInputAmount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const valid = /(^$)|(^[1-9][0-9]?$|^100$)/g.test(value);
      if (valid) {
        setInputValue(value);
        if (value) {
          setItemData((prev) => ({ ...prev, amount: Number(value) }));
        }
      }
    },
    []
  );

  const handleChangeAmount = useCallback(
    (delta: number) => {
      let n = itemData.amount + delta;
      if (n > 100) {
        n = 100;
      }
      setItemData({ ...itemData, amount: n });
      setInputValue(String(n));
    },
    [itemData]
  );

  useEffect(() => {
    if (inputActive) {
      inputRef.current?.focus();
    }
  }, [inputActive]);

  return (
    <TableRow key={ink.uuid}>
      <StyledTableCell>
        <div
          className={[styles.colorCircle, styles[ink.color || ""]]
            .filter(Boolean)
            .join(" ")}
        />
      </StyledTableCell>
      <StyledTableCell>
        <a href={`/product/${ink.name}`} className={styles.modelName}>
          <span>{ink.model}</span>
          <span>
            {ink.brand} {ink.name}
          </span>
        </a>
      </StyledTableCell>
      <StyledTableCell>
        <span className={styles.price}>
          {finalUsdPrice}$/
          {rubPrice}₽
        </span>
      </StyledTableCell>
      <StyledTableCell>
        <div className={styles.select_quantity}>
          <button
            onClick={(e) => {
              // @ts-ignore
              if (e.nativeEvent.pointerType === "mouse") {
                handleChangeAmount(-1);
              }
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleAddToCart(ink);
              }
            }}
            disabled={itemData.amount <= 1}
            className={`${styles.select_quantity_button} ${styles.select_quantity_button_minus}`}
          >
            -
          </button>
          {inputActive ? (
            <input
              ref={inputRef}
              onBlur={() => {
                setInputActive(false);
                setInputValue(String(itemData.amount));
              }}
              value={inputValue}
              className={styles.select_quantity_input}
              onChange={handleInputAmount}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setInputActive(false);
                  handleAddToCart(ink);
                }
              }}
            />
          ) : (
            <span
              className={styles.select_quantity_count}
              onClick={() => {
                setInputActive(true);
              }}
            >
              {itemData.amount}
            </span>
          )}
          <button
            onClick={(e) => {
              // @ts-ignore
              if (e.nativeEvent.pointerType === "mouse") {
                handleChangeAmount(1);
              }
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleAddToCart(ink);
              }
            }}
            className={styles.select_quantity_button}
          >
            +
          </button>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className={styles.buttonsAddWrapper}>
          <Button
            className={styles.add_to_basket_button}
            variant='contained'
            color='primary'
            disableElevation
            onClick={() => handleAddToCart(ink)}
          >
            В корзину
          </Button>
          <Button
            className={styles.add_to_something_button}
            variant='outlined'
            color='secondary'
            disableElevation
            onClick={() => {
              handleAddToDraft(ink, itemData.amount);
            }}
          >
            <span>Добавить в</span>
            <ArrowForwardIosIcon
              fontSize='small'
              className={styles.drop_icon}
            />
          </Button>
        </div>
      </StyledTableCell>
    </TableRow>
  );
};
