import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory, useParams } from "react-router-dom";
import { Draft2 } from "../../models/drafts";
import { AdressData, OurLegalEntity, WhItem } from "../../models/info";
import { LegalEnt } from "../../models/orgs";
import { ReserseResponse } from "../../models/reserves";
import { saveAdress } from "../../services/addressServices";
import { createDraft } from "../../services/cartServices";
import { getOurLegal, getWhList } from "../../services/infoServices";
import { reserve } from "../../services/reserveServices";
import AuthStore from "../../stores/AuthStore";
import DraftStore from "../../stores/DraftStore";
import AddressSelector from "../legalents/AddressSelector";
import LegalEntsSelector from "../legalents/LegalEntsSelector";
import styles from "./MerchDraftDetails.module.css";
import MerchDraftItemsList from "./MerchDraftItemsList";

const MerchDraftDetails = () => {
  const { id } = useParams<{ id: string }>();
  const authStore = AuthStore.Instance;
  const draftStore = DraftStore.Instance;
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [details, setDetails] = useState<Draft2 | undefined>(undefined);
  const [comment, setComment] = useState<string>(details?.comment || "");
  const history = useHistory();
  const [localCW, setLocalCW] = useState<number>(details?.credit_week || 0);
  const [localBG, setLocalBG] = useState<string>(details?.buyer_guid || "");
  const [addressValue, setAddressValue] = useState<string>(
    details?.adress_guid || ""
  );
  const [ourLegals, setOurLegals] = useState<Array<OurLegalEntity>>([]);
  const [ourLegalState, setOurLegalState] = useState<string>("");
  const [loadingOurLegals, setLoadingOurLegals] = useState<boolean>(false);
  const token = authStore.currentSessionToken;
  const [isNotReservedModal, setIsNotReservedModal] = useState<boolean>(false);
  const [notReservedItems, setNotReservedItems] = useState<
    Array<{
      descr: string;
      uuid: string;
      quantity: number;
    }>
  >([]);
  const [wh, setWh] = useState<string>("");
  const [whList, setWhList] = useState<Array<WhItem>>([]);
  const [loadingWhList, setLoadingWhList] = useState<boolean>(false);
  const [isAnyItemInReserve, setIsAnyItemInReserve] = useState<boolean>(false);
  const [loadingAddressList, setLoadingAddressList] = useState<boolean>(false);
  const [isCreateDraftFromNotReserved, setIsCreateDraftFromNotReserved] =
    useState<boolean>(false);

  useEffect(() => {
    setLocalCW(details?.credit_week || 0);
    setLocalBG(details?.buyer_guid || "");
    setOurLegalState(details?.org_guid || "");
    setWh(details?.warehouse_guid || "");
    setAddressValue(details?.adress_guid || "");
  }, [details]);

  useEffect(() => {
    const loadingDrafts = draftStore
      .getDraftsLoading()
      .subscribe((value) => setPageLoading(value));
    const subscription = draftStore.getDrafts().subscribe((value) => {
      let draft = (value || []).find((v) => v.draftuuid === id);
      if (draft) {
        setDetails({ ...draft } as Draft2);
        setComment(draft?.comment || "");
      }
    });
    draftStore.initDrafts();
    return () => {
      loadingDrafts.unsubscribe();
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateComment = () => {
    if (details?.draftuuid) {
      if (details.comment !== comment) {
        draftStore.updateComment(details?.draftuuid, comment);
      }
    }
  };

  const handleUpdateDelay = (newDelay: number) => {
    if (details?.draftuuid) {
      draftStore.updateCredWeek(details.draftuuid, newDelay);
      setLocalCW(newDelay);
    }
  };

  const handleUpdateLE = (le: LegalEnt) => {
    if (details?.draftuuid) {
      draftStore.updateBuyer(details.draftuuid, le.buyer_guid);
      setLocalBG(le.buyer_guid);
    }
  };

  const handleUpdateAddress = (addr: string) => {
    if (details?.draftuuid) {
      draftStore.updateAdress(details.draftuuid, addr);
      setAddressValue(addr);
    }
  };

  const handleUpdateOurLegal = (ourLegal: string) => {
    if (details?.draftuuid) {
      draftStore.updateOurLegal(details.draftuuid, ourLegal);
      setOurLegalState(ourLegal);
    }
  };

  const handleUpdateWH = (wh_guid: string) => {
    if (details?.draftuuid) {
      draftStore.updateWh(details.draftuuid, wh_guid);
      setWh(wh_guid);
    }
  };

  const handleSuccessReserve = useCallback(
    async (reserveUuid?: string) => {
      // Если нет незарезевированных - сразу переводим в резерв
      if (reserveUuid) {
        history.push(`/reserves/${reserveUuid}`);
        NotificationManager.success("Черновик успешно переведен в резерв");
      } else {
        // Если есть незарезевированные и зарезевированные - создаем черновик если выбрано создать черновик
        // Если вообще нет зарезервированных остаемся на месте
        if (isCreateDraftFromNotReserved && isAnyItemInReserve) {
          if (details && details.buyer_guid && details.warehouse_guid) {
            const {
              org_guid,
              warehouse_guid,
              buyer_guid,
              adress_guid,
              comment,
            } = details;
            const response = await createDraft(
              token,
              notReservedItems,
              org_guid,
              buyer_guid,
              adress_guid,
              warehouse_guid,
              comment
            );
            if (response) {
              history.push(`/drafts/${response?.order_uuid}`);
              NotificationManager.success(
                "Новый черновик из незарезервированных позиций успешно создан!"
              );
            }
          }
        }
        setIsNotReservedModal(false);
        setNotReservedItems([]);
        // Переходим в резерв если есть зарезевированные
        if (isAnyItemInReserve) {
          history.push(`/reserves/${details?.draftuuid}`);
          NotificationManager.success("Черновик успешно переведен в резерв");
        }
      }
    },
    [
      details,
      history,
      isAnyItemInReserve,
      isCreateDraftFromNotReserved,
      notReservedItems,
      token,
    ]
  );

  const handleDraftToReserve = useCallback(async () => {
    let data: ReserseResponse | undefined;
    if (details?.draftuuid) {
      setPageLoading(true);
      try {
        data = await reserve(token, details?.draftuuid);
        if (data) {
          if (data.items_not_reserved && data.items_not_reserved.length > 0) {
            const items = data.items_not_reserved.map((item) => {
              const { descr, uuid, quantity } = item;
              return {
                descr,
                uuid,
                quantity,
              };
            });
            const isReserves = Boolean(
              data.items_reserved && data.items_reserved.length
            );
            setIsAnyItemInReserve(isReserves);
            setNotReservedItems(items);
            setIsNotReservedModal(true);
          } else {
            handleSuccessReserve(data.order_uuid);
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        setPageLoading(false);
      }
    }
  }, [details?.draftuuid, token, handleSuccessReserve]);

  const handleSaveNewAdress = (
    data: Pick<
      AdressData,
      | "adress_city"
      | "adress_dom"
      | "adress_street"
      | "adress_korpus"
      | "adress_kvartira"
    >
  ) => {
    const {
      adress_city,
      adress_dom,
      adress_street,
      adress_korpus,
      adress_kvartira,
    } = data;
    if (details?.buyer_guid) {
      setLoadingAddressList(true);
      saveAdress({
        adress_city,
        SessionToken: token,
        adress_dom,
        adress_street,
        adress_korpus,
        adress_kvartira,
        buyer_guid: details.buyer_guid,
        // TODO: add type selectors
        dom_type: "дом",
        korpus_type: "корп",
        kvartira_type: "кв.",
      })
        .then(() => {
          NotificationManager.success("Новый адрес успешно создан!");
        })
        .finally(() => {
          setLoadingAddressList(false);
        });
    }
  };

  useEffect(() => {
    const callGetOurLegal = async () => {
      try {
        setLoadingOurLegals(true);
        const data = await getOurLegal(token);
        if (data) {
          setOurLegals(data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingOurLegals(false);
      }
    };
    callGetOurLegal();
  }, [token]);

  useEffect(() => {
    const callGetWhlist = async () => {
      try {
        if (details?.buyer_guid) {
          setLoadingWhList(true);
          const whListData = await getWhList(token);
          if (whListData) {
            setWhList(whListData);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingWhList(false);
      }
    };
    callGetWhlist();
  }, [details?.buyer_guid, token]);

  if (pageLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className={styles.page}>
        {details ? (
          <>
            <MerchDraftItemsList draft={details} />
            <LegalEntsSelector
              buyer_guid={localBG}
              onChange={handleUpdateLE}
              disabled={details?.buyerSP}
            />
            {loadingOurLegals ? (
              <div className={styles.loadingWrapper}>
                <CircularProgress />
              </div>
            ) : (
              <div className={styles.ourLegalWrapper}>
                <FormControl variant='outlined' className={styles.form_control}>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Юридическое лицо Поставщик
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={ourLegalState}
                    onChange={(e) =>
                      handleUpdateOurLegal(e.target.value as string)
                    }
                    label='Юридическое лицо Поставщик'
                    disabled={details?.orgSP}
                  >
                    {ourLegals.map((legal) => (
                      <MenuItem key={legal.org_guid} value={legal.org_guid}>
                        {legal.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {loadingWhList ? (
              <div className={styles.loadingWrapper}>
                <CircularProgress />
              </div>
            ) : (
              <div className={styles.WHWrapper}>
                <FormControl variant='outlined' className={styles.form_control}>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Склад
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={wh}
                    onChange={(e) => handleUpdateWH(e.target.value as string)}
                    label='Склад'
                    disabled={details?.wh_guidSP}
                  >
                    {whList.map((wh) => (
                      <MenuItem
                        key={wh.warehouse_guid}
                        value={wh.warehouse_guid}
                      >
                        {wh.warehouse_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {loadingAddressList ? (
              <div className={styles.loadingWrapper}>
                <CircularProgress />
              </div>
            ) : (
              <AddressSelector
                addressValue={addressValue}
                handleAddressChange={handleUpdateAddress}
                token={token}
                buyer_guid={details.buyer_guid}
                disabled={details?.addrSP}
                handleSaveNewAdress={handleSaveNewAdress}
              />
            )}
            <TextField
              id='outlined-basic'
              label='Примечание'
              variant='outlined'
              className={styles.txt_input}
              value={comment}
              disabled={details?.commentSP}
              onChange={(event) => {
                setComment(event.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleUpdateComment();
                }
              }}
              onBlur={() => {
                handleUpdateComment();
              }}
            />
            <div className={styles.buttons}>
              <Button
                className={styles.bottom_button}
                variant='contained'
                color='secondary'
                disableElevation
                onClick={handleDraftToReserve}
              >
                Поставить в резерв
              </Button>
            </div>
          </>
        ) : (
          <div className={styles.notFound}>
            <span>Черновик не найден</span>
          </div>
        )}
      </div>
      <Dialog
        open={isNotReservedModal}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          className: styles.notReservedModal,
        }}
      >
        <div className={styles.dialogContentWrapper}>
          <DialogTitle className={styles.dialogTitle} id='alert-dialog-title'>
            Незарезервированные позиции
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Данные позиции не были зарезервированы
            </DialogContentText>
            <List className={styles.notReservedList}>
              {notReservedItems.map((item, index) => {
                const description =
                  item.descr.length > 60
                    ? item.descr.slice(0, 60) + "..."
                    : item.descr;
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`${index + 1}) ${description} (${
                        item.quantity
                      } шт)`}
                    />
                  </ListItem>
                );
              })}
            </List>
            {!isAnyItemInReserve && (
              <DialogContentText id='alert-dialog-description'>
                Ни одна позиция не была зарезервирована
              </DialogContentText>
            )}
            {isAnyItemInReserve && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCreateDraftFromNotReserved}
                    onChange={(_, checked) =>
                      setIsCreateDraftFromNotReserved(checked)
                    }
                    name='isCreateDraftFromNotReserved'
                  />
                }
                label='Создать новый черновик из отсутствующих позиций'
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleSuccessReserve()}
              autoFocus
            >
              Принять
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default MerchDraftDetails;
