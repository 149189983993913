import { Link } from "react-router-dom";
import { CatalogueItem } from "../../models/catalogue";
import CatIcon, { CatIconName } from "../nav/CatIcon";
import styles from "./CatList.module.css";

type props = {
  items: CatalogueItem[];
  width: string;
  hasIconColumn?: boolean;
  header: string;
  activeId?: string;
  itemIt2link: (id: string) => string;
};

const CatList = ({
  items,
  width,
  header,
  activeId,
  hasIconColumn,
  itemIt2link,
}: props) => {
  const rows = items.map((itm) => (
    <div className={styles.row} key={itm.id}>
      {hasIconColumn ? (
        <CatIcon
          className={styles.icon}
          name={itm.icon as CatIconName}
        ></CatIcon>
      ) : (
        <div className={styles.icon}></div>
      )}
      <Link
        to={itemIt2link(itm.id)}
        className={`${styles.link} ${
          activeId === itm.id ? styles.active_row : styles.inactive_row
        }`}
      >
        {itm.name}
      </Link>
    </div>
  ));

  return (
    <div className={styles.panel} style={{ width }}>
      <div className={styles.header_row}>
        {hasIconColumn ? <div className={styles.icon}></div> : ""}
        <div className={styles.header}>{header}</div>
      </div>
      <div className={styles.sub_header_row}></div>
      {rows.length ? (
        rows
      ) : (
        <div className={styles.noVariants}>Нет доступных вариантов</div>
      )}
    </div>
  );
};

export default CatList;
