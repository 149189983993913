import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { useCallback, useState } from "react";
import { NotificationManager } from "react-notifications";
import { getConditionsFile, getInst } from "../../services/infoServices";
import useMainWhStore from "../../zustand/mainWhStore";
import styles from "./Footer.module.css";
const Footer = () => {
  const whList = useMainWhStore((state) => state.whList);
  const [open, setOpen] = useState<boolean>(false);

  const downloadInstructions = useCallback(async () => {
    const data = await getInst();
    if (data) {
      if (data.base64string) {
        let pdfData = Buffer.from(data.base64string, "base64");
        const blob = new Blob([pdfData], { type: "application/pdf" });
        const link = document.createElement("a");
        link.download = "Инструкция.pdf";
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        NotificationManager.warning("Инструкция в данный момент отсутствует.");
      }
    }
  }, []);

  const downloadConditionsFile = useCallback(async () => {
    const data = await getConditionsFile();
    if (data) {
      if (data.base64string) {
        let pdfData = Buffer.from(data.base64string, "base64");
        const blob = new Blob([pdfData], { type: "application/pdf" });
        const link = document.createElement("a");
        link.download = "Условия работы.pdf";
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        NotificationManager.warning(
          "Условия работы в данный момент отсутствуют."
        );
      }
    }
  }, []);

  return (
    <>
      <div className={styles.footer}>
        <div className={styles.col}>
          <a className={styles.phone} href='tel:+74957975557'>
            8 (495) 797-55-57
          </a>
          <a
            href='https://yandex.ru/maps/-/CDufZZ7d'
            target='_blank'
            rel='noopener noreferrer'
            className={styles.address}
          >
            115230 Россия, Москва, Варшавское шоссе. Д. 36, стр.8, оф.2
          </a>
        </div>
        <div className={styles.col}>
          {whList && whList?.length > 0 && (
            <button
              onClick={() => setOpen(true)}
              className={styles.wh_addresses}
            >
              Адреса складов
            </button>
          )}

          <div className={styles.downloadFiles}>
            <button
              onClick={downloadInstructions}
              className={styles.conditions}
            >
              Инструкция
            </button>

            <button
              onClick={downloadConditionsFile}
              className={styles.instructions}
            >
              Условия работы
            </button>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby='wh-list'
        className={styles.wh_dialog}
        maxWidth='lg'
      >
        <DialogTitle id='wh-list'>Список складов</DialogTitle>
        <IconButton
          aria-label='close'
          classes={{
            root: styles.closeButton,
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
        {whList && whList?.length > 0 && (
          <List
            component='ol'
            classes={{
              root: styles.list_root,
            }}
          >
            {whList.map((wh) => (
              <ListItem
                classes={{
                  root: styles.list_item_root,
                }}
                key={wh.warehouse_guid}
              >
                <Typography variant='h5'>{wh.warehouse_name}</Typography>

                <div className={styles.wh_item}>
                  <span className={styles.wh_item_name}>Полный адрес:</span>
                  <span className={styles.wh_item_data}>
                    {wh.warehouse_adress || "-"}
                  </span>
                </div>

                <div className={styles.wh_item}>
                  <span className={styles.wh_item_name}>Телефон:</span>
                  {wh.warehouse_phone ? (
                    <a
                      href={`tel:${wh.warehouse_phone}`}
                      className={styles.wh_item_data}
                    >
                      {wh.warehouse_phone}
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                </div>
              </ListItem>
            ))}
          </List>
        )}
      </Dialog>
    </>
  );
};

export default Footer;
