import { useParams } from 'react-router-dom';
import MerchReservesList from '../drafts/MerchReservesList';
import SubHeader from '../layout/SubHeader';
import MergeReserveDetails from '../reserves/MergeReserveDetails/MergeReserveDetails';
import styles from './ReservesPage.module.css';

const ReservesPage = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return (
      <div className={styles.page}>
        <div className={styles.header}>
          <SubHeader />
        </div>

        <div className={styles.content}>
          <MerchReservesList />
        </div>
      </div>
    );
  } else {
    // TODO: to go MergeReservePage
    return (
      <div className={styles.page}>
        <div className={styles.header}>
          <SubHeader></SubHeader>
        </div>

        <div className={styles.content}>
          <MergeReserveDetails id={id} />
        </div>
      </div>
    );
  }
};
export default ReservesPage;
