import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SaleItem } from "../../../../models/release";
import AuthStore from "../../../../stores/AuthStore";
import useSalesStore from "../../../../zustand/salesStore";
import SubHeader from "../../../layout/SubHeader";
import styles from "../styles.module.css";
import SingleSaleTable from "./SingleSaleTable/SingleSaleTable";
export const SingleSale = () => {
  const { id } = useParams<{ id: string }>();
  const authStore = AuthStore.Instance;
  const token = authStore.currentSessionToken;
  const [singleSale, setSingleSale] = useState<SaleItem | undefined>(undefined);
  const sales = useSalesStore((state) => state.sales);
  const loading = useSalesStore((state) => state.loading);
  const fetchSales = useSalesStore((state) => state.fetchSales);

  useEffect(() => {
    if (!sales) {
      fetchSales(token);
    }
    const currentSale = sales?.find((sale) => sale.draftuuid === id);
    setSingleSale(currentSale);
  }, [fetchSales, id, sales, token]);

  return (
    <>
      <div className={styles.header}>
        <SubHeader />
      </div>
      {loading ? (
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={styles.singleSaleInfo}>
            <div className={styles.singleSaleInfoLine}>
              <span className={styles.singleSaleInfoLineTitle}>Заказ №:</span>
              <span className={styles.singleSaleInfoLineValue}>
                {singleSale?.number}
              </span>
            </div>
            <div className={styles.singleSaleInfoLine}>
              <span className={styles.singleSaleInfoLineTitle}>
                Юридическое лицо Контрагент:
              </span>
              <span className={styles.singleSaleInfoLineValue}>
                {singleSale?.buyer_name}
              </span>
            </div>
            <div className={styles.singleSaleInfoLine}>
              <span className={styles.singleSaleInfoLineTitle}>
                Юридическое лицо Поставщик:
              </span>
              <span className={styles.singleSaleInfoLineValue}>
                {singleSale?.org_name}
              </span>
            </div>
          </div>
          <SingleSaleTable items={singleSale?.items} />
        </>
      )}
    </>
  );
};
