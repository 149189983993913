import { Link } from 'react-router-dom';
import styles from './CheckoutHeader.module.css';

const CheckoutHeader = () => {
  return (
    <div className={styles.line_header}>
      <Link to='/cart/checkout' className={styles.link}>
        <div className={styles.regul}>Регистрация юридического лица</div>
      </Link>
      <Link to='/cart/checkout/pdn' className={styles.link}>
        <div className={styles.regpdn}>Регистрация персональных данных</div>
      </Link>
      <Link to='/cart/checkout/delivery' className={styles.link}>
        <div className={styles.delivery}>Выбор доставки</div>
      </Link>
    </div>
  );
};

export default CheckoutHeader;
