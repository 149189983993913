import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ListResponse } from '../../models/catalogue';
import ProductStore from '../../stores/ProductStore';
import styles from './ListPaginator.module.css';

const ListPaginator = () => {
    const { id, from, sort } = useParams<{ id: string; from: string; sort: string }>();
    const pagesFrom = Number.parseInt(from) || 0;
    const pageSize = 15;

    const store = ProductStore.Instance;
    const [results, setResults] = React.useState<ListResponse | undefined>(undefined);

    React.useEffect(() => {
        const subscription = store.getListing().subscribe((value) => {
            setResults(value);
        });
        return () => { subscription.unsubscribe(); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pages = Math.ceil((results?.total || 0) / pageSize);

    const history = useHistory();
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        history.push("/list/" + id + "/" + value + (sort ? ("/" + sort + "/") : ""));
    };

    return <div className={styles.pane}>
        <Pagination variant="outlined" count={pages} page={pagesFrom} onChange={handleChange} />
    </div>
}

export default ListPaginator;