import {
  CircularProgress,
  DialogContent,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from "react";
import { Draft2 } from "../../../models/drafts";
import { ProductDetails, ProductPosition } from "../../../models/product";
import { getZips } from "../../../services/products";
import AuthStore from "../../../stores/AuthStore";
import DraftStore from "../../../stores/DraftStore";
import DraftUndReservesSelector from "../../drafts/DraftUndReservesSelector";
import styles from "./ZipCatalogList.module.css";
import { ZipTableItem } from "./ZipTableItem/ZipTableItem";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#3A3D4B",
    fontWeight: 600,
    fontFamily: "Raleway, Arial, Helvetica, sans-serif",
  },
  body: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Raleway, Arial, Helvetica, sans-serif",
  },
}))(TableCell);

const ZipCatalogList = () => {
  const authStore = AuthStore.Instance;
  const draftStore = DraftStore.Instance;
  const token = authStore.currentSessionToken;
  const [zips, setZips] = useState<Array<ProductDetails>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const user = authStore.getCurrentUser();
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const [draftPosition, setDraftPosition] = useState<
    ProductPosition | undefined
  >(undefined);
  const [draftAmount, setDraftAmount] = useState<number>(1);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddToDraft = (position: ProductPosition, amount: number) => {
    setDraftPosition(position);
    setDraftAmount(amount);
    setModalOpen(true);
  };

  function handleDraftSelect(draft: Draft2) {
    if (draft && draftPosition) {
      draftStore.addToDraft(
        draftPosition.uuid,
        draft.draftuuid,
        draftAmount || 1
      );
    }
    setModalOpen(false);
  }

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await getZips(token);
      if (data) {
        setZips(data);
      }
      setLoading(false);
    };
    getData();
  }, [token]);

  return (
    <>
      <div className={styles.panel}>
        <div className={styles.header_row}>
          <div className={styles.header}>ЗИП</div>
        </div>
        <div className={styles.sub_header_row}></div>
        {loading ? (
          <div className={styles.loadingWrapper}>
            <CircularProgress />
          </div>
        ) : zips.length > 0 ? (
          <>
            <TableContainer>
              <Table aria-label='ink table'>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Цвет</StyledTableCell>
                    <StyledTableCell>Наименование</StyledTableCell>
                    <StyledTableCell>Цена</StyledTableCell>
                    <StyledTableCell align='center'>Количество</StyledTableCell>
                    <StyledTableCell align='center'>Действия</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {zips
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((zip) => (
                      <ZipTableItem
                        zip={zip}
                        key={zip.uuid}
                        course_our={user.UserData?.course_our}
                        handleAddToDraft={handleAddToDraft}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20]}
              component='div'
              count={zips.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage='Позиций на странице:'
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`
              }
            />
          </>
        ) : (
          <div className={styles.noInks}>Чернила отсутствуют</div>
        )}
      </div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <DialogContent>
          <DraftUndReservesSelector
            loading={false}
            onDraftSelect={(draft) => {
              handleDraftSelect(draft);
            }}
          />
        </DialogContent>
      </Modal>
    </>
  );
};
export default ZipCatalogList;
