import styles from "./CartHeader.module.css";

const CartHeader = () => {
  return (
    <div className={styles.line_header}>
      <div className={styles.name}>Наименование</div>
      <div className={styles.num}>Количество (шт)</div>
      <div className={styles.price_usd}>Стоимость&nbsp;$</div>
      <div className={styles.price_rub}>Стоимость&nbsp;₽</div>
      <div className={styles.sum_usd}>Сумма&nbsp;$</div>
      <div className={styles.sum_rub}>Сумма&nbsp;₽</div>
    </div>
  );
};

export default CartHeader;
