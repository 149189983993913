import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import { OneReserveResponse } from '../../../models/reserves';
import { release } from '../../../services/release';
import { getReserves, unReserve } from '../../../services/reserveServices';
import AuthStore from '../../../stores/AuthStore';
import MerchReserveItemsList from '../MerchReserveItemsList/MerchReserveItemsList';
import styles from './MergeReserveDetails.module.css';

interface MergeReserveDetailsProps {
  id: string;
}

const MergeReserveDetails = (props: MergeReserveDetailsProps) => {
  const { id } = props;
  const authStore = AuthStore.Instance;
  const token = authStore.currentSessionToken;
  const [reserve, setReserve] = useState<OneReserveResponse>();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [isShipOffDialog, setIsShipOffDialog] = useState<boolean>(false);

  const handleUnreserve = async () => {
    try {
      setLoading(true);
      const result = await unReserve(token, reserve?.draftuuid || '');
      if (result) {
        NotificationManager.success('Резерв успешно переведен в черновик');
        if (reserve?.draftuuid) {
          history.push(`/drafts/${reserve?.draftuuid}`);
        } else {
          history.push('/drafts');
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleShipOff = async () => {
    setIsShipOffDialog(false);
    setLoading(true);
    try {
      const response = await release(token, reserve?.draftuuid || '');
      if (response) {
        NotificationManager.success(
          'Заказ успешно создан! Скоро с вами скоро свяжется менеджер!'
        );
        history.push('/reserves');
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const data = await getReserves(token);
        if (data) {
          const reserve = data.find((reserve) => reserve.draftuuid === id);
          setReserve(reserve);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className={styles.spinnerWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {reserve?.draftuuid ? (
        <div>
          <MerchReserveItemsList reserve={reserve} />
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              variant='contained'
              color='secondary'
              disableElevation
              onClick={handleUnreserve}
            >
              Вернуть в черновик
            </Button>
            <Button
              className={styles.button}
              variant='contained'
              color='primary'
              disableElevation
              onClick={() => setIsShipOffDialog(true)}
            >
              Отгрузиться
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.notFound}>
          <span>Резерв не найден</span>
        </div>
      )}{' '}
      <Dialog
        open={isShipOffDialog}
        onClose={() => setIsShipOffDialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <div className={styles.dialogContentWrapper}>
          <DialogTitle className={styles.dialogTitle} id='alert-dialog-title'>
            Подтверждение заказа
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Вы действительно хотите создать заказ? После принятия заказ будет
              передан в обработку, дальнейшие действия с данным резервом будут
              не возможны.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => setIsShipOffDialog(false)}
            >
              Отмена
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={handleShipOff}
              autoFocus
            >
              Принять
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};
export default MergeReserveDetails;
