import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { CatalogueItem, ListRequest, getPath } from "../../models/catalogue";
import {
  productSortsRe,
  productSortsServ,
  productSorts as ps,
} from "../../models/product";
import ProductStore from "../../stores/ProductStore";
import styles from "./ListFilter.module.css";

const ListFilter = () => {
  const { id, from, sort } = useParams<{
    id: string;
    from: string;
    sort: string;
  }>();
  const productSorts = ps;
  const sortVal = productSorts[sort as keyof typeof productSorts] || "";
  const pagesFrom = Number.parseInt(from) || 1;
  const pageSize = 15;

  const store = ProductStore.Instance;
  const [menu, setMenu] = React.useState<CatalogueItem[] | undefined>(
    undefined
  );
  const [selBrandName, setBrandName] = React.useState<string>("");
  const [selColorName, setColorName] = React.useState<string>("");

  useEffect(() => {
    const subscription = store.getMenu().subscribe((value) => {
      setMenu(value);
      if (!selBrandName) {
        const menuItem = getPath(id, value || []);
        setBrandName(menuItem[1]?.name || "");
        if (!selColorName) {
          setColorName(menuItem[2]?.name || "");
        }
      }
    });
    store.initMenu();
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuItem = getPath(id, menu || []);
  const brands = menuItem[0]?.children?.map((c) => c.name) || [];
  const selBrand = menuItem[0]?.children?.find((b) => b.name === selBrandName);
  const colors = selBrand?.children?.map((c) => c.name);
  const selColor = selBrand?.children?.find((b) => b.name === selColorName);
  // console.log(selColor?.filter || {});
  if (selColor?.filter) {
    const req: ListRequest = {
      filters: selColor?.filter,
      from: (pagesFrom - 1) * pageSize,
      size: pageSize,
    };
    if (sort && sort !== "ds") {
      const s = productSortsServ[sort as keyof typeof productSortsServ];
      if (s) {
        req.sort_dir = s.sort_dir;
        req.sort_field = s.sort_field;
      }
    }
    store.doListing(req);
  }

  const handleBrandChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newBrand = menuItem[0]?.children?.find(
      (b) => b.name === (event.target.value as string)
    );
    let firstColor;
    if (newBrand?.children && newBrand?.children?.length > 0) {
      firstColor = newBrand?.children[0];
    }
    setBrandName(newBrand?.name || "");
    setColorName(firstColor?.name || "");
    store.clearListing();
    if (firstColor) {
      history.push(
        "/list/" + firstColor?.id + "/1/" + (sort ? sort + "/" : "")
      );
    }
  };

  const history = useHistory();
  const handleColorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selBrand = menuItem[0]?.children?.find(
      (b) => b.name === selBrandName
    );
    const newColor = selBrand?.children?.find(
      (b) => b.name === (event.target.value as string)
    );
    setColorName(newColor?.name || "");

    if (newColor) {
      history.push("/list/" + newColor?.id + "/1/" + (sort ? sort + "/" : ""));
    }
  };

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newSort =
      productSortsRe[event.target.value as keyof typeof productSortsRe];

    if (newSort) {
      history.push(
        "/list/" + id + "/" + (from ? from : "1") + "/" + (newSort + "/")
      );
    }
  };

  const handleViewTypeChange = (grid: boolean) => {
    store.setListingViewType(grid);
  };

  return (
    <div className={styles.pane}>
      <div className={styles.filter_pane}>
        <FormControl className={styles.form_control}>
          <InputLabel id='brand-select-label'>Бренд</InputLabel>
          <Select
            labelId='brand-select-label'
            id='brand-select'
            value={selBrandName}
            onChange={handleBrandChange}
          >
            {brands?.map((b) => (
              <MenuItem key={b} value={b}>
                {b}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={styles.form_control}>
          <InputLabel id='color-select-label'>Цвет</InputLabel>
          <Select
            labelId='color-select-label'
            id='color-select'
            value={selColorName}
            onChange={handleColorChange}
          >
            {colors?.map((b) => (
              <MenuItem key={b} value={b}>
                {b}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={styles.sotring_pane}>
        <IconButton
          className={styles.sotring_pane_button}
          aria-label='grid'
          size='small'
          onClick={() => {
            handleViewTypeChange(false);
          }}
        >
          <svg
            width='25'
            height='22'
            viewBox='0 0 25 22'
            fill='#868897'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0 1H24.0111'
              stroke='#868897'
              strokeWidth='1.92'
              strokeMiterlimit='10'
            />
            <path
              d='M0 11H24.0111'
              stroke='#868897'
              strokeWidth='1.92'
              strokeMiterlimit='10'
            />
            <path
              d='M0 21H24.0111'
              stroke='#868897'
              strokeWidth='1.92'
              strokeMiterlimit='10'
            />
          </svg>
        </IconButton>

        <IconButton
          className={styles.sotring_pane_button}
          aria-label='cards'
          size='small'
          onClick={() => {
            handleViewTypeChange(true);
          }}
        >
          <svg
            width='23'
            height='22'
            viewBox='0 0 23 22'
            fill='#868897'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M13.6526 1H10.0144V4.63815H13.6526V1Z'
              stroke='#868897'
              strokeWidth='1.91'
              strokeMiterlimit='10'
            />
            <path
              d='M5.47165 1H1.8335V4.63815H5.47165V1Z'
              stroke='#868897'
              strokeWidth='1.91'
              strokeMiterlimit='10'
            />
            <path
              d='M21.8335 1H18.1953V4.63815H21.8335V1Z'
              stroke='#868897'
              strokeWidth='1.91'
              strokeMiterlimit='10'
            />
            <path
              d='M13.6526 9.18115H10.0144V12.8193H13.6526V9.18115Z'
              stroke='#868897'
              strokeWidth='1.91'
              strokeMiterlimit='10'
            />
            <path
              d='M5.47165 9.18115H1.8335V12.8193H5.47165V9.18115Z'
              stroke='#868897'
              strokeWidth='1.91'
              strokeMiterlimit='10'
            />
            <path
              d='M21.8335 9.18115H18.1953V12.8193H21.8335V9.18115Z'
              stroke='#868897'
              strokeWidth='1.91'
              strokeMiterlimit='10'
            />
            <path
              d='M13.6526 17.3618H10.0144V21H13.6526V17.3618Z'
              stroke='#868897'
              strokeWidth='1.91'
              strokeMiterlimit='10'
            />
            <path
              d='M5.47165 17.3618H1.8335V21H5.47165V17.3618Z'
              stroke='#868897'
              strokeWidth='1.91'
              strokeMiterlimit='10'
            />
            <path
              d='M21.8335 17.3618H18.1953V21H21.8335V17.3618Z'
              stroke='#868897'
              strokeWidth='1.91'
              strokeMiterlimit='10'
            />
          </svg>
        </IconButton>
        <div className={styles.sotring_pane_fill}></div>
        <div className={styles.sotring_pane_dd}>
          <FormControl className={styles.form_control}>
            <InputLabel id='sort-label'>Сортировка</InputLabel>
            <Select
              labelId='sort-label'
              id='sort'
              value={sortVal}
              onChange={handleSortChange}
            >
              {Object.values(productSorts)?.map((b) => (
                <MenuItem key={b} value={b}>
                  {b}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default ListFilter;
