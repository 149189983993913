import { MouseEventHandler } from 'react';
import { Draft, Draft2 } from '../../models/drafts';
import styles from './DraftLine.module.css';

const DraftLine = ({ position, onClick }: { position: Draft2, onClick: MouseEventHandler<HTMLDivElement> | undefined }) => {
    return <div className={styles.line} onClick={onClick}>
        <div className={styles.date}>{position.cdate || position.creationdate}</div>
        <div className={styles.order}>{position.number}</div>
        <div className={styles.sum}>{position.sum}</div>
        <div className={styles.comment}>{position.comment}</div>
    </div>
}

export default DraftLine