import CheckoutHeader from '../checkout/CheckoutHeader';
import CheckoutLegalEntsSelector from '../checkout/CheckoutLegalEntsSelector';
import styles from './CheckoutPage.module.css';

const CheckoutPage = () => {
  return (
    <div className={styles.page}>
      <CheckoutHeader></CheckoutHeader>
      <CheckoutLegalEntsSelector
        onFinish={() => {}}
      ></CheckoutLegalEntsSelector>
    </div>
  );
};
export default CheckoutPage;
