import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { AdressData } from "../../models/info";
import { getAdresses } from "../../services/addressServices";
import { displayAddress } from "../../utils/utils";
import styles from "./AddressSelector.module.css";

interface AddressSelectorProps {
  addressValue?: string;
  handleAddressChange: (addr: string) => void;
  token: string;
  buyer_guid?: string;
  disabled?: boolean;
  handleSaveNewAdress: (
    data: Pick<
      AdressData,
      | "adress_city"
      | "adress_dom"
      | "adress_street"
      | "adress_korpus"
      | "adress_kvartira"
    >
  ) => void;
}

const AddressSelector = (props: AddressSelectorProps) => {
  const {
    addressValue,
    handleAddressChange,
    token,
    buyer_guid,
    disabled,
    handleSaveNewAdress,
  } = props;
  const [addMode, setAddMode] = React.useState(false);
  const [addresses, setAddresses] = useState<Array<AdressData>>([]);
  const [saveAdressForm, setSaveAdressForm] = useState<
    Pick<
      AdressData,
      | "adress_city"
      | "adress_dom"
      | "adress_street"
      | "adress_korpus"
      | "adress_kvartira"
    >
  >({
    adress_city: "",
    adress_street: "",
    adress_dom: "",
    adress_korpus: "",
    adress_kvartira: "",
  });

  const handleSetAddMode = (val: boolean) => {
    setAddMode(val);
  };

  const handleSaveAdressField = useCallback(
    (field: keyof AdressData, data: string) => {
      setSaveAdressForm({ ...saveAdressForm, [field]: data });
    },
    [saveAdressForm]
  );

  useEffect(() => {
    const getData = async () => {
      if (buyer_guid) {
        try {
          const addressesList = await getAdresses(token, buyer_guid);
          if (addressesList) {
            setAddresses(addressesList);
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    getData();
  }, [buyer_guid, token]);

  return (
    <div className={styles.top_pane}>
      <div className={styles.pane}>
        <FormControl variant='outlined' className={styles.form_control}>
          <InputLabel id='demo-simple-select-outlined-label'>
            Адрес доставки
          </InputLabel>
          <Select
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            value={addressValue}
            onChange={(e) => handleAddressChange(e.target.value as string)}
            label='Адрес доставки'
            disabled={disabled}
          >
            {addresses.map((address) => (
              <MenuItem key={address.guid} value={address.guid}>
                {displayAddress(address)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {addMode ? (
          <div
            className={styles.adderer}
            onClick={() => {
              handleSetAddMode(false);
            }}
          >
            <svg width='12' height='12' viewBox='0 0 12 12' fill='none'>
              <path d='M0 6H12' stroke='#555555' strokeWidth='2' />
            </svg>
          </div>
        ) : (
          <div
            className={styles.adderer}
            onClick={() => {
              handleSetAddMode(true);
            }}
          >
            <svg width='12' height='12' viewBox='0 0 12 12' fill='none'>
              <path d='M0 6H12' stroke='#555555' strokeWidth='2' />
              <path d='M6 12L6 0' stroke='#555555' strokeWidth='2' />
            </svg>
          </div>
        )}
      </div>

      {addMode ? (
        <div className={styles.inputses}>
          <div>
            <TextField
              id='outlined-basic'
              label='Город'
              variant='outlined'
              className={styles.txt_input}
              value={saveAdressForm.adress_city}
              onChange={(e) =>
                handleSaveAdressField("adress_city", e.target.value)
              }
            />
            <TextField
              id='outlined-basic'
              label='Улица'
              variant='outlined'
              className={styles.txt_input}
              value={saveAdressForm.adress_street}
              onChange={(e) =>
                handleSaveAdressField("adress_street", e.target.value)
              }
            />
            <TextField
              id='outlined-basic'
              label='Дом'
              variant='outlined'
              className={styles.txt_input_small}
              value={saveAdressForm.adress_dom}
              onChange={(e) =>
                handleSaveAdressField("adress_dom", e.target.value)
              }
            />
            <TextField
              id='outlined-basic'
              label='Корпус'
              variant='outlined'
              className={styles.txt_input_small}
              onChange={(e) =>
                handleSaveAdressField("adress_korpus", e.target.value)
              }
            />
            <TextField
              id='outlined-basic'
              label='Строение'
              variant='outlined'
              className={styles.txt_input_small}
            />
            <TextField
              id='outlined-basic'
              label='Подъезд'
              variant='outlined'
              className={styles.txt_input_small}
            />
            <TextField
              id='outlined-basic'
              label='Офис'
              variant='outlined'
              className={styles.txt_input_small}
              onChange={(e) =>
                handleSaveAdressField("adress_kvartira", e.target.value)
              }
            />
            <Button
              className={styles.allright_button}
              variant='contained'
              color='secondary'
              disableElevation
              onClick={() => handleSaveNewAdress(saveAdressForm)}
            >
              Сохранить адрес
            </Button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default AddressSelector;
