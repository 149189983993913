import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import NoFoundImage from "../../assets/no-image-icon-20.jpg";
import { CartPosition, ProductPosition } from "../../models/product";
import ProductStore from "../../stores/ProductStore";
import { getPositionRubPrice } from "../../utils/utils";
import useCartStore from "../../zustand/cartStore";
import ListingIcon, { ListingIconName } from "./ListingIcon";
import styles from "./ShortProductCard.module.css";

interface ItemData {
  amount: number;
  inFavs: boolean;
  inCart: boolean;
}

interface ShortProductCard {
  position: ProductPosition;
  onDraftAndReservesClick: (position: ProductPosition, amount: number) => void;
  course_our?: string;
}

const ShortProductCard = (props: ShortProductCard) => {
  const { position, onDraftAndReservesClick, course_our } = props;
  const store = ProductStore.Instance;
  const finalUsdPrice = position.promoPrice || position.usdPrice;
  const rubPrice = getPositionRubPrice(1, finalUsdPrice, course_our).toFixed(2);

  const { addToCart, cartItems, changeCartItemQty } = useCartStore();
  const [itemData, setItemData] = useState<ItemData>({
    amount: 1,
    inFavs: false,
    inCart: false,
  });

  useEffect(() => {
    const subscription = store.getFavs().subscribe((value) => {
      let b = value.find((i) => i === position.id) === position.id;
      // if (b !== values.inFavs) {
      setItemData(() => ({ ...itemData, inFavs: b }));
      // }
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAmount = (delta: number) => {
    let n = itemData.amount + delta;
    if (n < 0) {
      n = 0;
    }
    if (n > 100) {
      n = 100;
    }
    setItemData({ ...itemData, amount: n });
  };

  const handleChangeFav = () => {
    store.addToFavs(position.id, itemData.inFavs);
  };

  const history = useHistory();

  function handleGoToDetails() {
    history.push("/product/" + position.id);
  }

  const handleAddToCart = useCallback(
    (position: ProductPosition) => {
      const qty: number = itemData.amount || 1;
      const totalrub = Number(rubPrice) * qty;

      const totalusd: CartPosition["totalusd"] =
        Number(position.promoPrice) * qty;
      const positionInCart: CartPosition | undefined = cartItems.find(
        (item) => item.uuid === position.uuid
      );

      if (positionInCart) {
        changeCartItemQty(position.uuid, qty, "add");
      } else {
        addToCart({
          ...position,
          promoPrice: Number(position.promoPrice),
          usdPrice: Number(position.promoPrice),
          qty,
          totalusd,
          totalrub,
        });
      }
      setItemData({ ...itemData, inCart: true, amount: 1 });
      NotificationManager.success("Позиция успешно добавлена в корзину!");
    },
    [addToCart, cartItems, changeCartItemQty, itemData, rubPrice]
  );

  return (
    <div className={styles.card}>
      <div className={styles.the_image_pane}>
        <div className={styles.labels_pane}>
          <IconButton
            aria-label='add to favorites'
            className={styles.fav_button}
            size='small'
            onClick={() => {
              handleChangeFav();
            }}
          >
            <ListingIcon
              name={
                itemData.inFavs
                  ? ListingIconName.heartfilled
                  : ListingIconName.heart
              }
            ></ListingIcon>
          </IconButton>
        </div>
        <div
          className={styles.the_image}
          onClick={() => {
            handleGoToDetails();
          }}
        >
          <img
            className={styles.the_image_img}
            src={position.imgUrl}
            alt={position.id}
            onError={(event) =>
              // @ts-ignore
              event.target.setAttribute("src", NoFoundImage)
            }
          />
        </div>
        <div className={styles.add_delete_pane}>
          <button
            className={`${styles.select_quantity_button} ${styles.select_quantity_button_minus}`}
            disabled={itemData.amount <= 1}
            onClick={() => {
              handleChangeAmount(-1);
            }}
          >
            -
          </button>
          <span className={styles.total_in_basket}>{itemData.amount}</span>
          <button
            className={styles.select_quantity_button}
            onClick={() => {
              handleChangeAmount(1);
            }}
          >
            +
          </button>
        </div>
        <div className={styles.on_hover_pane}>
          <Button
            className={styles.add_to_basket_button}
            variant='contained'
            color='secondary'
            onClick={() => handleAddToCart(position)}
            disableElevation
          >
            В корзину
          </Button>
          <Button
            className={styles.add_to_something_button}
            variant='contained'
            color='primary'
            disableElevation
            onClick={() => {
              onDraftAndReservesClick(position, itemData.amount);
            }}
          >
            <span>Добавить в</span>
            <svg
              className={styles.drop_icon}
              width='11'
              height='6'
              viewBox='0 0 11 6'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <line
                x1='0.853553'
                y1='0.69674'
                x2='5.8033'
                y2='5.64649'
                stroke='white'
              />
              <line
                y1='-0.5'
                x2='7'
                y2='-0.5'
                transform='matrix(-0.707107 0.707107 0.707107 0.707107 10.4497 1.05029)'
                stroke='white'
              />
            </svg>
          </Button>
        </div>
      </div>
      <div className={styles.category}>
        {position.brand} {position.name}
      </div>
      <div className={styles.sku}>{position.model}</div>
      <div className={styles.price}>
        {"$" + finalUsdPrice + " / " + rubPrice + "₽"}
      </div>
    </div>
  );
};

export default ShortProductCard;
