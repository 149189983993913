import { useState, useEffect } from 'react';
import { Draft, Draft2 } from '../../models/drafts';
import DraftStore from '../../stores/DraftStore';
import DraftLine from './DraftLine';
import DraftLineHeader from './DraftLineHeader';
import styles from './DraftList.module.css';

const DraftList = ({
  onDraftSelect,
}: {
  onDraftSelect: (draft: Draft2) => void;
}) => {
  const store = DraftStore.Instance;
  const [results, setResults] = useState<Draft2[] | undefined>([]);

  useEffect(() => {
    const subscription = store.getDrafts().subscribe((value) => {
      setResults(value);
    });
    store.initDrafts();
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.pane}>
      <div className={styles.header}>Черновики</div>
      <DraftLineHeader></DraftLineHeader>
      <div className={styles.scroll_pane}>
        {results?.map((i) => (
          <DraftLine
            key={i.draftuuid}
            position={i}
            onClick={() => {
              onDraftSelect(i);
            }}
          ></DraftLine>
        ))}
      </div>
    </div>
  );
};

export default DraftList;
