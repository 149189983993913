import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthStore from "../../stores/AuthStore";
import useCartStore from "../../zustand/cartStore";
import NavButton, { NavIconStyle } from "../nav/NavButton";
import { NavIconName } from "../nav/NavIcon";

const Header = () => {
  const authStore = AuthStore.Instance;
  const history = useHistory();
  const { total } = useCartStore();

  useEffect(() => {
    if (authStore.currentSessionToken === "") {
      history.push("/login");
      localStorage.removeItem("main-wh");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavButton
        txt='Каталог'
        navStyle={NavIconStyle.menu}
        name={NavIconName.menu}
        to={["/catalog"]}
      />
      <NavButton txt='Поиск' name={NavIconName.search} to={["/search"]} />
      <NavButton txt='Главная' name={NavIconName.home} to={["/"]}></NavButton>
      <NavButton
        txt='Заказы/Отгрузки'
        name={NavIconName.bag}
        to={["/drafts", "/reserves", "/orders", "/archive"]}
        notexact={true}
      />
      {/* <NavButton txt="Деньги" name={NavIconName.money} to="/money"></NavButton>
        <NavButton txt="Разное" name={NavIconName.diff} to="/diff"></NavButton>
        <NavButton txt="Профиль" name={NavIconName.profile} to="/profile"></NavButton> */}
      <NavButton
        txt='Корзина'
        name={NavIconName.basket}
        to={["/cart"]}
        notexact={true}
        totalCount={total.totalPositions}
      />
    </>
  );
};

export default Header;
