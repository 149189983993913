import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { BankSuggest, LegalEnt, OrgSuggest } from "../../models/orgs";
import InfoStore from "../../stores/InfoStore";
import styles from "./LegalEntsSelector.module.css";

const LegalEntsSelector = ({
  buyer_guid,
  onChange,
  disabled,
}: {
  // le?: LegalEnt | undefined,
  buyer_guid: string;
  onChange: (le: LegalEnt) => void | undefined;
  disabled?: boolean;
}) => {
  const store = InfoStore.Instance;

  const [addMode, setAddMode] = useState(false);
  const [orgFound, setOrgFound] = useState<
    { org?: OrgSuggest; bank?: BankSuggest; error?: string } | undefined
  >(undefined);
  const [INN, setINN] = useState<string>("");
  const [BIK, setBIK] = useState<string>("");
  const [orgProgress, setOrgProgress] = useState<boolean>(false);

  const [acc, setAcc] = useState("");

  const [legs, setLegs] = useState<LegalEnt[]>([]);
  const [bg, setBG] = useState<string>(buyer_guid || "");
  // console.log("bg: " + buyer_guid);

  useEffect(() => {
    setBG(buyer_guid);
  }, [buyer_guid]);
  // const [selLeg, setSelLeg] = React.useS tate<LegalEnt>();

  useEffect(() => {
    const subscription = store.getGetLegal().subscribe((value) => {
      setLegs(value?.ents || []);
    });
    store.getLegal();
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = store.getSuggestByInn().subscribe((value) => {
      setOrgFound(value);
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = store.getSuggestByInnLoading().subscribe((value) => {
      setOrgProgress(value);
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: any) => {
    setBG(event.target.value);
    const sel = legs?.find((x) => x.buyer_guid === event.target.value);
    if (sel) {
      onChange(sel);
    }
  };

  const handleBIKChange = (event: any) => {
    setBIK(event.target.value);
  };

  const handleInnChange = (event: any) => {
    setINN(event.target.value);
  };

  const handleSetAddMode = (val: boolean) => {
    setAddMode(val);
  };

  const handleSearchOrg = () => {
    store.suggestByInn(INN, BIK);
  };

  const handleClearINN = () => {
    setINN("");
    setBIK("");
    setOrgFound(undefined);
  };

  const handleAccChange = (event: any) => {
    setAcc(event.target.value);
  };
  return (
    <div className={styles.top_pane}>
      <div className={styles.pane}>
        <FormControl variant='outlined' className={styles.form_control}>
          <InputLabel id='bg-select-outlined-label'>
            Юридическое лицо Контрагент
          </InputLabel>
          <Select
            labelId='bg-select-outlined-label'
            id='bg-select-outlined'
            value={bg}
            onChange={handleChange}
            label='Юридическое лицо Контрагент'
            disabled={disabled}
          >
            {legs.map((le) => (
              <MenuItem key={le.buyer_guid} value={le.buyer_guid}>
                {le.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* TODO: add legal with dadata */}
        {/* {addMode ? (
          <div
            className={styles.adderer}
            onClick={() => {
              handleSetAddMode(false);
            }}
          >
            <svg width='12' height='12' viewBox='0 0 12 12' fill='none'>
              <path d='M0 6H12' stroke='#555555' strokeWidth='2' />
            </svg>
          </div>
        ) : (
          <div
            className={styles.adderer}
            onClick={() => {
              handleSetAddMode(true);
            }}
          >
            <svg width='12' height='12' viewBox='0 0 12 12' fill='none'>
              <path d='M0 6H12' stroke='#555555' strokeWidth='2' />
              <path d='M6 12L6 0' stroke='#555555' strokeWidth='2' />
            </svg>
          </div>
        )} */}
      </div>

      {addMode ? (
        <div className={styles.inputses}>
          <div className={styles.row}>
            <TextField
              id='outlined-basic'
              label='ИНН'
              variant='outlined'
              className={styles.txt_input}
              value={INN}
              onChange={handleInnChange}
            />
            <TextField
              id='outlined-basic'
              label='БИК'
              variant='outlined'
              className={styles.txt_input}
              value={BIK}
              onChange={handleBIKChange}
            />
            <TextField
              id='outlined-basic'
              label='Расчетный счет'
              variant='outlined'
              className={styles.txt_input_last}
              onChange={handleAccChange}
            />
            {INN !== "" ? (
              <div
                className={styles.adderer}
                onClick={() => {
                  handleClearINN();
                }}
              >
                <svg width='12' height='12' viewBox='0 0 12 12' fill='none'>
                  <path
                    d='M3.18213 9.54599L9.54609 3.18203'
                    stroke='#3A3D4B'
                  ></path>
                  <path
                    d='M9.5459 9.54599L3.18194 3.18203'
                    stroke='#3A3D4B'
                  ></path>
                </svg>
              </div>
            ) : (
              <span></span>
            )}
          </div>
          {orgFound ? (
            <div className={styles.org_info}>
              <div className={styles.org_info_header}>
                Проверьте данные о компании
              </div>
              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>
                  Полное наименование
                </div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.org?.data?.name?.full_with_opf}
                </div>
              </div>

              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>
                  Сокращенное наименование
                </div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.org?.data?.name?.short_with_opf}
                </div>
              </div>

              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>
                  Юридический адрес
                </div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.org?.data?.address?.unrestricted_value}
                </div>
              </div>

              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>ОГРН</div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.org?.data?.ogrn}
                </div>
              </div>

              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>ИНН</div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.org?.data?.inn}
                </div>
              </div>

              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>КПП</div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.org?.data?.kpp}
                </div>
              </div>

              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>ОКПО</div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.org?.data?.okpo}
                </div>
              </div>

              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>ОКОГУ</div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.org?.data?.okogu}
                </div>
              </div>

              <div className={styles.org_info_hard_line}>
                <div className={styles.org_info_line_header}>ОКВЭД</div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.org?.data?.okved}
                </div>
              </div>

              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>
                  Расчетный счет
                </div>
                <div className={styles.org_info_line_val}>{acc}</div>
              </div>

              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>Банк</div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.bank?.unrestricted_value +
                    ", " +
                    orgFound?.bank?.data?.payment_city}
                </div>
              </div>

              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>БИК</div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.bank?.data?.bic}
                </div>
              </div>

              <div className={styles.org_info_hard_line}>
                <div className={styles.org_info_line_header}>БИК</div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.bank?.data?.correspondent_account}
                </div>
              </div>

              <div className={styles.org_info_line}>
                <div className={styles.org_info_line_header}>
                  Генеральный директор
                </div>
                <div className={styles.org_info_line_val}>
                  {orgFound?.org?.data?.management?.name}
                </div>
              </div>
            </div>
          ) : (
            <span></span>
          )}
          {orgFound ? (
            <Button
              className={styles.allright_button}
              variant='contained'
              color='secondary'
              disableElevation
              onClick={() => {}}
            >
              Данные верны
            </Button>
          ) : (
            <Button
              className={styles.allright_button}
              variant='contained'
              color='secondary'
              disableElevation
              onClick={() => {
                handleSearchOrg();
              }}
            >
              Искать по ИНН и БИК
            </Button>
          )}
          {orgProgress ? <CircularProgress></CircularProgress> : <span></span>}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
//7707083893
export default LegalEntsSelector;
