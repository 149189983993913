import Tooltip from "@material-ui/core/Tooltip";
import { ItemType } from "../../../models/common";
import styles from "./MerchReserveItemsListLine.module.css";
interface MerchReserveItemsListLineProps extends ItemType {}

const MerchReserveItemsListLine = (props: MerchReserveItemsListLineProps) => {
  const { position, descr, quantity, pricerub, priceusd, usdsum, rubsum } =
    props;

  return (
    <div className={styles.line}>
      <div className={styles.num}>{position}</div>
      <div className={styles.nameWrapper}>
        <Tooltip title={descr} placement='top'>
          <div className={styles.nameContent}>{descr}</div>
        </Tooltip>
      </div>
      <div className={styles.count1}>-</div>
      <div className={styles.count2}>{quantity}</div>
      <div className={styles.price1}>{priceusd}</div>
      <div className={styles.price2}>{pricerub}</div>
      <div className={styles.price3}>{usdsum}</div>
      <div className={styles.price4}>{rubsum}</div>
    </div>
  );
};
export default MerchReserveItemsListLine;
