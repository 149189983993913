import { NotificationManager } from "react-notifications";
import {
  AdressData,
  SaveAdressRequest,
  SaveAdressResponse,
} from "../models/info";
import { postData } from "../stores/BaseStore";

export const getAdresses = async (SessionToken: string, buyer_guid: string) => {
  let data:
    | {
        adresses: Array<AdressData>;
      }
    | undefined;
  const request: { SessionToken: string; buyer_guid: string } = {
    SessionToken,
    buyer_guid,
  };
  const response = await postData(
    `${process.env.REACT_APP_BASE_URL}/f/getadr`,
    request
  ).ready;
  data = await response.json();

  if (!response.ok || !Array.isArray(data?.adresses)) {
    NotificationManager.error("Ошибка при получении адресов доставки");
    return;
  }

  return data?.adresses;
};

export const saveAdress = async (
  request: SaveAdressRequest
): Promise<SaveAdressResponse | undefined> => {
  try {
    const response = await postData(
      `${process.env.REACT_APP_BASE_URL}/f/saveadress`,
      request
    ).ready;
    const data: SaveAdressResponse = await response.json();
    return data;
  } catch {
    NotificationManager.error("Ошибка при создании нового адреса доставки");
    return;
  }
};
