import { Button, DialogContent, Modal, TextField } from "@material-ui/core";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link, useHistory } from "react-router-dom";
import { Draft2 } from "../../models/drafts";
import { OrderPosition } from "../../models/orders";
import { updateDraftFromCart } from "../../services/cartServices";
import AuthStore from "../../stores/AuthStore";
import { getFullCartRubPrice } from "../../utils/utils";
import useCartStore from "../../zustand/cartStore";
import DraftUndReservesSelector from "../drafts/DraftUndReservesSelector";
import styles from "./CartList.module.css";
import CartListLine from "./CartListLine";

// function createData(
//   name: string,
//   calories: number,
//   fat: number,
//   carbs: number,
//   protein: number
// ) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

const CartList = () => {
  // const [mainCondition, setMainCondition] = useState<ConditionsItem>();
  const [loading, setLoading] = useState<boolean>(false);
  const [saveAsVisible, setSaveAsVisible] = useState<boolean>(false);
  const [reserveVisible, setReserveVisible] = useState<boolean>(false);
  const [commentVisible, setCommentVisible] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [draftsModal, setDraftsModal] = useState<boolean>(false);
  const { cartItems, total, deleteItemFromCart, reset } = useCartStore();
  const authStore = AuthStore.Instance;
  const token = authStore.currentSessionToken;
  const user = authStore.getCurrentUser();
  // const { setConditions } = useConditionsStore();

  const history = useHistory();
  function handleGoToDetails(position: OrderPosition) {
    // history.push("/ca/" + draft.draftuuid);
    // go to details page
  }
  function handleGoToList() {
    history.push("/cart");
  }
  function handleSaveAsClick() {
    if (!saveAsVisible) {
      setReserveVisible(false);
    }
    setSaveAsVisible(!saveAsVisible);
    setCommentVisible(false);
  }
  function handleReserveClick() {
    if (!reserveVisible) {
      setSaveAsVisible(false);
    }
    setReserveVisible(!reserveVisible);
    setCommentVisible(false);
  }
  function handleNewReserveClick() {
    setReserveVisible(false);
    setSaveAsVisible(false);
    setCommentVisible(true);
  }
  function handleAddReserveClick() {
    setDraftsModal(true);
  }

  function handleCheckout() {
    history.push("/cart/new-order");
  }

  async function handleDraftSelect(draft: Draft2) {
    setLoading(true);
    const result = await updateDraftFromCart(draft, cartItems, token);
    if (result && Array.isArray(result)) {
      NotificationManager.success(
        "Черновик успешно обновлен! Перевожу на страницу черновика..."
      );
      reset();
      useCartStore.persist.clearStorage();
      history.push(`drafts/${draft.draftuuid}`);
    }
    // setLoading(false);
    // setDraftsModal(false);
  }

  // const handleCreateNewDraft = useCallback(
  //   async (type: CreateNewDraftType) => {
  //     const newDraftResponse = await createDraftFromCart(
  //       token,
  //       cartItems,
  //       type === 'with comment' ? comment : undefined
  //     );
  //     if (newDraftResponse) {
  //       NotificationManager.success(
  //         'Черновик успешно создан! Перевожу на страницу черновика...'
  //       );
  //       reset();
  //       useCartStore.persist.clearStorage();
  //       history.push(`drafts/${newDraftResponse.order_guid}`);
  //     }
  //     if (newDraftResponse instanceof Error) {
  //       NotificationManager.error(newDraftResponse.message);
  //     } else {
  //       newDraftResponse = {
  //         ...newDraftResponse,
  //         draftuuid: newDraftResponse.order_guid,
  //       };
  //       const updateDraftResponse = await updateDraftFromCart(
  //         newDraftResponse,
  //         cartItems,
  //         token,
  //         type === 'with comment' ? comment : undefined
  //       );
  //       if (updateDraftResponse instanceof Error) {
  //         NotificationManager.error(updateDraftResponse.message);
  //       } else {
  //         NotificationManager.success(
  //           'Черновик успешно создан! Перевожу на страницу черновика...'
  //         );
  //         reset();
  //         useCartStore.persist.clearStorage();
  //         history.push(`drafts/${newDraftResponse.order_guid}`);
  //       }
  //     }
  //   },
  //   [cartItems, comment, history, reset, token]
  // );

  // if (loading) {
  //   return (
  //     <>
  //       <Skeleton animation='wave' />
  //       <Skeleton animation='wave' />
  //       <Skeleton animation='wave' />
  //       <Skeleton animation='wave' />
  //       <Skeleton animation='wave' />
  //       <Skeleton animation='wave' />
  //       <Skeleton animation='wave' />
  //       <Skeleton animation='wave' />
  //     </>
  //   );
  // }

  return (
    <>
      <div className={styles.pane}>
        <div className={styles.cartPage}>
          {cartItems.length > 0 ? (
            <>
              <div className={styles.cartWrapper}>
                {cartItems?.map((cartItem) => (
                  <CartListLine
                    key={cartItem.id}
                    position={cartItem}
                    onDelete={deleteItemFromCart}
                    course_our={user.UserData?.course_our}
                  />
                ))}
              </div>
              <div className={styles.cartInfo}>
                {user.UserData?.percent_our !== undefined &&
                Number(user.UserData?.percent_our) >= 0 ? (
                  <div className={styles.percentWrapper}>
                    <span>Ваш процент отсрочки:</span>
                    <span>{user.UserData?.percent_our}%</span>
                  </div>
                ) : (
                  <></>
                )}
                <div className={styles.super_total}>
                  <span>Итого:</span>
                  <div className={styles.total_prices}>
                    <span>
                      {getFullCartRubPrice(
                        cartItems,
                        user.UserData?.course_our
                      ).toFixed(2)}
                      ₽
                    </span>
                    <span>или</span>
                    <span>{total.totalCartUsd.toFixed(2)}$</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.emptyCart}>
              <span>
                Ваша корзина пуста. Вы можете добавить позиции с помощью{" "}
                <Link to='/search'>поиска</Link>
              </span>
            </div>
          )}
          {cartItems.length > 0 && (
            <div className={styles.buttons}>
              <Button
                className={styles.add_to_basket_button}
                variant='contained'
                color='default'
                disableElevation
                onClick={() => {
                  handleAddReserveClick();
                }}
              >
                Добавить в черновик
              </Button>
              {/* <Button
                className={styles.add_to_basket_button}
                variant='contained'
                color='secondary'
                disableElevation
                onClick={() => {
                  handleReserveClick();
                }}
              >
                Зарезервировать
              </Button> */}
              <Button
                className={styles.add_to_basket_button}
                variant='contained'
                color='primary'
                disableElevation
                onClick={() => {
                  handleCheckout();
                }}
              >
                Новый черновик
              </Button>
            </div>
          )}
          {saveAsVisible && (
            <>
              <div className={styles.buttons}>
                <Button
                  className={styles.add_to_basket_button_2}
                  variant='contained'
                  color='secondary'
                  disableElevation
                  onClick={() => {
                    handleCheckout();
                  }}
                >
                  Новый черновик
                </Button>
              </div>
              <div className={styles.buttons}>
                <Button
                  className={styles.add_to_basket_button_2}
                  variant='contained'
                  color='primary'
                  disableElevation
                  onClick={() => {
                    handleAddReserveClick();
                  }}
                >
                  Добавить в черновик
                </Button>
              </div>
            </>
          )}

          {reserveVisible && (
            <>
              <div className={styles.buttons}>
                <Button
                  className={styles.add_to_basket_button_3}
                  variant='contained'
                  color='secondary'
                  disableElevation
                >
                  Новый резерв
                </Button>
              </div>
              <div className={styles.buttons}>
                <Button
                  className={styles.add_to_basket_button_3}
                  variant='contained'
                  color='primary'
                  disableElevation
                >
                  Добавить в резерв
                </Button>
              </div>
            </>
          )}
          {commentVisible && (
            <div className={styles.comment_top}>
              <span className={styles.comment_header}>Примечание</span>
              <div className={styles.comment_content}>
                <TextField
                  className={styles.comment_text}
                  id='outlined-multiline-static'
                  label='Введите примечание'
                  multiline
                  rows={4}
                  defaultValue=''
                  variant='outlined'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <div className={styles.comment_buttons}>
                  <Button
                    className={styles.save_comment_button}
                    variant='contained'
                    color='primary'
                    disableElevation
                    // onClick={() => handleCreateNewDraft('with comment')}
                  >
                    Сохранить примечание
                  </Button>
                  <Button
                    className={styles.save_no_comment_button}
                    variant='contained'
                    color='default'
                    disableElevation
                    // onClick={() => handleCreateNewDraft('without comment')}
                  >
                    Продолжить без примечаний
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={draftsModal}
        onClose={() => {
          setDraftsModal(false);
        }}
      >
        <DialogContent>
          <DraftUndReservesSelector
            loading={loading}
            onDraftSelect={(draft) => {
              handleDraftSelect(draft);
            }}
          />
        </DialogContent>
      </Modal>
    </>
  );
};

export default CartList;

// TODO: заготовка под обновление таблицы
{
  /* <TableContainer
component={(props: PaperProps) => (
  // @ts-ignore
  <Paper
    {...props}
    classes={{
      root: styles.tabelPaper,
    }}
  />
)}
>
<Table aria-label='simple table'>
  <TableHead>
    <TableRow>
      <TableCell>Dessert (100g serving)</TableCell>
      <TableCell align='right'>Calories</TableCell>
      <TableCell align='right'>Fat&nbsp;(g)</TableCell>
      <TableCell align='right'>Carbs&nbsp;(g)</TableCell>
      <TableCell align='right'>Protein&nbsp;(g)</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {rows.map((row) => (
      <TableRow key={row.name}>
        <TableCell component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell align='right'>{row.calories}</TableCell>
        <TableCell align='right'>{row.fat}</TableCell>
        <TableCell align='right'>{row.carbs}</TableCell>
        <TableCell align='right'>{row.protein}</TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>
</TableContainer> */
}
