import { MouseEventHandler } from 'react';
import { Order } from '../../models/orders';
import styles from './ArchiveLine.module.css';

const ArchiveLine = ({ position, onClick }: {
    position: Order,
    onClick: MouseEventHandler<HTMLDivElement> | undefined
}) => {
    return <div className={styles.line} onClick={onClick}>
        <div className={styles.statuser}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00362 5.43945L5 8.44307L1.99638 5.43945L2.54883 4.88701L4.60938 6.94756V0H5.39062V6.94756L7.45117 4.88701L8.00362 5.43945ZM10 9.21875H0V10H10V9.21875Z" fill="#3A3D4B" />
            </svg>

        </div>
        <div className={styles.status}>{position.status}</div>
        <div className={styles.order_date}>{position.order_date}</div>
        <div className={styles.number}>{position.order_number || ''}</div>
        <div className={styles.legal}>{position.org_name || ''}</div>
        <div className={styles.sum}>{position.sum}</div>
        <div className={styles.comment}>{position.comment}</div>
    </div>

}

export default ArchiveLine