export function postData(url = "", data = {}) {
  const controller = new AbortController();
  const { signal } = controller;

  return {
    abort: () => controller.abort(),
    ready: fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      signal,
    }),
  };
}

export function getData(url = "", config?: RequestInit) {
  const controller = new AbortController();
  const { signal } = controller;
  const defaultConfig: RequestInit = {
    method: "GET",
    mode: "cors",
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
    referrerPolicy: "no-referrer",
    signal,
  };
  const configParams: RequestInit = {
    ...defaultConfig,
    ...config,
  };

  return {
    abort: () => controller.abort(),
    ready: fetch(url, configParams),
  };
}
