import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { getWhList } from "../../services/infoServices";
import AuthStore from "../../stores/AuthStore";
import InfoStore from "../../stores/InfoStore";
import useCartStore from "../../zustand/cartStore";
import useMainWhStore from "../../zustand/mainWhStore";
import styles from "./WelcomeHeader.module.css";

interface WelcomeHeaderProps {
  welcomeText: string;
  limit: string;
  remainder: string;
  userManager?: {
    manager_name: string;
    email: string;
    icq?: string;
  };
}

const WelcomeHeader = (props: WelcomeHeaderProps) => {
  const { welcomeText, userManager } = props;
  const authStore = AuthStore.Instance;
  const infoStore = InfoStore.Instance;
  const token = authStore.currentSessionToken;
  const history = useHistory();
  const whList = useMainWhStore((state) => state.whList);
  const mainWh = useMainWhStore((state) => state.mainWh);
  const setMainWh = useMainWhStore((state) => state.setMainWh);
  const [isChangeMainWhDialog, setIsChangeMainWhDialog] =
    useState<boolean>(false);
  const newMainWhValue = useRef<string | null>(null);
  const resetCart = useCartStore((state) => state.reset);
  const setWhList = useMainWhStore((state) => state.setWhList);
  function doLogout() {
    authStore.logout();
    history.push("/login");
  }

  const handleCancel = useCallback(() => {
    newMainWhValue.current = null;
    setIsChangeMainWhDialog(false);
  }, []);

  const handleChangeMainWh = useCallback(() => {
    if (newMainWhValue.current) {
      setMainWh(newMainWhValue.current);
      resetCart();
      useCartStore.persist.clearStorage();
    }
    setIsChangeMainWhDialog(false);
  }, [resetCart, setMainWh]);

  useEffect(() => {
    let subscription: any;
    if (!mainWh || !whList) {
      try {
        subscription = infoStore.getGetLegal().subscribe(async (value) => {
          if (value?.ents) {
            const whListData = await getWhList(token);
            if (whListData && whListData[0]) {
              setWhList(whListData);
              if (!mainWh) {
                setMainWh(whListData[0].warehouse_guid);
              }
            }
          }
        });
        infoStore.getLegal();
      } catch (e) {
        console.log(e);
      }
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoStore, setMainWh, setWhList, token]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.welcome}>
            {welcomeText} (
            <span className={styles.logout} onClick={() => doLogout()}>
              Выйти
            </span>
            )
          </div>
          {/* <div className={styles.limitbox}>
            <span>Кредитный лимит</span>
            <span className={styles.bold}>-</span>
            <span className={styles.delim}>|</span>
            <span>Остаток от лимита</span>
            <span className={styles.bold}>-</span>
          </div> */}
        </div>
        <div className={styles.additionalInfo}>
          <FormControl className={styles.mainWHFormControl}>
            <span className={styles.mainWHLabel}>
              Основной склад при поиске
            </span>
            <Select
              key={mainWh}
              id='main-wh'
              value={mainWh}
              onChange={(e) => {
                const { value } = e.target;
                newMainWhValue.current = value as string;
                setIsChangeMainWhDialog(true);
              }}
            >
              {whList?.map((whItem) => (
                <MenuItem
                  key={whItem.warehouse_guid}
                  value={whItem.warehouse_guid}
                >
                  {whItem.warehouse_name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Остаток при поиске позиций будет указан в соответствии с данным
              складом
            </FormHelperText>
          </FormControl>
          {userManager && (
            <div className={styles.userManagerWrapper}>
              <span className={styles.userManagerTitle}>
                Менеджера компании Т2
              </span>
              <div className={styles.userManagerContent}>
                <div className={styles.userManagerLine}>
                  <span className={styles.userManagerLineTitle}>ФИО:</span>
                  <span>{userManager.manager_name}</span>
                </div>
                <div className={styles.userManagerLine}>
                  <span className={styles.userManagerLineTitle}>Email:</span>
                  <a
                    href={`mailto:${userManager.email}`}
                    className={styles.userManagerLineEmail}
                  >
                    {userManager.email}
                  </a>
                </div>
                {userManager.icq && (
                  <div className={styles.userManagerLine}>
                    <span className={styles.userManagerLineTitle}>ICQ:</span>
                    <span>{userManager.icq}</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={isChangeMainWhDialog}
        onClose={handleCancel}
        aria-labelledby='change-main-wh-title'
        aria-describedby='change-main-wh-description'
      >
        <DialogTitle id='change-main-wh-title'>
          Изменение основного склада при поиске
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='change-main-wh-description'>
            При смене основного склада при поиске необходимо очистить текущую
            корзину. Продолжить?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant='contained' color='secondary'>
            Отмена
          </Button>
          <Button
            onClick={handleChangeMainWh}
            variant='contained'
            color='primary'
            autoFocus
          >
            Принять
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WelcomeHeader;
