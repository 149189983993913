import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { MappedExchangeRate } from "../../../models/info";
import { getExchangeRate } from "../../../services/infoServices";
import AuthStore from "../../../stores/AuthStore";
import styles from "./index.module.css";
import { mapExchangeRates } from "./utils";

const ExchangeRateComponent = () => {
  const authStore = AuthStore.Instance;
  const token = authStore.currentSessionToken;
  const [exchangeRates, setExchangeRates] = useState<Array<MappedExchangeRate>>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      if (token) {
        setLoading(true);
        const exchangeRates = await getExchangeRate(token);
        if (exchangeRates) {
          const filteredExchangeRates = exchangeRates
            .filter((exchangeRate) => exchangeRate.currency !== "руб.")
            .map<MappedExchangeRate>((value) => {
              const mapped = mapExchangeRates(value);
              return mapped || value;
            });
          setExchangeRates(filteredExchangeRates);
        }
        setLoading(false);
      }
    };
    getData();
  }, [token]);

  if (loading) {
    return (
      <div className={styles.spinnerWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {exchangeRates.length ? (
        <div className={styles.wrapper}>
          <span className={styles.title}>Курсы валют</span>
          <div className={styles.items}>
            {exchangeRates.map((exchangeRate) => (
              <div key={exchangeRate.currency} className={styles.item}>
                <img src={exchangeRate.src} className={styles.image} />
                <div className={styles.item}>
                  <span className={styles.currency}>
                    {exchangeRate.currency}
                  </span>
                  <span>-</span>
                  <span className={styles.currency_rate}>
                    {exchangeRate.currency_rate}₽
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default ExchangeRateComponent;
