import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { ProductDetails, ProductPosition } from "../../../../models/product";
import AuthStore from "../../../../stores/AuthStore";
import { NewProductsTableRow } from "./NewProductsTableRow/NewProductsTableRow";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#3A3D4B",
    fontWeight: 600,
    fontFamily: "Raleway, Arial, Helvetica, sans-serif",
  },
  body: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Raleway, Arial, Helvetica, sans-serif",
  },
}))(TableCell);

interface NewProductsTableProps {
  newProducts: Array<ProductDetails>;
  handleAddToDraft: (position: ProductPosition, amount: number) => void;
}

export const NewProductsTable = (props: NewProductsTableProps) => {
  const { newProducts, handleAddToDraft } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const authStore = AuthStore.Instance;
  const user = authStore.getCurrentUser();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label='new products table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Цвет</StyledTableCell>
              <StyledTableCell>Наименование</StyledTableCell>
              <StyledTableCell>Цена</StyledTableCell>
              <StyledTableCell align='center'>Количество</StyledTableCell>
              <StyledTableCell align='center'>Действия</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newProducts
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((newProduct) => (
                <NewProductsTableRow
                  newProduct={newProduct}
                  key={newProduct.uuid}
                  course_our={user.UserData?.course_our}
                  handleAddToDraft={handleAddToDraft}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20]}
        component='div'
        count={newProducts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage='Позиций на странице:'
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`
        }
      />
    </>
  );
};
