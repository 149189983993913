import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import { Draft2 } from "../../models/drafts";
import { OneReserveResponse } from "../../models/reserves";
import { getInvoiceFile } from "../../services/infoServices";
import { getReserves } from "../../services/reserveServices";
import AuthStore from "../../stores/AuthStore";
import DraftStore from "../../stores/DraftStore";
import MerchDraftLine from "./MerchDraftLine";
import MerchDraftLineHeader from "./MerchDraftLineHeader";
import styles from "./MerchReservesList.module.css";

const MerchReservesList = () => {
  const store = DraftStore.Instance;
  const [results, setResults] = useState<OneReserveResponse[]>([]);
  const authStore = AuthStore.Instance;
  const token = authStore.currentSessionToken;
  const [loadingReserves, setLoadingReserves] = useState<boolean>(false);
  useEffect(() => {
    const getData = async () => {
      setLoadingReserves(true);
      const data = await getReserves(token);
      if (data) {
        setResults(data);
      }
      setLoadingReserves(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  function handleGoToDetails(draft: Draft2 | OneReserveResponse) {
    history.push("/reserves/" + draft.draftuuid);
  }
  function handleGoToList() {
    history.push("/reserves");
  }

  const handleLoadInvoice = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    uuid: string
  ) => {
    event.stopPropagation();
    const data = await getInvoiceFile({
      order_uuid: uuid,
      session_token: token as string,
    });
    if (data) {
      if (data.base64string) {
        let pdfData = Buffer.from(data.base64string, "base64");
        const blob = new Blob([pdfData], { type: "application/pdf" });
        const link = document.createElement("a");
        link.download = "Счет.pdf";
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        NotificationManager.error("Счет в данный момент не доступен.");
      }
    }
  };

  if (loadingReserves) {
    return (
      <div className={styles.spinnerWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.pane}>
      <MerchDraftLineHeader type='reserve' />
      {results.length > 0 ? (
        <div className={styles.scroll_pane}>
          {results?.map((i) => (
            <MerchDraftLine
              key={i.draftuuid}
              position={i}
              onClick={() => {
                handleGoToDetails(i);
              }}
              onDelete={(draft) => {
                // alert(JSON.stringify(id));
                store.deleteDraft(draft.draftuuid).then(() => {
                  handleGoToList();
                });
              }}
              type='reserve'
              handleLoadInvoice={handleLoadInvoice}
            />
          ))}
          {/* <MerchDraftLineAdd
          onAdd={() => {
            // ADD emty chernovik and navigate to details
            store.addEmptyDraft().then((uuid) => {
              if (uuid && typeof uuid === 'string') {
                handleGoToDetails({ draftuuid: uuid, number: '' });
              }
            });
          }}
        ></MerchDraftLineAdd> */}
        </div>
      ) : (
        <div className={styles.empty}>Нет резервов</div>
      )}
    </div>
  );
};

export default MerchReservesList;
