import { IconButton, Input } from '@material-ui/core';
import React, { useEffect } from 'react';
import { DraftPosition2 } from '../../models/drafts';
import styles from './MerchDraftItemsListLine.module.css';

const MerchDraftItemsListLine = ({
  position,
  am,
  processing,
  order,
  onDelete,
  onChangeAmount,
  onSetAmount,
}: {
  position: DraftPosition2;
  am: number;
  processing: boolean;
  order: number;
  onDelete: (draft: DraftPosition2) => void;
  onChangeAmount: (draft: DraftPosition2, delta: number) => void;
  onSetAmount: (draft: DraftPosition2, amount: number) => void;
}) => {
  // const store = DraftStore.Instance;
  const [localAmount, setLocalAmount] = React.useState<string>('' + am);
  useEffect(() => {
    setLocalAmount('' + am);
  }, [am]);

  const handleChangeAmount = (delta: number) => {
    let n = am + delta;
    if (n < 0) {
      n = 0;
    }
    if (n > 100) {
      n = 100;
    }

    if (n !== am) {
      onChangeAmount(position, delta);
    }
    // setAmount(amount);
    // store.addToDraft(draft)
  };

  const handleSetAmount = () => {
    let n = parseInt(localAmount);
    if (n === NaN) {
      n = 1;
    }
    if (n < 0) {
      n = 0;
    }
    if (n > 100) {
      n = 100;
    }

    if (n !== am) {
      onSetAmount(position, n);
    }
    setLocalAmount('' + n);
    // setAmount(amount);
    // store.addToDraft(draft)
  };

  return (
    <div className={styles.line}>
      <div
        className={styles.deleter}
        onClick={() => {
          onDelete(position);
        }}
      >
        <svg width='13' height='13' viewBox='0 0 13 13' fill='none'>
          <path d='M3.18213 9.54599L9.54609 3.18203' stroke='#3A3D4B' />
          <path d='M9.5459 9.54599L3.18194 3.18203' stroke='#3A3D4B' />
        </svg>
      </div>
      <div className={styles.num}>{order}</div>
      <div className={styles.name}>{position.productId}</div>
      <div className={styles.count1}>-</div>
      <div className={styles.count2}>
        <IconButton
          aria-label='add'
          className={styles.add_button}
          size='small'
          onClick={() => {
            handleChangeAmount(1);
          }}
          disabled={processing}
        >
          +
        </IconButton>

        <Input
          readOnly={false}
          value={localAmount}
          disabled={processing}
          onChange={(event) => {
            setLocalAmount(event.target.value);
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleSetAmount();
            }
          }}
          onBlur={() => {
            handleSetAmount();
          }}
        />
        <IconButton
          aria-label='delete'
          className={styles.delete_button}
          size='small'
          onClick={() => {
            handleChangeAmount(-1);
          }}
          disabled={processing}
        >
          -
        </IconButton>
      </div>
      <div className={styles.price1}>{position.priceusd}</div>
      <div className={styles.price2}>{position.pricerub}</div>
      <div className={styles.price3}>{position.usdsum}</div>
      <div className={styles.price4}>{position.rubsum}</div>
    </div>
  );
};

export default MerchDraftItemsListLine;
