import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import debounce from "lodash.debounce";
import React, { ChangeEvent, useEffect, useMemo, useRef } from "react";
import ProductStore from "../../stores/ProductStore";
import styles from "./ListingSearchAndFilterPane.module.css";

const ListingSearchAndFilterPane = () => {
  const store = ProductStore.Instance;
  let [values, setValues] = React.useState<{ options: string[] }>({
    options: [],
  });

  const qq = useRef("");

  useEffect(() => {
    const subscription = store.getSuggest().subscribe((value) => {
      setValues(() => ({ ...values, options: value }));
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetch = useMemo(
    () =>
      debounce((value: string) => {
        // Working wrong
        // store.suggest(value);
        store.search(value);
      }, 400),
    [store]
  );

  const handleInputChange = (_: ChangeEvent<{}>, value: string): void => {
    qq.current = value;
    fetch(value);
  };

  return (
    <>
      <Autocomplete
        className={styles.card}
        id='free-solo'
        freeSolo
        options={values.options}
        filterOptions={(x) => x}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField autoFocus {...params} label='Поиск' margin='normal' />
        )}
      />
      <div className={styles.helperText}>
        Укажите минимум 3 символа для поиска
      </div>
    </>
  );
};

export default ListingSearchAndFilterPane;
