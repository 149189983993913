import { NotificationManager } from "react-notifications";
import { Label, ProductDetails } from "../models/product";
import { postData } from "../stores/BaseStore";

export const getNewProducts = async (
  SessionToken: string,
  labels: Array<Label>
) => {
  let data: Array<ProductDetails> | undefined;
  const request: { SessionToken: string; labels: Array<Label> } = {
    SessionToken,
    labels,
  };
  const response = await postData(
    `${process.env.REACT_APP_BASE_URL}/f/lookforlabels`,
    request
  ).ready;
  data = await response.json();

  if (!response.ok || !Array.isArray(data)) {
    NotificationManager.error("Ошибка при получении новинок");
    return;
  }

  return data;
};

export const getPromotions = async (SessionToken: string) => {
  let data: Array<ProductDetails> | undefined;
  const response = await postData(
    `${process.env.REACT_APP_BASE_URL}/f/lookforlabels`,
    {
      SessionToken,
      labels: ["promo"],
    }
  ).ready;
  data = await response.json();

  if (!response.ok || !Array.isArray(data)) {
    NotificationManager.error("Ошибка при получении акций");
    return;
  }

  return data;
};

export const getInks = async (SessionToken: string) => {
  let data: Array<ProductDetails> | undefined;
  const response = await postData(
    `${process.env.REACT_APP_BASE_URL}/f/lookforlabels`,
    {
      SessionToken,
      labels: ["ink"],
    }
  ).ready;
  data = await response.json();

  if (!response.ok || !Array.isArray(data)) {
    NotificationManager.error("Ошибка при получении списка чернил");
    return;
  }

  return data;
};

export const getZips = async (SessionToken: string) => {
  let data: Array<ProductDetails> | undefined;
  const response = await postData(
    `${process.env.REACT_APP_BASE_URL}/f/lookforlabels`,
    {
      SessionToken,
      labels: ["zipItem"],
    }
  ).ready;
  data = await response.json();

  if (!response.ok || !Array.isArray(data)) {
    NotificationManager.error("Ошибка при получении списка ЗИП");
    return;
  }

  return data;
};
