import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { OneReserveResponse } from "../../../models/reserves";
import MerchReserveItemsListLine from "../MerchReserveItemsListLine/MerchReserveItemsListLine";
import MerchReserveItemsListLineHeader from "../MerchReserveItemsListLineHeader/MerchReserveItemsListLineHeader";
import styles from "./MerchReserveItemsList.module.css";
import { displayReserveConditionDate } from "./utils";

interface MerchReserveItemsListProps {
  reserve?: OneReserveResponse;
}

const MerchReserveItemsList = (props: MerchReserveItemsListProps) => {
  const { reserve } = props;
  const [endToday, setEndToday] = useState<boolean>(false);

  useEffect(() => {
    if (reserve?.["reserveCondition "]) {
      const today = new Date();
      const todayMonth = today.getMonth();
      const todayDate = today.getDate();
      const reserveCondition = new Date(reserve?.["reserveCondition "]);
      const reserveConditionMonth = reserveCondition.getMonth();
      const reserveConditionDate = reserveCondition.getDate();

      if (
        todayMonth === reserveConditionMonth &&
        todayDate === reserveConditionDate
      ) {
        setEndToday(true);
        NotificationManager.warning("Данный резерв истекает сегодня");
      }
    }
  }, [reserve]);

  return (
    <div>
      <MerchReserveItemsListLineHeader />
      <div>
        {reserve?.items.map((item) => (
          <MerchReserveItemsListLine key={item.uuid} {...item} />
        ))}
      </div>
      <div className={styles.total}>
        {reserve?.ndses && (
          <span className={styles.total_lbl}>
            {reserve?.ndses
              ? reserve?.ndses === "Без НДС"
                ? "Без НДС"
                : "НДС " + reserve?.ndses
              : ""}
          </span>
        )}
        {reserve?.ndstotal && (
          <span className={styles.total_sums}>{reserve?.ndstotal}₽</span>
        )}
        {/* <span className={styles.total_lbl}>Доставка</span>
        <span className={styles.total_sums}>???₽</span>
        <div className={styles.total_separator}></div> */}
        <span className={styles.total_lbl}>Итого к оплате</span>
        <span className={styles.total_sums}>{reserve?.sum}₽</span>
      </div>
      <div className={styles.info}>
        <div className={styles.infoLine}>
          <span className={styles.label}>Дата создания:</span>
          <span>{reserve?.cdate}</span>
        </div>
        {reserve?.["reserveCondition "] && (
          <div className={styles.infoLine}>
            <span className={styles.label}>Дата истечения:</span>
            {
              <span className={endToday ? styles.reserveEndToday : ""}>
                <span>
                  {displayReserveConditionDate(reserve?.["reserveCondition "])}
                </span>
              </span>
            }
          </div>
        )}
        <div className={styles.infoLine}>
          <span className={styles.label}>Юридическое лицо Поставщик:</span>
          <span>{reserve?.org_name}</span>
        </div>
        <div className={styles.infoLine}>
          <span className={styles.label}>Юридическое лицо Контрагент:</span>
          <span>{reserve?.buyer_name}</span>
        </div>
        {reserve?.comment && (
          <div className={styles.infoLine}>
            <span className={styles.label}>Примечание:</span>
            <span>{reserve.comment}</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default MerchReserveItemsList;
