import styles from "./MerchDraftItemsListLine.module.css";

const MerchDraftItemsListLineAdd = ({
  order,
  onAdd,
}: {
  order: number;
  onAdd: () => void;
}) => {
  return (
    <div
      className={styles.line}
      onClick={() => {
        onAdd();
      }}
    >
      <div
        className={styles.deleter}
        onClick={() => {
          onAdd();
        }}
      >
        <svg width='9' height='9' viewBox='0 0 9 9' fill='none'>
          <path d='M0 4.5H9' stroke='#33558B' strokeWidth='1.5' />
          <path d='M4.5 9L4.5 0' stroke='#33558B' strokeWidth='1.5' />
        </svg>
      </div>
      <div className={styles.num}>{order}</div>
      <div className={styles.add_label}>Добавить товар</div>
    </div>
  );
};

export default MerchDraftItemsListLineAdd;
