import "./App.css";

import { ThemeProvider, createTheme } from "@material-ui/core";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import MainLayout from "./components/layout/MainLayout";
import ArchivePage from "./components/pages/ArchivePage";
import CartPage from "./components/pages/CartPage";
import CataloguePage from "./components/pages/CataloguePage";
import CheckoutPDNPage from "./components/pages/CheckoutPDNPage";
import CheckoutPage from "./components/pages/CheckoutPage";
import DraftsPage from "./components/pages/DraftsPage";
import ListPage from "./components/pages/ListPage";
import LoginPage from "./components/pages/LoginPage";
import MainPage from "./components/pages/MainPage/MainPage";
import NewOrderPage from "./components/pages/NewOrderPage/NewOrderPage";
import NotFound from "./components/pages/NotFound/NotFound";
import ProductPage from "./components/pages/ProductPage";
import ReservesPage from "./components/pages/ReservesPage";
import { Sales, SingleSale } from "./components/pages/Sales";
import SearchPage from "./components/pages/SearchPage/SearchPage";

const App = () => {
  const theme = createTheme({
    palette: {
      primary: { main: "#3A3D4B" },
      secondary: { main: "#868897" },
      info: { main: "#EFF0F1" },
    },
    shape: {
      borderRadius: 0,
    },
  });

  return (
    <div className='app'>
      <NotificationContainer />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route exact path='/login' render={() => <LoginPage />} />
            <Route
              render={() => (
                <MainLayout
                  header={<Header />}
                  content={
                    <Switch>
                      <Route
                        path='/catalog/:id'
                        render={() => <CataloguePage />}
                      />
                      <Route path='/catalog' render={() => <CataloguePage />} />
                      <Route path='/search' render={() => <SearchPage />} />
                      <Route
                        path='/product/:id'
                        render={() => <ProductPage />}
                      />
                      <Route
                        path='/list/:id/:from/:sort'
                        render={() => <ListPage />}
                      />
                      <Route
                        path='/list/:id/:from'
                        render={() => <ListPage />}
                      />
                      <Route path='/list/:id' render={() => <ListPage />} />

                      <Route path='/drafts/:id' render={() => <DraftsPage />} />
                      <Route path='/drafts' render={() => <DraftsPage />} />

                      <Route
                        path='/reserves/:id'
                        render={() => <ReservesPage />}
                      />
                      <Route path='/reserves' render={() => <ReservesPage />} />
                      <Route exact path='/sales' render={() => <Sales />} />
                      <Route path='/sales/:id' render={() => <SingleSale />} />
                      <Route
                        path='/archive/:id'
                        render={() => <ArchivePage />}
                      />
                      <Route path='/archive' render={() => <ArchivePage />} />
                      <Route
                        path='/cart/new-order'
                        render={() => <NewOrderPage />}
                      />
                      <Route
                        path='/cart/checkout/pdn'
                        render={() => <CheckoutPDNPage />}
                      />
                      <Route
                        path='/cart/checkout'
                        render={() => <CheckoutPage />}
                      />
                      <Route path='/cart' render={() => <CartPage />} />

                      {/* <Route path='/main' render={() => <MainPage />} /> */}
                      <Route exact path='/' render={() => <MainPage />} />
                      <Route path='*' render={() => <NotFound />} />
                    </Switch>
                  }
                  footer={<Footer />}
                />
              )}
            />
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
