import { ProductPosition } from "../../models/product";
import ListingSearchAndFilterPane from "../listing/ListingSearchAndFilterPane";
import ProductLineHeader from "../listing/ProductLineHeader";
import LCListing from "./LCListing";
import styles from "./ListingControl.module.css";

interface ListingControlProps {
  onAddClick: (position: ProductPosition, amount: number) => void;
  draftWhGuid: string;
}

const ListingControl = (props: ListingControlProps) => {
  const { onAddClick, draftWhGuid } = props;
  return (
    <div className={styles.page}>
      <ListingSearchAndFilterPane />
      <ProductLineHeader />
      <LCListing
        draftWhGuid={draftWhGuid}
        onAddClick={(positiom, amount) => {
          onAddClick(positiom, amount);
        }}
      />
    </div>
  );
};
export default ListingControl;
