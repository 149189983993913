import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { DebetItem } from "../models/info";
import { getDebet } from "../services/infoServices";

interface DebetsState {
  currentDebet: DebetItem | undefined;
  isNotFoundCurrentDebet: boolean;
  isLoading: boolean;
  isError: boolean;
  debets: Array<DebetItem>;
  setDebets: (value: Array<DebetItem>) => void;
  setCurrentDebet: (value: DebetItem | undefined) => void;
  changeCurrentDebet: (buyer_guid: string, org_guid: string) => void;
  setIsLoading: (value: boolean) => void;
  setIsError: (value: boolean) => void;
  setIsNotFoundCurrentDebet: (value: boolean) => void;
  fetchDebets: (session_token: string) => Promise<void>;
}

const useDebetsStore = create<DebetsState>()(
  devtools((set, get) => ({
    currentDebet: undefined,
    isLoading: false,
    isError: false,
    isNotFoundCurrentDebet: false,
    debets: [],
    setDebets(value: Array<DebetItem>) {
      set({ debets: value });
    },
    setIsLoading(value: boolean) {
      set({ isLoading: value });
    },
    setIsError(value: boolean) {
      set({ isError: value });
    },
    setCurrentDebet(value: DebetItem | undefined) {
      set({ currentDebet: value });
    },
    setIsNotFoundCurrentDebet(value: boolean) {
      set({ isNotFoundCurrentDebet: value });
    },
    changeCurrentDebet(buyer_guid: string, org_guid: string) {
      get().setIsNotFoundCurrentDebet(false);
      const foundDebet = get().debets.find(
        (debet) =>
          debet.buyer_guid === buyer_guid && debet.org_guid === org_guid
      );
      get().setCurrentDebet(foundDebet);
      if (!foundDebet) {
        get().setIsNotFoundCurrentDebet(true);
      }
    },
    async fetchDebets(session_token: string) {
      get().setIsError(false);
      get().setIsLoading(true);
      const debets = await getDebet(session_token);
      if (debets?.length) {
        get().setDebets(debets);
      } else {
        get().setIsError(true);
      }
      get().setIsLoading(false);
    },
  }))
);

export default useDebetsStore;
