import { Link } from 'react-router-dom';
import styles from './NotFound.module.css';

const NotFound = () => {
  return (
    <div className={styles.wrapper}>
      <h1>Страница не найдена</h1>
      <p>
        Вернутся на <Link to="/">главную</Link>
      </p>
    </div>
  );
};

export default NotFound;
