import { Skeleton } from "@material-ui/lab";
import { ProductPosition } from "../../../models/product";
import AuthStore from "../../../stores/AuthStore";
import useProductsStore from "../../../zustand/newProductsStore";
import CaliderIcon, { CaliderIconName } from "../../main/CaliderIcon";
import ProductCard from "../ProductCard";
import styles from "./NewProductsSlider.module.css";

const productWidth = 208;
const visibleProducts = 5;

interface NewProductsSliderProps {
  handleToDraftAndReservesClick: (
    position: ProductPosition,
    amount: number
  ) => void;
}

const NewProductsSlider = (props: NewProductsSliderProps) => {
  const { handleToDraftAndReservesClick } = props;
  const authStore = AuthStore.Instance;
  const user = authStore.getCurrentUser();
  const { products, error, loading, position, setPosition } =
    useProductsStore();
  const moveLeftEnabled = position > 0;
  const moveRightEnabled = position < products?.length - visibleProducts;

  const doScroll = (delta: number) => {
    const newPos = position + delta;
    if (newPos < 0 || newPos > products.length - visibleProducts) {
      return;
    }

    setPosition(newPos);
  };

  if (loading) {
    return (
      <div className={styles.skeleton}>
        <Skeleton variant='rect' height={364} width={200} />
        <Skeleton variant='rect' height={364} width={200} />
        <Skeleton variant='rect' height={364} width={200} />
        <Skeleton variant='rect' height={364} width={200} />
        <Skeleton variant='rect' height={364} width={200} />
      </div>
    );
  }

  // if (!error) {
  //   return <div className={styles.error}>Ошибка при загрузке новинок</div>;
  // }

  return (
    <>
      {products.length > 0 ? (
        <div className={styles.pane}>
          <div
            className={moveLeftEnabled ? styles.arrow : styles.arrow_disabled}
            onClick={() => doScroll(-1)}
          >
            <CaliderIcon name={CaliderIconName.bla} className={styles.icon} />
          </div>
          <div className={styles.pane_window}>
            <div
              className={styles.long_one}
              style={{
                width: `${productWidth * products.length}px`,
                transform: `translateX(-${productWidth * position}px)`,
              }}
            >
              {products?.map((p) => (
                <ProductCard
                  key={p.id}
                  position={p}
                  onDraftAndReservesClick={(position, amount) => {
                    handleToDraftAndReservesClick(position, amount);
                  }}
                  course_our={user.UserData?.course_our}
                />
              ))}
            </div>
          </div>
          <div
            className={moveRightEnabled ? styles.arrow : styles.arrow_disabled}
            onClick={(e) => {
              doScroll(1);
            }}
          >
            <CaliderIcon name={CaliderIconName.bra} className={styles.icon} />
          </div>
        </div>
      ) : (
        <div className={styles.empty}>В данный момент новинки отсутствуют</div>
      )}
    </>
  );
};
export default NewProductsSlider;
