import styles from "./MerchReserveItemsListLineHeader.module.css";

const MerchReserveItemsListLineHeader = () => {
  return (
    <div className={styles.line_header}>
      <div className={styles.num_header}>№</div>
      <div className={styles.name_header}>Номенклатура</div>
      <div className={styles.count1_header}>Кол. в уп.</div>
      <div className={styles.count2_header}>Кол.</div>
      <div className={styles.price1_header}>Цена&nbsp;$</div>
      <div className={styles.price2_header}>Цена&nbsp;₽</div>
      <div className={styles.price3_header}>Сумма&nbsp;$</div>
      <div className={styles.price4_header}>Сумма&nbsp;₽</div>
    </div>
  );
};

export default MerchReserveItemsListLineHeader;
