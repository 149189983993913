import DialogContent from "@material-ui/core/DialogContent";
import Modal from "@material-ui/core/Modal";
import { useEffect, useState } from "react";
import { Draft2 } from "../../models/drafts";
import { Label, ProductPosition } from "../../models/product";
import DraftStore from "../../stores/DraftStore";
import ProductStore from "../../stores/ProductStore";
import useProductsStore from "../../zustand/newProductsStore";
import NewProductsSlider from "../catalog/NewProductsSlider/NewProductsSlider";
import DraftUndReservesSelector from "../drafts/DraftUndReservesSelector";
import styles from "./NewProducts.module.css";

type props = {
  // skus: string[];
  label: Label;
  header: string;
};

const NewProducts = ({ label, header }: props) => {
  const store = ProductStore.Instance;
  const draftStore = DraftStore.Instance;
  const { setProducts } = useProductsStore();
  const [modalOpen, setModalOpen] = useState(false);
  const [draftPosition, setDraftPosition] = useState<
    ProductPosition | undefined
  >(undefined);
  const [draftAmount, setDraftAmount] = useState<number>(1);

  useEffect(() => {
    const subscription = store.getNewProductDetails().subscribe((value) => {
      setProducts(value);
    });
    store.newProductDetails(label);
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleToDraftAndReservesClick(
    position: ProductPosition,
    amount: number
  ) {
    setDraftPosition(position);
    setDraftAmount(amount);
    setModalOpen(true);
  }

  function handleDraftSelect(draft: Draft2) {
    if (draft && draftPosition) {
      draftStore.addToDraft(
        draftPosition.uuid,
        draft.draftuuid,
        draftAmount || 1
      );
    }
    setModalOpen(false);
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>{header}</div>
      <NewProductsSlider
        handleToDraftAndReservesClick={handleToDraftAndReservesClick}
      />
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <DialogContent>
          <DraftUndReservesSelector
            loading={false}
            onDraftSelect={(draft) => {
              handleDraftSelect(draft);
            }}
          />
        </DialogContent>
      </Modal>
    </div>
  );
};

export default NewProducts;
