import { Link, useRouteMatch } from "react-router-dom";
import styles from "./NavButton.module.css";
import NavIcon, { NavIconName } from "./NavIcon";

export const enum NavIconStyle {
  menu = "menu",
  regular = "regular",
}

interface NavButtonProps {
  txt: string;
  name: NavIconName;
  navStyle?: NavIconStyle;
  to: Array<string>;
  notexact?: boolean;
  totalCount?: number;
}

const NavButton = (props: NavButtonProps) => {
  const { txt, name, navStyle, to, notexact, totalCount } = props;
  const match = useRouteMatch({
    exact: !notexact,
    path: to,
  });
  const cssStyle =
    navStyle === NavIconStyle.menu
      ? NavIconStyle.menu
      : match
      ? "active"
      : "inactive";

  return (
    <Link to={to[0]} className={styles.link}>
      <div className={`${styles.base} ${styles["style_" + cssStyle]}`}>
        <NavIcon className={styles.icon} name={name} />
        <span className={styles.txt}>{txt}</span>
        {totalCount && totalCount > 0 ? (
          <span className={styles.totalCount}>{totalCount}</span>
        ) : (
          <></>
        )}
      </div>
    </Link>
  );
};

export default NavButton;
