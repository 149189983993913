export const EMAIL_REG_EXP =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const MIN_SYMBOLS_FOR_SEARCH = 3;

export const MONTHS = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

export const EXCEL_FILE_FORMATS = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const MILLISECOND_IN_HOUR = 60 * 60 * 1000;
export const MILLISECOND_IN_MINUTE = 60 * 1000;
export const MILLISECOND_IN_SECOND = 1000;
