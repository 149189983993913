import { AdressData } from "../models/info";
import { CartPosition } from "../models/product";

export const getPositionRubPrice = (
  qty: number,
  usdPrice?: number,
  course_our?: string
) => {
  if (!usdPrice || !course_our) {
    return 0;
  }
  return qty * (Math.round(usdPrice * Number(course_our) * 100) / 100);
};

export const getFullCartRubPrice = (
  cartItems: Array<CartPosition>,
  course_our?: string
) => {
  return cartItems.reduce((acc, item) => {
    const itemPrice = getPositionRubPrice(item.qty, item.usdPrice, course_our);
    return acc + itemPrice;
  }, 0);
};

export const displayAddress = (address: AdressData) => {
  const name = address.adress_string || "Нет названия";
  return name;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day =
    date.getDate().toString().length < 2
      ? "0" + date.getDate()
      : date.getDate();
  const month =
    (date.getMonth() + 1).toString().length < 2
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  return `${day}.${month}.${date.getFullYear()}`;
};

export const transformToBase64 = (
  file: File
): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
