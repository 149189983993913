import styles from './ProductLine.module.css';


const ProductLineHeader = () => {
    return <div className={styles.line_header}>
        <div className={styles.color_header}>Цвет</div>
        <div className={styles.name_header}>Наименование</div>
        <div className={styles.price_header}>Цена</div>
        <div className={styles.amount_header}>Остаток</div>
        <div className={styles.add_delete_header}>Количество</div>
    </div>
}

export default ProductLineHeader;