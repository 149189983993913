import { useEffect, useState } from "react";
import { User } from "../../../models/user";
import AuthStore from "../../../stores/AuthStore";
import ExchangeRateComponent from "../../main/ExchangeRateComponent";
import NewProducts from "../../main/NewProducts";
import TodayTomorrow from "../../main/TodayTomorrow/TodayTomorrow";
import WelcomeHeader from "../../main/WelcomeHeader";
import styles from "./MainPage.module.css";
const Mainpage = () => {
  const authStore = AuthStore.Instance;
  const [authValues, setAuthValues] = useState<User>({});

  useEffect(() => {
    const subscription = authStore.getUser().subscribe((value) => {
      setAuthValues(value);
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WelcomeHeader
        welcomeText={`Здравствуйте, ${authValues?.UserData?.Surname} ${authValues?.UserData?.Name}`}
        limit='$300 000'
        remainder='$2 124'
        userManager={authValues?.UserData?.userManager}
      />
      <ExchangeRateComponent />
      {/* <Calider /> */}
      <TodayTomorrow />
      <div className={styles.divider} />
      <NewProducts header='Новинки' label='new' />
      {/* <Promos
        promos={[
          { sku: '1', imgUrl: 'promo1.svg' },
          { sku: '2', imgUrl: 'promo1.svg' },
          { sku: '3', imgUrl: 'promo1.svg' },
          { sku: '4', imgUrl: 'promo1.svg' },
        ]}
      /> */}
    </>
  );
};

export default Mainpage;
