import { NotificationManager } from "react-notifications";
import {
  Base64StringResponse,
  ConditionsItem,
  DebetItem,
  DebetResponse,
  ExchangeRate,
  ExchangeRateResponse,
  GetInvoiceRequest,
  GetLegalResponse,
  GetOurLegalResponse,
  GetWhResponse,
} from "../models/info";
import { getData, postData } from "../stores/BaseStore";

export const getOurLegal = async (session_token: string) => {
  let data: GetOurLegalResponse | undefined;
  const request: { session_token: string } = {
    session_token,
  };
  const response = await postData(
    `${process.env.REACT_APP_BASE_URL}/f/getourlegal`,
    request
  ).ready;
  data = await response.json();
  if (!response.ok || !data?.LegalOurEntities) {
    NotificationManager.error(
      "Ошибка при получении списка юридических лиц Поставщика"
    );
    return;
  }
  return data?.LegalOurEntities;
};

export const getLegal = async (session_token: string) => {
  let data: GetLegalResponse | undefined;
  const request: { session_token: string } = {
    session_token,
  };
  const response = await postData(
    `${process.env.REACT_APP_BASE_URL}/f/getlegal`,
    request
  ).ready;
  data = await response.json();
  if (!response.ok || !data?.LegalEntities) {
    NotificationManager.error(
      "Ошибка при получении списка юридических лиц Контрагента"
    );
    return;
  }
  return data?.LegalEntities;
};

export const getWhList = async (session_token: string) => {
  let data: GetWhResponse | undefined;
  const request: { session_token: string } = {
    session_token,
  };
  const response = await postData(
    `${process.env.REACT_APP_BASE_URL}/f/getwh`,
    request
  ).ready;
  data = await response.json();
  if (!response.ok || !data?.warehouses) {
    NotificationManager.error("Ошибка при получении списка складов");
    return;
  }

  return data.warehouses;
};

// TODO: deprecated
// export const getWhStocks = async (warehouse_guid: string) => {
//   let data: MappedWhStocks | undefined;
//   const request: { warehouse_guid: string } = {
//     warehouse_guid,
//   };
//   const response = await postData(
//     `${process.env.REACT_APP_BASE_URL}/f/getwhstocks`,
//     request
//   ).ready;
//   data = await response.json();
//   if (!response.ok || !data) {
//     NotificationManager.error("Ошибка при получении списка остатков");
//     return;
//   }

//   return data;
// };

export const getConditionsList = async (
  SessionToken: string
): Promise<Array<ConditionsItem> | undefined> => {
  let data: Array<ConditionsItem> | undefined;
  const request: { SessionToken: string } = {
    SessionToken,
  };
  const response = await postData(
    `${process.env.REACT_APP_BASE_URL}/f/getconditions`,
    request
  ).ready;

  if (!response.ok) {
    NotificationManager.error("Ошибка при получении списка курсов валют");
    return;
  }

  data = (await response.json()).contracts;

  if (!Array.isArray(data)) {
    NotificationManager.error("Ошибка при получении списка договоров");
    return;
  }

  return data;
};

export const getExchangeRate = async (
  session_token: string
): Promise<Array<ExchangeRate> | undefined> => {
  let data: Array<ExchangeRate> | undefined;
  const request: { session_token: string } = {
    session_token,
  };
  const response = await postData(
    `${process.env.REACT_APP_BASE_URL}/f/getexchrate`,
    request
  ).ready;

  if (!response.ok) {
    NotificationManager.error("Ошибка при получении списка курсов валют");
    return;
  }

  data = ((await response.json()) as ExchangeRateResponse).exchangerates;

  if (!Array.isArray(data)) {
    NotificationManager.error("Ошибка при получении списка курсов валют");
    return;
  }

  return data;
};

export const getInst = async (): Promise<Base64StringResponse | undefined> => {
  try {
    const response = await getData(
      `${process.env.REACT_APP_BASE_URL}/f/getinst`
    ).ready;
    if (!response.ok) {
      throw new Error();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    NotificationManager.error("Ошибка при получении инструкции");
  }
};

export const getDebet = async (
  SessionToken: string
): Promise<Array<DebetItem> | undefined> => {
  const request: { SessionToken: string } = {
    SessionToken,
  };
  try {
    const response = await postData(
      `${process.env.REACT_APP_BASE_URL}/f/getdebet`,
      request
    ).ready;
    if (!response.ok) {
      throw new Error();
    }
    const data: DebetResponse = await response.json();
    const debets = data.debet;
    if (!Array.isArray(debets)) {
      throw new Error();
    }
    return debets;
  } catch (error) {
    NotificationManager.error("Ошибка при получении данных по дебетам");
  }
};

export const getConditionsFile = async (): Promise<
  Base64StringResponse | undefined
> => {
  try {
    const response = await getData(
      `${process.env.REACT_APP_BASE_URL}/f/getcondfile`
    ).ready;
    if (!response.ok) {
      throw new Error();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    NotificationManager.error("Ошибка при получении условий работы");
  }
};

export const getInvoiceFile = async (
  request: GetInvoiceRequest
): Promise<Base64StringResponse | undefined> => {
  try {
    const response = await postData(
      `${process.env.REACT_APP_BASE_URL}/f/getinvoice`,
      request
    ).ready;
    if (!response.ok) {
      throw new Error();
    }
    const data: Base64StringResponse = await response.json();
    return data;
  } catch (error) {
    NotificationManager.error("Ошибка при получении данных по дебетам");
  }
};
