import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductStore from "../../stores/ProductStore";
import MerchDraftDetails from "../drafts/MerchDraftDetails";
import MerchDraftList from "../drafts/MerchDraftList";
import SubHeader from "../layout/SubHeader";
import styles from "./DraftsPage.module.css";

const DraftsPage = () => {
  const { id } = useParams<{ id: string }>();
  const productStore = ProductStore.Instance;

  useEffect(() => {
    return () => {
      productStore.setSuggest([]);
      productStore.setSearch(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!id) {
    return (
      <div className={styles.page}>
        <div className={styles.header}>
          <SubHeader />
        </div>

        <div className={styles.content}>
          <MerchDraftList></MerchDraftList>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <SubHeader></SubHeader>
      </div>

      <div className={styles.content}>
        <MerchDraftDetails></MerchDraftDetails>
      </div>
    </div>
  );
};
export default DraftsPage;
