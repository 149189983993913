import {
  Button,
  CircularProgress,
  DialogContent,
  Modal,
} from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Draft2 } from "../../models/drafts";
import { OneReserveResponse } from "../../models/reserves";
import DraftStore from "../../stores/DraftStore";
import MerchDraftLine from "./MerchDraftLine";
import MerchDraftLineHeader from "./MerchDraftLineHeader";
import styles from "./MerchDraftList.module.css";

const MerchDraftList = () => {
  const store = DraftStore.Instance;
  const [results, setResults] = React.useState<Draft2[] | undefined>([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [draft, setDraft] = React.useState<
    Draft2 | OneReserveResponse | undefined
  >(undefined);
  const [loadingDrafts, setLoadingDrafts] = useState<boolean>(false);

  React.useEffect(() => {
    const subscription = store.getDrafts().subscribe((value) => {
      setResults(value);
    });
    const loadingSubscription = store.getDraftsLoading().subscribe((value) => {
      setLoadingDrafts(value);
    });
    store.initDrafts();
    return () => {
      subscription.unsubscribe();
      loadingSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  function handleDeleteClick(draft: Draft2 | OneReserveResponse) {
    setDraft(draft);
    setModalOpen(true);
  }

  function handleDeleteConfirm(draft?: Draft2 | OneReserveResponse) {
    if (draft?.draftuuid) {
      store.deleteDraft(draft.draftuuid).then(() => {
        // handleGoToList();
        setModalOpen(false);
      });
    } else {
      // setModalOpen(false);
    }
    // setModalOpen(false);
  }
  function handleDeleteCancel() {
    setModalOpen(false);
  }

  function handleGoToDetails(draft: Draft2) {
    history.push("/drafts/" + draft.draftuuid);
  }
  function handleGoToList() {
    history.push("/drafts");
  }

  if (loadingDrafts) {
    return (
      <div className={styles.spinnerWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={styles.pane}>
      <MerchDraftLineHeader type='draft' />
      {results && results?.length > 0 ? (
        <div className={styles.scroll_pane}>
          {results?.map((i) => (
            <MerchDraftLine
              key={i.draftuuid}
              position={i}
              onClick={() => {
                handleGoToDetails(i);
              }}
              onDelete={(draft) => {
                handleDeleteClick(draft);
              }}
              type='draft'
            />
          ))}
          {/* <MerchDraftLineAdd
          onAdd={() => {
            // ADD emty draft and navigate to details
            store.addEmptyDraft().then((uuid) => {
              if (uuid && typeof uuid === 'string') {
                handleGoToDetails({ draftuuid: uuid, number: '' });
              }
            });
          }}
        /> */}
        </div>
      ) : (
        <div className={styles.empty}>Нет черновиков</div>
      )}

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <DialogContent>
          <div className={styles.modal_pane}>
            <span>Пожалуйста, подтвердите удаление черновика...</span>
            <div className={styles.modal_pane_buttons}>
              <Button
                className={styles.modal_yes_button}
                variant='contained'
                color='secondary'
                disableElevation
                onClick={() => {
                  handleDeleteConfirm(draft);
                }}
              >
                Удалить
              </Button>
              <Button
                className={styles.modal_no_button}
                variant='contained'
                color='primary'
                disableElevation
                onClick={() => {
                  handleDeleteCancel();
                }}
              >
                Отмена
              </Button>
            </div>
          </div>
        </DialogContent>
      </Modal>
    </div>
  );
};

export default MerchDraftList;
