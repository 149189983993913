import React from 'react';
import { useHistory } from 'react-router-dom';
import { Order } from '../../models/orders';
import styles from './ArchiveList.module.css';
import ArchiveLineHeader from './ArchiveLineHeader';
import ArchiveLine from './ArchiveLine';

const ArchiveList = () => {

    // const store = DraftStore.Instance;
    const [results, setResults] = React.useState<Order[] | undefined>([
        {
            status: "На сборке",
            order_date: "01.07.2020",
            shipment_date: "26.12.2020",
            payment_date: "26.12.2021",

            order_number: "267 387 256",
            org_name: "ООО \"Мистер Твистер\"",
            sum: "1 765 567",
            comment: "Отложить подумать",
            items: [
                {
                    position: 1
                },
                {
                    position: 2
                },
                {
                    position: 3
                }
            ]
        },
        {
            status: "Собран",
            order_date: "01.07.2020",
            shipment_date: "26.12.2020",
            payment_date: "26.12.2021",

            order_number: "267 387 256",
            org_name: "ООО \"Мистер Твистер\"",
            sum: "1 765 567",
            comment: "Отложить подумать"
        },
        {
            status: "Отгружен",
            order_date: "01.07.2020",
            shipment_date: "26.12.2020",
            payment_date: "26.12.2021",

            order_number: "267 387 256",
            org_name: "ООО \"Мистер Твистер\"",
            sum: "1 765 567",
            comment: "Отложить подумать"
        },
        {
            status: "Доставлен",
            order_date: "01.07.2020",
            shipment_date: "26.12.2020",
            payment_date: "26.12.2021",

            order_number: "267 387 256",
            org_name: "ООО \"Мистер Твистер\"",
            sum: "1 765 567",
            comment: "Отложить подумать"
        },
    ]);


    // React.useEffect(() => {
    //     const subscription = store.getDrafts().subscribe((value) => {
    //         setResults(value);
    //     });
    //     store.initDrafts();
    //     return () => { subscription.unsubscribe(); }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const history = useHistory();
    function handleGoToDetails(order: Order) {
        // history.push("/orders/" + order.order_number);
    }

    return <div className={styles.pane}>
        <ArchiveLineHeader></ArchiveLineHeader>
        <div className={styles.scroll_pane} >
            {results?.map(i => <ArchiveLine key={i.order_number} position={i} onClick={() => { handleGoToDetails(i); }}></ArchiveLine>)}
        </div>
    </div>
}

export default ArchiveList;