import styles from './DraftLine.module.css';


const DraftLineHeader = () => {
    return <div className={styles.line_header}>
        <div className={styles.date_header}>Дата</div>
        <div className={styles.order_header}>Номер</div>
        <div className={styles.sum_header}>Сумма</div>
        <div className={styles.comment_header}>Примечание</div>
    </div>
}

export default DraftLineHeader;