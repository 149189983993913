import styles from './ArchiveLine.module.css';


const ArchiveLineHeader = () => {
    return <div className={styles.line_header}>
        <div className={styles.status_header}>Документ</div>
        <div className={styles.order_date_header}>Дата заказа</div>
        <div className={styles.number_header}>Номер заказа</div>        
        <div className={styles.legal_header}>Юридическое лицо</div>
        <div className={styles.sum_header}>Сумма заказа</div>
        <div className={styles.comment_header}>Примечание</div>
    </div>
}

export default ArchiveLineHeader;