import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { SaleItem } from "../models/release";
import { getSales } from "../services/release";

interface SalesState {
  loading: boolean;
  sales?: Array<SaleItem>;
  setSales: (value: Array<SaleItem>) => void;
  changeSort: (value: "creationdate", direction: "desc" | "asc") => void;
  fetchSales: (token: string) => Promise<void>;
}

const useSalesStore = create<SalesState>()(
  devtools((set) => ({
    sales: undefined,
    loading: false,
    setSales(value: Array<SaleItem>) {
      set({ sales: value });
    },
    changeSort(value: "creationdate", direction: "desc" | "asc") {
      set((state) => {
        const data = state.sales ? [...state.sales] : undefined;
        if (data) {
          if (value === "creationdate") {
            if (direction == "asc") {
              data.sort(
                (a, b) =>
                  new Date(a.creationdate).getTime() -
                  new Date(b.creationdate).getTime()
              );
            } else {
              data.sort(
                (a, b) =>
                  new Date(b.creationdate).getTime() -
                  new Date(a.creationdate).getTime()
              );
            }
          }
        }

        return { sales: data ? [...data] : undefined };
      });
    },
    fetchSales: async (token) => {
      set({ loading: true });
      const sales = await getSales(token);
      if (sales) {
        sales?.sort(
          (a, b) =>
            new Date(b.creationdate).getTime() -
            new Date(a.creationdate).getTime()
        );
        set({ sales });
      }
      set({ loading: false });
    },
  }))
);

export default useSalesStore;
