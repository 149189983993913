import styles from "./MerchDraftLine.module.css";

interface MerchDraftLineHeaderProps {
  type: "draft" | "reserve";
}

const MerchDraftLineHeader = (props: MerchDraftLineHeaderProps) => {
  const { type } = props;
  return (
    <div
      className={[
        styles.line_header,
        type === "draft" ? styles.lineHeaderDraft : styles.lineHeaderReserve,
      ].join(" ")}
    >
      <div className={styles.date_header}>Дата</div>
      <div className={styles.order_header}>Номер</div>
      <div className={styles.legal_header}>Юридическое лицо Контрагент</div>
      <div className={styles.sum_header}>Сумма</div>
      <div className={styles.comment_header}>Примечание</div>
      {type === "reserve" && <div className={styles.loadInvoice}>Счет</div>}
    </div>
  );
};

export default MerchDraftLineHeader;
