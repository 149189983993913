import { ProductPosition } from "./product";

export interface CatalogueItem {
    id: string;
    name: string;
    icon?: string;
    children?: CatalogueItem[];
    size?: number;
    filter?: { term: string, values: string[] }[];
}

export interface ListRequest {
    filters: { term: string, values: string[] }[],
    from: number,
    size: number,
    sort_field?: string,
    sort_dir?: string
}

export interface ListResponse {
    items: ProductPosition[];
    total: number;
}



export const getPath = (id: string, data: CatalogueItem[]): CatalogueItem[] => {
    if (!id) { return []; }
    for (var i = 0; i < data.length; i++) {
        const item = data[i];
        if (id === item.id) { return [item]; }
        if (item.children) {
            const subResult = getPath(id, item.children);
            if (subResult && subResult.length > 0) { return [item, ...subResult]; }
        }
    }
    return [];
}