import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import NoFoundImage from "../../assets/no-image-icon-20.jpg";
import { Draft2 } from "../../models/drafts";
import { CartPosition, ProductDetails } from "../../models/product";
import AuthStore from "../../stores/AuthStore";
import DraftStore from "../../stores/DraftStore";
import ProductStore from "../../stores/ProductStore";
import { getPositionRubPrice } from "../../utils/utils";
import useCartStore from "../../zustand/cartStore";
import DraftUndReservesSelector from "../drafts/DraftUndReservesSelector";
import ListingIcon, { ListingIconName } from "../listing/ListingIcon";
import styles from "./ProductPage.module.css";

interface ItemData {
  amount: number;
  inFavs: boolean;
  inCart: boolean;
}

const ProductPage = () => {
  const { id } = useParams<{ id: string }>();
  const store = ProductStore.Instance;
  const draftStore = DraftStore.Instance;
  const [position, setPosition] = useState<ProductDetails | undefined>(
    undefined
  );
  const authStore = AuthStore.Instance;
  const user = authStore.getCurrentUser();
  const finalUsdPrice = position ? position.promoPrice || position.usdPrice : 0;
  const rubPrice = getPositionRubPrice(
    1,
    finalUsdPrice,
    user.UserData?.course_our
  ).toFixed(2);

  const { addToCart, cartItems, changeCartItemQty } = useCartStore();
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("1");
  const inputRef = useRef<HTMLInputElement>(null);
  const [itemData, setItemData] = useState<ItemData>({
    amount: 1,
    inFavs: false,
    inCart: false,
  });

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleChangeAmount = (delta: number) => {
    let n = itemData.amount + delta;
    // FOR WHAT??
    if (n > 100) {
      n = 100;
    }
    setItemData({ ...itemData, amount: n });
  };

  useEffect(() => {
    const subscription = store.getProductDetails().subscribe((value) => {
      setPosition(value);
    });
    store.productDetails(id);
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = store.getFavs().subscribe((value) => {
      let b = value.find((i) => i === id) === id;
      if (b !== itemData.inFavs) {
        setItemData(() => ({ ...itemData, inFavs: b }));
      }
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeFav = () => {
    store.addToFavs(id, itemData.inFavs);
  };

  function handleDraftSelect(draft: Draft2) {
    if (draft && position) {
      draftStore.addToDraft(
        position.uuid,
        draft.draftuuid,
        itemData.amount || 1
      );
    }
    setModalOpen(false);
  }

  const handleAddToCart = useCallback(
    (position: ProductDetails) => {
      const qty: number = itemData.amount || 1;
      const totalrub = Number(rubPrice) * qty;
      const totalusd: CartPosition["totalusd"] = Number(finalUsdPrice) * qty;
      const positionInCart: CartPosition | undefined = cartItems.find(
        (item) => item.uuid === position.uuid
      );
      if (positionInCart) {
        changeCartItemQty(position.uuid, qty, "add");
      } else {
        addToCart({
          ...position,
          promoPrice: finalUsdPrice,
          usdPrice: finalUsdPrice,
          qty,
          totalusd,
          totalrub,
        });
      }
      setItemData({ ...itemData, inCart: true, amount: 1 });
      setInputValue("1");
      NotificationManager.success("Позиция успешно добавлена в корзину!");
    },
    [addToCart, cartItems, changeCartItemQty, finalUsdPrice, itemData, rubPrice]
  );

  const handleInputAmount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const valid = /(^$)|(^[1-9][0-9]?$|^100$)/g.test(value);
      if (valid) {
        setInputValue(value);
        if (value) {
          setItemData((prev) => ({ ...prev, amount: Number(value) }));
        }
      }
    },
    []
  );

  useEffect(() => {
    if (inputActive) {
      inputRef.current?.focus();
    }
  }, [inputActive]);

  if (!position) {
    return <CircularProgress className={styles.progress} />;
  }

  return (
    <div className={styles.page}>
      <div className={styles.left_column}>
        <div className={styles.img_block}>
          <IconButton
            aria-label='add to favorites'
            className={styles.fav_button}
            size='small'
            onClick={() => {
              handleChangeFav();
            }}
          >
            <ListingIcon
              name={
                itemData.inFavs
                  ? ListingIconName.heartfilled
                  : ListingIconName.heart
              }
            ></ListingIcon>
          </IconButton>
          <img
            className={styles.the_image_img}
            src={position.imgUrl}
            alt={position.model}
            onError={(event) => {
              // @ts-ignore
              event.target.setAttribute("src", NoFoundImage);
            }}
          />
        </div>
        <div className={styles.icons}>
          {(position?.icons || []).map((i) => (
            <img key={i} className={styles.icon} src={i}></img>
          ))}
        </div>

        <div className={styles.name}>{position?.name}</div>
        <div className={styles.model}>{position?.model}</div>
        <div className={styles.usdPrice}>
          <span>${finalUsdPrice}</span>
        </div>

        <div className={styles.select_quantity}>
          <button
            onClick={(e) => {
              // @ts-ignore
              if (e.nativeEvent.pointerType === "mouse") {
                handleChangeAmount(-1);
              }
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleAddToCart(position);
              }
            }}
            disabled={itemData.amount <= 1}
            className={`${styles.select_quantity_button} ${styles.select_quantity_button_minus}`}
          >
            -
          </button>
          {inputActive ? (
            <input
              ref={inputRef}
              onBlur={() => {
                setInputActive(false);
                setInputValue(String(itemData.amount));
              }}
              value={inputValue}
              className={styles.select_quantity_input}
              onChange={handleInputAmount}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setInputActive(false);
                  handleAddToCart(position);
                }
              }}
            />
          ) : (
            <span
              className={styles.select_quantity_count}
              onClick={() => {
                setInputActive(true);
              }}
            >
              {itemData.amount}
            </span>
          )}
          <button
            onClick={(e) => {
              // @ts-ignore
              if (e.nativeEvent.pointerType === "mouse") {
                handleChangeAmount(1);
              }
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleAddToCart(position);
              }
            }}
            className={styles.select_quantity_button}
          >
            +
          </button>
        </div>
        <Button
          className={styles.add_to_basket_button}
          variant='contained'
          color='secondary'
          disableElevation
          onClick={() => handleAddToCart(position)}
        >
          В корзину
        </Button>
        <Button
          className={styles.add_to_something_button}
          variant='contained'
          color='primary'
          disableElevation
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <span>Добавить в</span>
          <svg
            className={styles.drop_icon}
            width='9'
            height='15'
            viewBox='0 0 9 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <line
              x1='0.646447'
              y1='14.6464'
              x2='8.10857'
              y2='7.18433'
              stroke='white'
            />
            <line
              y1='-0.5'
              x2='10.553'
              y2='-0.5'
              transform='matrix(0.707107 0.707107 0.707107 -0.707107 1 6.10352e-05)'
              stroke='white'
            />
          </svg>
        </Button>
      </div>
      <div className={styles.right_column}>
        {doMainChars(position.mainChars)}
        {doCompatWith("Совместим с принтерами", position.compatWith)}
        {doCompatWith("Не совместим с принтерами", position.notCompatWith)}
        {doChars("Характеристики", [
          ...position.chars,
          {
            name: "OEM",
            value: position.oemcodes.length ? position.oemcodes.join(" ") : "-",
          },
        ])}
      </div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <DialogContent>
          <DraftUndReservesSelector
            loading={false}
            onDraftSelect={(draft) => {
              handleDraftSelect(draft);
            }}
          />
        </DialogContent>
      </Modal>
    </div>
  );
};

const doMainChars = (chars: { name: string; value: string }[]) => {
  if (chars && chars.length > 0) {
    let cnt = 0;
    const rez = (chars || []).map((i) => (
      <React.Fragment key={cnt}>
        <div className={styles.char_line} key={cnt++}>
          <div className={styles.char_header}>{i.name}</div>
          <div className={styles.char_value}>{i.value}</div>
        </div>
      </React.Fragment>
    ));
    return (
      <React.Fragment>
        {rez}
        <div className={styles.separator}></div>
      </React.Fragment>
    );
  } else {
    return "";
  }
};

const doCompatWith = (
  header: string,
  compats: { group: string; models: string[] }[]
) => {
  if (compats && compats.length > 0) {
    let cnt = 0;
    const content = (compats || []).map((i) => (
      <React.Fragment key={cnt}>
        <div className={styles.compat_line} key={cnt++}>
          <div className={styles.compat_header}>{i.group}</div>
          <div className={styles.compat_value}>{i.models.join(" ")}</div>
        </div>
      </React.Fragment>
    ));
    return (
      <React.Fragment>
        <div className={styles.group_header}>{header}</div>
        {content}
        <div className={styles.separator}></div>
      </React.Fragment>
    );
  } else {
    return "";
  }
};

const doChars = (header: string, chars: { name: string; value: string }[]) => {
  if (chars && chars.length > 0) {
    let cnt = 0;
    const content = (chars || []).map((i) => (
      <React.Fragment key={cnt}>
        <div className={styles.compat_line} key={cnt++}>
          <div className={styles.compat_header}>{i.name}</div>
          <div className={styles.compat_value}>{i.value}</div>
        </div>
      </React.Fragment>
    ));
    return (
      <>
        <div className={styles.group_header}>{header}</div>
        {content}
      </>
    );
  } else {
    return "";
  }
};

export default ProductPage;
