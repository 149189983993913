import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { LegalEntity, OurLegalEntity } from "../../../models/info";
import { getOurLegal } from "../../../services/infoServices";
import AuthStore from "../../../stores/AuthStore";
import InfoStore from "../../../stores/InfoStore";
import useDebetsStore from "../../../zustand/debetsStore";
import styles from "./TodayTomorrow.module.css";
import { TodayTomorrowItem } from "./TodayTomorrowItem/TodayTomorrowItem";

const TodayTomorrow = () => {
  const authStore = AuthStore.Instance;
  const token = authStore.currentSessionToken;
  const store = InfoStore.Instance;
  const {
    fetchDebets,
    isLoading: isLoadingDebets,
    isError: isErrorDebets,
    currentDebet,
    setCurrentDebet,
    changeCurrentDebet,
    isNotFoundCurrentDebet,
  } = useDebetsStore();
  const [loadingLegals, setLoadingLegals] = useState<boolean>(false);
  const [loadingOurLegals, setLoadingOurLegals] = useState<boolean>(false);
  const [legals, setLegals] = useState<Array<LegalEntity>>([]);
  const [ourLegals, setOurLegals] = useState<Array<OurLegalEntity>>([]);
  const [legalState, setLegalState] = useState<string>("");
  const [ourLegalState, setOurLegalState] = useState<string>("");
  const allOurLegals = useRef<Array<OurLegalEntity>>([]);

  const handleLegalChange = (event: any) => {
    setLegalState(event.target.value);
    setOurLegalState("");
    setCurrentDebet(undefined);
    const allowedOurLegals =
      legals
        .find((legal) => legal.buyer_guid === event.target.value)
        ?.org.map((item) => item.org_guid) || [];
    const filteredOurLegals = allOurLegals.current.filter((legal) =>
      allowedOurLegals.includes(legal.org_guid)
    );
    setOurLegals(filteredOurLegals);
  };

  const handleOurLegal = (event: any) => {
    setOurLegalState(event.target.value);
  };

  useEffect(() => {
    const loading = store.getGetLegalLoading().subscribe((value) => {
      setLoadingLegals(value);
    });
    const subscription = store.getGetLegal().subscribe((value) => {
      if (value?.ents) {
        setLegals(value.ents);
      }
    });
    store.getLegal();
    return () => {
      subscription.unsubscribe();
      loading.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callGetOurLegal = async () => {
      try {
        if (token) {
          setLoadingOurLegals(true);
          const data = await getOurLegal(token);
          if (data) {
            allOurLegals.current = data;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingOurLegals(false);
      }
    };
    callGetOurLegal();
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchDebets(token);
    }
  }, [fetchDebets, token]);

  useEffect(() => {
    if (legalState && ourLegalState) {
      changeCurrentDebet(legalState, ourLegalState);
    }
  }, [changeCurrentDebet, legalState, ourLegalState]);

  if (isLoadingDebets) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (isErrorDebets) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Дебетовая информация</span>
      <div className={styles.legalControls}>
        <div className={styles.controlWrapper}>
          <div className={styles.controlWrapperHeader}>
            Юридическое лицо Контрагент
          </div>
          {loadingLegals ? (
            <div className={styles.loadingWrapperLegal}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <FormControl variant='outlined' className={styles.formControl}>
                <InputLabel id='demo-simple-select-outlined-label'>
                  Юридическое лицо Контрагент
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  value={legalState}
                  onChange={handleLegalChange}
                  label='Юридическое лицо Контрагент'
                >
                  {legals.map((legal) => (
                    <MenuItem key={legal.buyer_guid} value={legal.buyer_guid}>
                      {legal.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </div>
        <div className={styles.controlWrapper}>
          <div className={styles.controlWrapperHeader}>
            Юридическое лицо Поставщик
          </div>
          {loadingOurLegals ? (
            <div className={styles.loadingWrapperLegal}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <FormControl
                disabled={!legalState}
                variant='outlined'
                className={styles.formControl}
              >
                <InputLabel id='demo-simple-select-outlined-label'>
                  Юридическое лицо Поставщик
                </InputLabel>
                <Select
                  labelId='demo-simple-select-outlined-label'
                  id='demo-simple-select-outlined'
                  value={ourLegalState}
                  onChange={handleOurLegal}
                  disabled={!legalState}
                  label='Юридическое лицо Поставщик'
                >
                  {ourLegals.map((legal) => (
                    <MenuItem key={legal.org_guid} value={legal.org_guid}>
                      {legal.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </div>
      </div>
      {currentDebet ? (
        <div className={styles.contentWrapper}>
          <TodayTomorrowItem debet={currentDebet} isTomorrow={0} />
        </div>
      ) : isNotFoundCurrentDebet ? (
        <div className={styles.unselectedLegalsWrapper}>
          <p className={styles.unselectedLegals}>
            Для данных &quot;Юридическое лицо Контрагент&quot; и
            &quot;Юридическое лицо Поставщик&quot; не найдена дебетовая
            информация
          </p>
        </div>
      ) : (
        <div className={styles.unselectedLegalsWrapper}>
          <p className={styles.unselectedLegals}>
            Для отображения дебетовой информации выберите &quot;Юридическое лицо
            Контрагент&quot; и &quot;Юридическое лицо Поставщик&quot;
          </p>
        </div>
      )}
    </div>
  );
};

export default TodayTomorrow;
