import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CatalogueItem, getPath } from "../../models/catalogue";
import ProductStore from "../../stores/ProductStore";
import CatList from "../catalog/CatList";
import InkCatalogList from "../catalog/InkCatalogList/InkCatalogList";
import NewProductsCatalogList from "../catalog/NewProductsCatalogList";
import PromosCatalogList from "../catalog/PromosCatalogList/PromosCatalogList";
import ZipCatalogList from "../catalog/ZipCatalogList/ZipCatalogList";
import styles from "./CataloguePage.module.css";

// category id goes in as a parameter. If it has children - then we show submenu. Otherwise - direct to the listing page

const CataloguePage = () => {
  const { id } = useParams<{ id: string }>();
  // const ct = testData;

  const store = ProductStore.Instance;
  const [ct, setMenu] = useState<CatalogueItem[] | undefined>(undefined);
  const [loadingMenu, setLoadingMenu] = useState<boolean>(false);

  useEffect(() => {
    const loadingSubscription = store.getMenuLoading().subscribe((value) => {
      setLoadingMenu(value);
    });
    const subscription = store.getMenu().subscribe((value) => {
      setMenu(value);
    });
    store.initMenu();
    return () => {
      loadingSubscription.unsubscribe();
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const path = getPath(id, ct || []);

  const firstActiveId = path.length > 0 ? path[0].id : "";
  const secondActiveId = path.length > 1 ? path[1].id : "";

  return (
    <>
      {loadingMenu ? (
        <div className={styles.loadingWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <div className={styles.page}>
          <CatList
            items={ct || []}
            width='270px'
            hasIconColumn={true}
            header='Тип товара'
            activeId={firstActiveId}
            itemIt2link={(id) => `/catalog/${id}`}
          />
          {firstActiveId === "promo" ? (
            <PromosCatalogList />
          ) : firstActiveId === "new" ? (
            <NewProductsCatalogList />
          ) : firstActiveId === "ink" ? (
            <InkCatalogList />
          ) : firstActiveId === "zip" ? (
            <ZipCatalogList />
          ) : (
            firstActiveId && (
              <CatList
                items={path[0].children || []}
                width='500px'
                header={path[0].name}
                activeId={secondActiveId}
                itemIt2link={(id) => `/catalog/${id}`}
              />
            )
          )}
          {secondActiveId && (
            <CatList
              items={path[1].children || []}
              width='400px'
              header={path[1].name}
              itemIt2link={(id) => `/list/${id}/1/ds/`}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CataloguePage;
