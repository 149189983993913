import Icons from '../img/catalog_icons.svg';

export const enum CatIconName {
    promo = 'promo',
    new = 'new',
    laser = 'laser',
    jet = 'jet',
    ink = 'ink',
    paper = 'paper',
    zip = 'zip',
    jetridge = 'jetridge',
    kantz = 'kantz',
}

const iconSizes: { [id in CatIconName]: { w: number, h: number } } = {
    promo: { w: 15, h: 15 },
    new: { w: 15, h: 15 },
    laser: { w: 15, h: 15 },
    jet: { w: 15, h: 15},
    ink: { w: 13, h: 15 },
    paper: { w: 15, h: 15 },
    zip: { w: 15, h: 15 },
    jetridge: { w: 15, h: 14 },
    kantz: { w: 15, h: 14 },
};

const CatIcon = ({ name, className }: { name: CatIconName, className: string }) => {
    const size = iconSizes[name] || { w: 15, h: 15 };
    return (

        <svg width={size.w} height={size.h} className={className}>
            <use href={`${Icons}#icon-${name}`} />
        </svg>
    )
}

export default CatIcon;
