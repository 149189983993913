import { Link, useRouteMatch } from "react-router-dom";
import styles from "./SubNavButton.module.css";

type props = {
  txt: string;
  to?: any;
  last?: boolean;
};

const SubNavButton = ({ txt, to, last }: props) => {
  const match = useRouteMatch({
    exact: true,
    path: to,
  });
  const cssStyle = match ? "active" : "inactive";
  return (
    <Link to={to} className={styles.link}>
      <div
        className={`${styles.base} ${styles["style_" + cssStyle]} ${
          styles[!!last ? "last" : "notlast"]
        }`}
      >
        <span className={styles.txt}>{txt}</span>
      </div>
    </Link>
  );
};

export default SubNavButton;
