import SubHeader from '../layout/SubHeader';
import styles from './ArchivePage.module.css';
import { useParams } from 'react-router-dom';
import ArchiveList from '../archive/ArchiveList';

const ArchivePage = () => {

    const { id } = useParams<{ id: string }>();
    // const store = DraftStore.Instance;

    if (!id) {
        return <div className={styles.page}>
            <div className={styles.header}>
                <SubHeader></SubHeader>
            </div>

            <div className={styles.content}>
                <ArchiveList></ArchiveList>
            </div>
        </div>
    }
    else {
        return <div className={styles.page}>
            {/* <div className={styles.header}>
                <SubHeader></SubHeader>
            </div>

            <div className={styles.content}>
                <MerchOrderDetails></MerchOrderDetails>
            </div> */}

        </div>
    }

}
export default ArchivePage;