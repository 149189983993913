import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { CartPosition, ItemData, ProductPosition } from "../../models/product";
import ProductStore from "../../stores/ProductStore";
import { getPositionRubPrice } from "../../utils/utils";
import useCartStore from "../../zustand/cartStore";
import useMainWhStore from "../../zustand/mainWhStore";
import ListingIcon, { ListingIconName } from "./ListingIcon";
import styles from "./ProductLine.module.css";

interface ProductLineProps {
  position: ProductPosition;
  onDraftAndReservesClick: (position: ProductPosition, amount: number) => void;
  course_our?: string;
}

const ProductLine = (props: ProductLineProps) => {
  const { position, onDraftAndReservesClick, course_our } = props;
  const store = ProductStore.Instance;
  const whList = useMainWhStore((state) => state.whList);
  const whListLoading = useMainWhStore((state) => state.loading);
  const { addToCart, cartItems, changeCartItemQty } = useCartStore();
  const finalUsdPrice = position.promoPrice || position.usdPrice;
  const rubPrice = getPositionRubPrice(1, finalUsdPrice, course_our).toFixed(2);
  const [itemData, setItemData] = useState<ItemData>({
    amount: 1,
    inFavs: false,
    inCart: false,
  });
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("1");
  const inputRef = useRef<HTMLInputElement>(null);
  const whStocksContent = useMemo(() => {
    const result = whList?.map((wh) => {
      let stock = "0";
      const whStockItem = position.whStocks.find(
        (item) => item.wh_guid === wh.warehouse_guid
      );
      if (whStockItem) {
        stock =
          (Number(whStockItem.stock) || 0) >= 100 ? "100+" : whStockItem.stock;
      }
      return (
        <div key={wh.warehouse_guid}>
          {wh.shortName || `${wh.warehouse_name.slice(0, 4)}...`} - {stock}
        </div>
      );
    });
    return result;
  }, [position.whStocks, whList]);

  useEffect(() => {
    const subscription = store.getFavs().subscribe((value) => {
      let b = value.find((i) => i === position.id) === position.id;
      setItemData(() => ({ ...itemData, inFavs: b }));
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAmount = useCallback(
    (delta: number) => {
      let n = itemData.amount + delta;
      // FOR WHAT??
      if (n > 100) {
        n = 100;
      }
      setItemData({ ...itemData, amount: n });
      setInputValue(String(n));
    },
    [itemData]
  );

  const handleInputAmount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const valid = /(^$)|(^[1-9][0-9]?$|^100$)/g.test(value);
      if (valid) {
        setInputValue(value);
        if (value) {
          setItemData((prev) => ({ ...prev, amount: Number(value) }));
        }
      }
    },
    []
  );

  const handleChangeFav = () => {
    store.addToFavs(position.id, itemData.inFavs);
  };

  const handleAddToCart = useCallback(
    (position: ProductPosition) => {
      const qty: number = itemData.amount || 1;
      const totalrub = Number(rubPrice) * qty;
      const totalusd: CartPosition["totalusd"] = Number(finalUsdPrice) * qty;
      const positionInCart: CartPosition | undefined = cartItems.find(
        (item) => item.uuid === position.uuid
      );

      if (positionInCart) {
        changeCartItemQty(position.uuid, qty, "add");
      } else {
        addToCart({
          ...position,
          promoPrice: finalUsdPrice,
          usdPrice: finalUsdPrice,
          qty,
          totalusd,
          totalrub,
        });
      }
      setItemData({ ...itemData, inCart: true, amount: 1 });
      setInputValue("1");
      NotificationManager.success("Позиция успешно добавлена в корзину!");
    },
    [addToCart, cartItems, changeCartItemQty, finalUsdPrice, itemData, rubPrice]
  );

  useEffect(() => {
    if (inputActive) {
      inputRef.current?.focus();
    }
  }, [inputActive]);

  return (
    <div className={styles.line}>
      <div className={styles.color_hldr}>
        <div className={styles[`color_${position.color || ""}`]}> </div>
      </div>
      {/* <Link className={styles.name} to={"/product/" + position.id}>
        {position.model + " " + (position.brand || "") + " " + position.name}
      </Link> */}
      <div className={styles.name}>
        {position.model + " " + (position.brand || "")}
        <Link className={styles.nameLink} to={"/product/" + position.id}>
          {position.name}
        </Link>
      </div>
      {/* TODO: add icons for labels */}
      {/* <div className={styles.tag}>{position.labels?.join(" ")}</div> */}
      <div className={styles.tag} />
      <div className={styles.fav}>
        <IconButton
          aria-label='add to favorites'
          className={styles.fav_button}
          size='small'
          onClick={() => {
            handleChangeFav();
          }}
        >
          <ListingIcon
            name={
              itemData.inFavs
                ? ListingIconName.heartfilled
                : ListingIconName.heart
            }
          />
        </IconButton>
      </div>
      <div className={styles.price}>
        {"$" + finalUsdPrice + " / " + rubPrice + "₽"}
      </div>
      <div className={styles.whStocks}>
        {whListLoading ? (
          <div className={styles.spinnerWrapper}>
            <CircularProgress size={12} />
          </div>
        ) : (
          whStocksContent
        )}
      </div>
      <div className={styles.select_quantity}>
        <button
          onClick={(e) => {
            // @ts-ignore
            if (e.nativeEvent.pointerType === "mouse") {
              handleChangeAmount(-1);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleAddToCart(position);
            }
          }}
          disabled={itemData.amount <= 1}
          className={`${styles.select_quantity_button} ${styles.select_quantity_button_minus}`}
        >
          -
        </button>
        {inputActive ? (
          <input
            ref={inputRef}
            onBlur={() => {
              setInputActive(false);
              setInputValue(String(itemData.amount));
            }}
            value={inputValue}
            className={styles.select_quantity_input}
            onChange={handleInputAmount}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setInputActive(false);
                handleAddToCart(position);
              }
            }}
          />
        ) : (
          <span
            className={styles.select_quantity_count}
            onClick={() => {
              setInputActive(true);
            }}
          >
            {itemData.amount}
          </span>
        )}
        <button
          onClick={(e) => {
            // @ts-ignore
            if (e.nativeEvent.pointerType === "mouse") {
              handleChangeAmount(1);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleAddToCart(position);
            }
          }}
          className={styles.select_quantity_button}
        >
          +
        </button>
      </div>
      <Button
        className={styles.add_to_basket_button}
        variant='contained'
        color='primary'
        disableElevation
        onClick={() => handleAddToCart(position)}
      >
        В корзину
      </Button>
      <Button
        className={styles.add_to_something_button}
        variant='outlined'
        color='secondary'
        disableElevation
        onClick={() => {
          onDraftAndReservesClick(position, itemData.amount);
        }}
      >
        <span>Добавить в</span>
        <ArrowForwardIosIcon fontSize='small' className={styles.drop_icon} />
      </Button>
    </div>
  );
};

export default ProductLine;
