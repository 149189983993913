import { DialogContent, Modal } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import { Draft2 } from "../../../models/drafts";
import { ProductDetails, ProductPosition } from "../../../models/product";
import { getNewProducts } from "../../../services/products";
import AuthStore from "../../../stores/AuthStore";
import DraftStore from "../../../stores/DraftStore";
import DraftUndReservesSelector from "../../drafts/DraftUndReservesSelector";
import { NewProductsTable } from "./NewProductsTable/NewProductsTable";
import styles from "./styles.module.css";

const NewProductsCatalogList = () => {
  const authStore = AuthStore.Instance;
  const draftStore = DraftStore.Instance;
  const token = authStore.currentSessionToken;
  const [newProducts, setNewProducts] = useState<Array<ProductDetails>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [draftPosition, setDraftPosition] = useState<
    ProductPosition | undefined
  >(undefined);
  const [draftAmount, setDraftAmount] = useState<number>(1);
  const [modalOpen, setModalOpen] = useState(false);

  const handleAddToDraft = (position: ProductPosition, amount: number) => {
    setDraftPosition(position);
    setDraftAmount(amount);
    setModalOpen(true);
  };

  function handleDraftSelect(draft: Draft2) {
    if (draft && draftPosition) {
      draftStore.addToDraft(
        draftPosition.uuid,
        draft.draftuuid,
        draftAmount || 1
      );
    }
    setModalOpen(false);
  }

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await getNewProducts(token, ["new"]);
      if (data) {
        setNewProducts(data);
      }
      setLoading(false);
    };
    getData();
  }, [token]);

  return (
    <>
      <div className={styles.panel}>
        <div className={styles.header_row}>
          <div className={styles.header}>Новинки</div>
        </div>
        <div className={styles.sub_header_row}></div>
        {loading ? (
          <div className={styles.loadingWrapper}>
            <CircularProgress />
          </div>
        ) : newProducts.length > 0 ? (
          <div className={styles.newProducts}>
            <NewProductsTable
              handleAddToDraft={handleAddToDraft}
              newProducts={newProducts}
            />
          </div>
        ) : (
          <div className={styles.noNewProducts}>Новинки отсутствуют</div>
        )}
      </div>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <DialogContent>
          <DraftUndReservesSelector
            loading={false}
            onDraftSelect={(draft) => {
              handleDraftSelect(draft);
            }}
          />
        </DialogContent>
      </Modal>
    </>
  );
};
export default NewProductsCatalogList;
