import SubNavButton from "../nav/SubNavButton";

const SubHeader = () => {
  return (
    <>
      <SubNavButton txt='Черновики' to='/drafts' />
      <SubNavButton txt='Резервы' to='/reserves' />
      <SubNavButton txt='Отгрузки' to='/sales' last={true} />
      {/* <SubNavButton txt="Претензии" to="/tobe3">
        <SubNavButton txt="Архив" to="/archive" last={true}> */}
    </>
  );
};

export default SubHeader;
