import IconButton from "@material-ui/core/IconButton";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { CartPosition } from "../../models/product";
import { getPositionRubPrice } from "../../utils/utils";
import useCartStore from "../../zustand/cartStore";
import styles from "./CartListLine.module.css";

const CartListLine = ({
  position,
  onDelete,
  course_our,
}: {
  position: CartPosition;
  onChange?: (position: CartPosition) => void;
  onDelete: (uuid: string) => void;
  course_our?: string;
}) => {
  const { changeCartItemQty } = useCartStore();
  const [amount, setAmount] = React.useState<number>(position.qty || 0);
  const finalUsdPrice = position.promoPrice || position.usdPrice;
  const rubPrice = getPositionRubPrice(1, finalUsdPrice, course_our).toFixed(2);
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(
    `${position.qty}` || "0"
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const handleChangeAmount = (delta: number) => {
    let n = amount + delta;
    if (n < 0) {
      n = 0;
    }
    if (n > 100) {
      n = 100;
    }
    setAmount(n);
    setInputValue(String(n));
    changeCartItemQty(position.uuid, n, "update");
  };

  const history = useHistory();

  function handleGoToDetails() {
    history.push("/product/" + position.name);
  }

  const handleInputAmount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const valid = /(^$)|(^[1-9][0-9]?$|^100$)/g.test(value);
      if (valid) {
        setInputValue(value);
        if (value) {
          setAmount(Number(value));
        }
      }
    },
    []
  );

  useEffect(() => {
    if (inputActive) {
      inputRef.current?.focus();
    }
  }, [inputActive]);

  return (
    <div className={styles.line}>
      <div
        className={styles.deleter}
        onClick={() => {
          onDelete(position.uuid);
        }}
      >
        <svg width='10' height='10' viewBox='0 0 10 10' fill='none'>
          <path
            d='M10 1.00714L8.99286 0L5 3.99286L1.00714 0L0 1.00714L3.99286 5L0 8.99286L1.00714 10L5 6.00714L8.99286 10L10 8.99286L6.00714 5L10 1.00714Z'
            fill='#D6D7D7'
          />
        </svg>
      </div>

      <div
        className={styles.name}
        onClick={() => {
          handleGoToDetails();
        }}
      >
        <div>
          {position.brand} {position.name}
        </div>
        <div className={styles.name_model}>{position.model}</div>
      </div>
      <div className={styles.select_quantity}>
        <IconButton
          aria-label='delete'
          className={`${styles.select_quantity_button} ${styles.select_quantity_button_minus}`}
          disabled={amount <= 1}
          size='small'
          onClick={() => {
            handleChangeAmount(-1);
          }}
        >
          -
        </IconButton>
        {inputActive ? (
          <input
            ref={inputRef}
            onBlur={() => {
              setInputActive(false);
              setInputValue(String(amount));
              changeCartItemQty(position.uuid, amount, "update");
            }}
            value={inputValue}
            className={styles.select_quantity_input}
            onChange={handleInputAmount}
          />
        ) : (
          <span
            className={styles.select_quantity_count}
            onClick={() => {
              setInputActive(true);
            }}
          >
            {amount}
          </span>
        )}
        <IconButton
          aria-label='add'
          className={styles.select_quantity_button}
          size='small'
          onClick={() => {
            handleChangeAmount(1);
          }}
        >
          +
        </IconButton>
      </div>

      <div className={styles.price_first}>
        {finalUsdPrice ? finalUsdPrice.toFixed(2) : "-"}
      </div>
      <div className={styles.price}>{rubPrice}</div>
      <div className={styles.price}>
        {position.totalusd !== "-"
          ? position.totalusd.toFixed(2)
          : position.totalusd}
      </div>
      <div className={styles.price}>
        {getPositionRubPrice(
          position.qty,
          position.usdPrice,
          course_our
        ).toFixed(2)}
      </div>
    </div>
  );
};

export default CartListLine;
