import CartHeader from "../cart/CartHeader";
import CartList from "../cart/CartList";
import styles from "./CartPage.module.css";

const CartPage = () => {
  return (
    <div className={styles.page}>
      <CartHeader />
      <CartList />
    </div>
  );
};
export default CartPage;
