import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import React, { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { Redirect } from "react-router-dom";
import AuthStore from "../../stores/AuthStore";
import styles from "./LoginPage.module.css";
interface State {
  login: string;
  password: string;
  rememberMe: boolean;
  showPassword: boolean;
  inProcess?: boolean;
  loggedIn?: boolean;
  error?: string;
}

const LoginPage = () => {
  const store = AuthStore.Instance;
  const [values, setValues] = React.useState<State>({
    login: "",
    password: "",
    rememberMe: true,
    showPassword: false,
    inProcess: false,
    loggedIn: false,
    error: "",
  });

  React.useEffect(() => {
    // store.logout();
    const subscription = store.getUser().subscribe((value) => {
      setValues({
        ...values,
        inProcess: value.inProcess,
        loggedIn: value.loggedIn,
        error: value.ErrorMessage,
      });
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleRememberMe = () => {
    setValues({ ...values, rememberMe: !values.rememberMe });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const doLogin = () => {
    store.tryLogin(values.login, values.password, values.rememberMe);
  };

  useEffect(() => {
    if (values.error) {
      NotificationManager.error(values.error);
    }
  }, [values.error]);

  let content;
  if (store.getCurrentUser()?.inProcess) {
    content = <CircularProgress className={styles.progress} />;
  } else if (store.getCurrentUser()?.loggedIn) {
    content = <Redirect to='/' />;
  } else {
    content = (
      <>
        <TextField
          autoFocus
          className={styles.login}
          error={values.error !== ""}
          helperText={values.error}
          label='Логин'
          id='login'
          variant='outlined'
          value={values.login}
          onChange={handleChange("login")}
        />
        <div className={styles.space}></div>
        <FormControl variant='outlined'>
          <InputLabel htmlFor='password'>Password</InputLabel>
          <OutlinedInput
            id='password'
            error={values.error !== ""}
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                doLogin();
                ev.preventDefault();
              }
            }}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.rememberMe}
              onChange={handleRememberMe}
              name='checkedB'
              color='primary'
            />
          }
          label='Запомнить меня'
        />
        <div className={styles.space}></div>
        <Button
          className={styles.action}
          variant='contained'
          color='primary'
          disableElevation
          onClick={() => doLogin()}
        >
          Войти
        </Button>
      </>
    );
  }

  return <div className={styles.border}>{content}</div>;
};

export default LoginPage;
