import { useEffect } from "react";
import ProductStore from "../../../stores/ProductStore";
import ListingSearchAndFilterPane from "../../listing/ListingSearchAndFilterPane";
import styles from "./SearchPage.module.css";
import { SearchPageTable } from "./SearchPageTable/SearchPageTable";

const SearchPage = () => {
  const productStore = ProductStore.Instance;

  useEffect(() => {
    return () => {
      productStore.setSuggest([]);
      productStore.setSearch(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.page}>
      <ListingSearchAndFilterPane />
      <SearchPageTable />
    </div>
  );
};
export default SearchPage;
