import { create } from "zustand";
import { computed } from "zustand-middleware-computed-state";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { CartPosition } from "../models/product";

type ComputedStore = {
  total: {
    totalCartRub: number;
    totalCartUsd: number;
    totalPositions: number;
  };
};

interface CartStore {
  cartItems: Array<CartPosition>;
  addToCart: (value: CartPosition) => void;
  changeCartItemQty: (
    uuid: string,
    qty: number,
    type: CartItemQtyChange
  ) => void;
  deleteItemFromCart: (uuid: string) => void;
  reset: () => void;
}

type SetType = (
  partial:
    | CartStore
    | Partial<CartStore>
    | ((state: CartStore) => CartStore | Partial<CartStore>),
  replace?: boolean | undefined
) => void;

type CombinedStore = CartStore & ComputedStore;
type CartItemQtyChange = "update" | "add";

function computedState(state: CartStore) {
  function getTotal() {
    return state.cartItems.reduce(
      (acc, item) => {
        const itemPriceRub = item.qty * (item.rubPrice || 0);
        const itemPriceUsd = item.qty * (item.usdPrice || 0);
        return {
          totalCartRub: acc.totalCartRub + itemPriceRub,
          totalCartUsd: acc.totalCartUsd + itemPriceUsd,
          totalPositions: acc.totalPositions + item.qty,
        };
      },
      {
        totalCartRub: 0,
        totalCartUsd: 0,
        totalPositions: 0,
      }
    );
  }

  return {
    total: getTotal(),
  };
}

const initialState = {
  cartItems: [],
};

const useCartStore = create<CombinedStore>()(
  immer(
    devtools(
      persist(
        computed<CartStore, ComputedStore>(
          (set: SetType) => ({
            ...initialState,
            addToCart: (value: CartPosition) => {
              set((state) => ({
                cartItems: [...state.cartItems, value],
              }));
            },
            changeCartItemQty: (
              uuid: string,
              qty: number,
              type: CartItemQtyChange
            ) => {
              set((state) => {
                return {
                  cartItems: state.cartItems.map((cartItem) => {
                    if (cartItem.uuid === uuid) {
                      const newQty = type === "add" ? cartItem.qty + qty : qty;
                      const totalrub: CartPosition["totalrub"] =
                        cartItem.rubPrice
                          ? cartItem.rubPrice * newQty
                          : cartItem.totalusd;
                      const totalusd: CartPosition["totalusd"] =
                        cartItem.usdPrice
                          ? cartItem.usdPrice * newQty
                          : cartItem.totalusd;
                      return {
                        ...cartItem,
                        qty: newQty > 100 ? 100 : newQty,
                        totalrub,
                        totalusd,
                      };
                    }
                    return cartItem;
                  }),
                };
              });
            },
            deleteItemFromCart: (uuid) => {
              set((state) => ({
                cartItems: state.cartItems.filter(
                  (cartItem) => cartItem.uuid !== uuid
                ),
              }));
            },
            reset: () => {
              set(initialState);
            },
          }),
          computedState
        ),
        {
          name: "cart-items",
        }
      )
    )
  )
);

export default useCartStore;
