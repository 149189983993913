import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import React, { useEffect, useState } from 'react';
import styles from './CheckoutPDNInput.module.css';
import { validateEmail } from '../../utils/validate';
import useCheckoutStore from '../../zustand/checkoutStore';

interface CheckoutPDNInputProps {
  onFinish: () => void;
}

interface FieldType {
  value: string;
  error: boolean;
  changed: boolean;
}

type PDNInputField =
  | 'name'
  | 'surname'
  | 'patronimic'
  | 'phone'
  | 'mail'
  | 'password'
  | 'repeatPassword';

const CheckoutPDNInput = (props: CheckoutPDNInputProps) => {
  const { onFinish } = props;
  const [agree, setAgree] = React.useState(false);
  const { pdn, setPdn } = useCheckoutStore();

  const [formState, setFormState] = useState<Record<PDNInputField, FieldType>>({
    name: {
      value: '',
      error: false,
      changed: false,
    },
    surname: {
      value: '',
      error: false,
      changed: false,
    },
    patronimic: {
      value: '',
      error: false,
      changed: false,
    },
    phone: {
      value: '',
      error: false,
      changed: false,
    },
    mail: {
      value: '',
      error: false,
      changed: false,
    },
    password: {
      value: '',
      error: false,
      changed: false,
    },
    repeatPassword: {
      value: '',
      error: false,
      changed: false,
    },
  });

  const handleContinue = () => {
    setPdn({
      mail: formState.mail.value,
      name: formState.name.value,
      password: formState.password.value,
      patronimic: formState.patronimic.value,
      phone: formState.phone.value,
      surname: formState.surname.value,
    });
    onFinish();
  };

  const handleValue = (field: PDNInputField, value: string) => {
    setFormState({
      ...formState,
      [field]: { value: value, error: formState[field].error, changed: true },
    });
  };

  const handleError = (field: PDNInputField, value: string) => {
    if (field === 'repeatPassword') {
      setFormState({
        ...formState,
        [field]: {
          value: formState[field].value,
          error: value !== formState.password.value,
          changed: formState[field].changed,
        },
      });
    } else if (field === 'mail') {
      setFormState({
        ...formState,
        [field]: {
          value: formState[field].value,
          error: !validateEmail(value),
          changed: formState[field].changed,
        },
      });
    } else {
      setFormState({
        ...formState,
        [field]: {
          value: formState[field].value,
          error: !value.length,
          changed: formState[field].changed,
        },
      });
    }
  };

  const checkFields = (): boolean => {
    return (
      formState.name.error ||
      !formState.name.changed ||
      formState.surname.error ||
      !formState.surname.changed ||
      formState.phone.error ||
      !formState.phone.changed ||
      formState.mail.error ||
      !formState.mail.changed ||
      formState.password.error ||
      !formState.password.changed ||
      formState.repeatPassword.error ||
      !formState.repeatPassword.changed ||
      !agree
    );
  };

  useEffect(() => {
    if (pdn) {
      setFormState({
        mail: {
          error: false,
          changed: true,
          value: pdn.mail,
        },
        name: {
          error: false,
          changed: true,
          value: pdn.name,
        },
        surname: {
          error: false,
          changed: true,
          value: pdn.surname,
        },
        patronimic: {
          error: false,
          changed: true,
          value: pdn.patronimic,
        },
        password: {
          error: false,
          changed: true,
          value: pdn.password,
        },
        repeatPassword: {
          error: false,
          changed: true,
          value: pdn.password,
        },
        phone: {
          error: false,
          changed: true,
          value: pdn.phone,
        },
      });
    }
  }, [pdn]);

  return (
    <div className={styles.top_pane}>
      <div className={styles.pane}>
        <TextField
          required
          InputLabelProps={{
            classes: {
              asterisk: styles.asterisk,
            },
          }}
          label='Фамилия'
          variant='outlined'
          className={styles.txt_input}
          error={formState.surname.error}
          helperText={formState.surname.error ? 'Обязательное поле' : undefined}
          value={formState.surname.value}
          onBlur={(event) => {
            const value = event.target.value;
            handleError('surname', value);
          }}
          onChange={(event) => {
            const value = event.target.value;
            handleValue('surname', value);
          }}
        />
        <TextField
          required
          InputLabelProps={{
            classes: {
              asterisk: styles.asterisk,
            },
          }}
          id='outlined-basic'
          label='Имя'
          variant='outlined'
          className={styles.txt_input}
          error={formState.name.error}
          helperText={formState.name.error ? 'Обязательное поле' : undefined}
          value={formState.name.value}
          onBlur={(event) => {
            const value = event.target.value;
            handleError('name', value);
          }}
          onChange={(event) => {
            const value = event.target.value;
            handleValue('name', value);
          }}
        />
        <TextField
          id='outlined-basic'
          label='Отчество'
          variant='outlined'
          className={styles.txt_input}
          error={formState.patronimic.error}
          helperText={
            formState.patronimic.error ? 'Обязательное поле' : undefined
          }
          value={formState.patronimic.value}
          onChange={(event) => {
            const value = event.target.value;
            handleValue('patronimic', value);
          }}
        />
      </div>

      <div className={styles.pane}>
        <TextField
          required
          InputLabelProps={{
            classes: {
              asterisk: styles.asterisk,
            },
          }}
          id='outlined-basic'
          label='Телефон'
          variant='outlined'
          className={styles.txt_input}
          error={formState.phone.error}
          helperText={formState.phone.error ? 'Обязательное поле' : undefined}
          value={formState.phone.value}
          onBlur={(event) => {
            const value = event.target.value;
            handleError('phone', value);
          }}
          onChange={(event) => {
            const value = event.target.value;
            handleValue('phone', value);
          }}
        />
      </div>

      <div className={styles.pane}>
        <TextField
          required
          InputLabelProps={{
            classes: {
              asterisk: styles.asterisk,
            },
          }}
          id='outlined-basic'
          label='Email'
          variant='outlined'
          className={styles.txt_input_first}
          error={formState.mail.error}
          helperText={
            formState.mail.error ? 'Формат email - example@mail.ru' : undefined
          }
          value={formState.mail.value}
          onBlur={(event) => {
            const value = event.target.value;
            handleError('mail', value);
          }}
          onChange={(event) => {
            const value = event.target.value;
            handleValue('mail', value);
          }}
        />
      </div>

      <div className={styles.pane}>
        <TextField
          required
          InputLabelProps={{
            classes: {
              asterisk: styles.asterisk,
            },
          }}
          id='outlined-basic'
          label='Пароль'
          type='password'
          variant='outlined'
          className={styles.txt_input_3}
          error={formState.password.error}
          helperText={
            formState.password.error ? 'Обязательное поле' : undefined
          }
          value={formState.password.value}
          onBlur={(event) => {
            const value = event.target.value;
            handleError('password', value);
          }}
          onChange={(event) => {
            const value = event.target.value;
            handleValue('password', value);
          }}
        />
        <TextField
          required
          InputLabelProps={{
            classes: {
              asterisk: styles.asterisk,
            },
          }}
          id='outlined-basic'
          label='Повторите пароль'
          type='password'
          variant='outlined'
          className={styles.txt_input_3}
          error={formState.repeatPassword.error}
          helperText={
            formState.repeatPassword.error ? 'Пароль не совпадает' : undefined
          }
          value={formState.repeatPassword.value}
          onBlur={(event) => {
            const value = event.target.value;
            handleError('repeatPassword', value);
          }}
          onChange={(event) => {
            const value = event.target.value;
            handleValue('repeatPassword', value);
          }}
        />
      </div>

      <div className={styles.pane}>
        <FormControlLabel
          className={styles.check_cb}
          onChange={(event: any) => {
            setAgree(event.target.checked);
          }}
          control={
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
              name='checkedI'
            />
          }
          label={
            <span className={styles.check_cb_txt}>
              {' '}
              Для сохранения данных необходимо ваше согласие с обработкой
              персональных данных
            </span>
          }
        />
      </div>

      <div className={styles.pane}>
        <Button
          className={styles.continue1}
          variant='contained'
          color='primary'
          disableElevation
          disabled={checkFields()}
          onClick={() => {
            handleContinue();
          }}
        >
          Перейти к доставке
        </Button>
      </div>
    </div>
  );
};
export default CheckoutPDNInput;
