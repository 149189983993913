import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ProductPosition } from "../../models/product";
import ProductStore from "../../stores/ProductStore";
import { getPositionRubPrice } from "../../utils/utils";
import ListingIcon, { ListingIconName } from "../listing/ListingIcon";
import styles from "./LCProductLine.module.css";

interface LCProductLineProps {
  position: ProductPosition;
  onAddClick: (position: ProductPosition, amount: number) => void;
  stock?: string;
  course_our?: string;
}

const LCProductLine = (props: LCProductLineProps) => {
  const { position, onAddClick, stock, course_our } = props;
  const finalUsdPrice = position.promoPrice || position.usdPrice;
  const rubPrice = getPositionRubPrice(1, finalUsdPrice, course_our).toFixed(2);
  const store = ProductStore.Instance;

  const [values, setValues] = useState<{
    amount: number;
    inFavs: boolean;
  }>({
    amount: 1,
    inFavs: false,
  });
  const [inputActive, setInputActive] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("1");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const subscription = store.getFavs().subscribe((value) => {
      let b = value.find((i) => i === position.id) === position.id;
      // if (b !== values.inFavs) {
      setValues(() => ({ ...values, inFavs: b }));
      // }
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputAmount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const valid = /(^$)|(^[1-9][0-9]?$|^100$)/g.test(value);
      if (valid) {
        setInputValue(value);
        if (value) {
          setValues((prev) => ({ ...prev, amount: Number(value) }));
        }
      }
    },
    []
  );

  const handleChangeAmount = (delta: number) => {
    let n = values.amount + delta;
    if (n < 0) {
      n = 0;
    }
    if (n > 100) {
      n = 100;
    }
    setValues({ ...values, amount: n });
    setInputValue(String(n));
  };

  const handleChangeFav = () => {
    store.addToFavs(position.id, values.inFavs);
  };

  const history = useHistory();

  function handleGoToDetails() {
    history.push("/product/" + position.id);
  }

  useEffect(() => {
    if (inputActive) {
      inputRef.current?.focus();
    }
  }, [inputActive]);

  return (
    <div className={styles.line}>
      <div className={styles.color_hldr}>
        <div className={styles[`color_${position.color || ""}`]}> </div>
      </div>
      <div
        className={styles.name}
        onClick={() => {
          handleGoToDetails();
        }}
      >
        {position.model + " " + position.name}
      </div>
      {/* TODO: add icons for labels */}
      {/* <div className={styles.tag}>{position.labels?.join(" ")}</div> */}
      <div className={styles.tag} />
      <div className={styles.fav}>
        <IconButton
          aria-label='add to favorites'
          className={styles.fav_button}
          size='small'
          onClick={() => {
            handleChangeFav();
          }}
        >
          <ListingIcon
            name={
              values.inFavs
                ? ListingIconName.heartfilled
                : ListingIconName.heart
            }
          />
        </IconButton>
      </div>
      <div className={styles.price}>
        {"$" + finalUsdPrice + " / " + rubPrice + "₽"}
      </div>
      <div className={styles.amount}>
        <>{(Number(stock) || 0) >= 100 ? "100+" : stock}</>
      </div>
      <div className={styles.select_quantity}>
        <button
          onClick={() => {
            handleChangeAmount(-1);
          }}
          disabled={values.amount <= 1}
          className={`${styles.select_quantity_button} ${styles.select_quantity_button_minus}`}
        >
          -
        </button>
        {inputActive ? (
          <input
            ref={inputRef}
            onBlur={() => {
              setInputActive(false);
              setInputValue(String(values.amount));
            }}
            value={inputValue}
            className={styles.select_quantity_input}
            onChange={handleInputAmount}
          />
        ) : (
          <span
            className={styles.select_quantity_count}
            onClick={() => {
              setInputActive(true);
            }}
          >
            {values.amount}
          </span>
        )}
        <button
          onClick={() => {
            handleChangeAmount(1);
          }}
          className={styles.select_quantity_button}
        >
          +
        </button>
      </div>
      <Button
        className={styles.add_to_basket_button}
        variant='contained'
        color='primary'
        disableElevation
        onClick={() => {
          onAddClick(position, values.amount);
        }}
      >
        Добавить
      </Button>
    </div>
  );
};

export default LCProductLine;
