import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { ItemType } from "../../../../../models/common";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#3A3D4B",
    fontWeight: 600,
    fontFamily: "Raleway, Arial, Helvetica, sans-serif",
  },
  body: {
    fontSize: 12,
    fontWeight: 500,
    fontFamily: "Raleway, Arial, Helvetica, sans-serif",
  },
}))(TableCell);

interface SingleSaleTableProps {
  items?: Array<ItemType>;
}
const SingleSaleTable = (props: SingleSaleTableProps) => {
  const { items } = props;
  return (
    <TableContainer>
      <Table aria-label='sales table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>№</StyledTableCell>
            <StyledTableCell>Номенклатура</StyledTableCell>
            <StyledTableCell width='70px' align='center'>
              Кол. в уп.
            </StyledTableCell>
            <StyledTableCell align='center'>Кол.</StyledTableCell>
            <StyledTableCell align='right'>Цена&nbsp;$</StyledTableCell>
            <StyledTableCell align='right'>Цена&nbsp;₽</StyledTableCell>
            <StyledTableCell align='right'>Сумма&nbsp;$</StyledTableCell>
            <StyledTableCell align='right'>Сумма&nbsp;₽</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((item) => (
            <TableRow hover key={item.uuid}>
              <StyledTableCell>{item.position}</StyledTableCell>
              <StyledTableCell>{item.descr}</StyledTableCell>
              <StyledTableCell align='center'>-</StyledTableCell>
              <StyledTableCell align='center'>{item.quantity}</StyledTableCell>
              <StyledTableCell align='right'>{item.priceusd}</StyledTableCell>
              <StyledTableCell align='right'>{item.pricerub}</StyledTableCell>
              <StyledTableCell align='right'>{item.usdsum}</StyledTableCell>
              <StyledTableCell align='right'>{item.rubsum}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default SingleSaleTable;
