import CheckoutHeader from '../checkout/CheckoutHeader';
import CheckoutPDNInput from '../checkout/CheckoutPDNInput';
import styles from './CheckoutPDNPage.module.css';

const CheckoutPDNPage = () => {
  return (
    <div className={styles.page}>
      <CheckoutHeader></CheckoutHeader>
      <CheckoutPDNInput onFinish={() => {}}></CheckoutPDNInput>
    </div>
  );
};
export default CheckoutPDNPage;
