import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { NewProductsType, ProductDetails } from '../models/product';
import { immer } from 'zustand/middleware/immer';

interface ProductsState {
  products: ProductDetails[];
  error: null | string;
  setProducts: (value: NewProductsType) => void;
  setPosition: (value: number) => void;
  position: number;
  loading: boolean;
}

const useProductsStore = create<ProductsState>()(
  immer(
    devtools((set) => ({
      products: [],
      error: null,
      loading: false,
      position: 0,
      setPosition(value) {
        set((state) => {
          state.position = value;
        });
      },
      setProducts({ data, error, loading }: NewProductsType) {
        set((state) => {
          state.error = error;
          state.loading = loading;
          state.products = data;
        });
      },
    }))
  )
);

export default useProductsStore;
