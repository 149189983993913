import { Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import useCheckoutStore from "../../../zustand/checkoutStore";
import CheckoutAddressSelector from "../../checkout/CheckoutAddressSelector/CheckoutAddressSelector";
import CheckoutLegalEntsSelector from "../../checkout/CheckoutLegalEntsSelector";
import styles from "./NewDraftPage.module.css";

enum TabContent {
  LEGAL_ENTITY = "legal_entity",
  PERSONAL_DATA = "personal_data",
  DELIVERY = "delivery",
}

const NewOrderPage = () => {
  const [tab, setTab] = useState(TabContent.LEGAL_ENTITY);
  const {} = useCheckoutStore();
  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setTab(value);
  };

  const handleFinishLegal = () => {
    setTab(TabContent.DELIVERY);
  };

  // const handleFinishPD = () => {
  //   setTab(TabContent.DELIVERY);
  // };

  return (
    <>
      <div>
        <Tabs
          className={styles.tabs}
          value={tab}
          onChange={handleChange}
          variant='fullWidth'
        >
          <Tab
            className={styles.tab}
            value={TabContent.LEGAL_ENTITY}
            label='Выбор юридических лиц'
            onClick={() => setTab(tab)}
            disableRipple
          />
          {/* <Tab
            className={styles.tab}
            value={TabContent.PERSONAL_DATA}
            label='Заполнение персональных данных'
            onClick={() => setTab(tab)}
            disableRipple
          /> */}
          <Tab
            className={styles.tab}
            value={TabContent.DELIVERY}
            label='Выбор доставки'
            onClick={() => setTab(tab)}
            disableRipple
          />
        </Tabs>
        <div className={styles.content}>
          {tab === TabContent.LEGAL_ENTITY && (
            <CheckoutLegalEntsSelector onFinish={handleFinishLegal} />
          )}
          {/* {tab === TabContent.PERSONAL_DATA && (
            <CheckoutPDNInput onFinish={handleFinishPD} />
          )} */}
          {tab === TabContent.DELIVERY && <CheckoutAddressSelector />}
        </div>
      </div>
    </>
  );
};
export default NewOrderPage;
