import BlackIcon from '../../../assets/BlackIcon.png';
import ChinaIcon from '../../../assets/ChinaIcon.png';
import EuropeanUnionIcon from '../../../assets/EuropeanUnionIcon.png';
import KazakhstanIcon from '../../../assets/KazakhstanIcon.png';
import USAIcon from '../../../assets/USAIcon.png';
import { ExchangeRate, MappedExchangeRate } from '../../../models/info';

export const mapExchangeRates = (
  exchangeRate: ExchangeRate
): MappedExchangeRate => {
  switch (exchangeRate.currency) {
    case 'CNY':
      return { ...exchangeRate, src: ChinaIcon };
    case 'EUR':
      return { ...exchangeRate, src: EuropeanUnionIcon };
    case 'KZT':
      return { ...exchangeRate, src: KazakhstanIcon };
    case 'USD':
      return { ...exchangeRate, src: USAIcon };
    default:
      return { ...exchangeRate, src: BlackIcon };
  }
};
