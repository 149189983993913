import { BehaviorSubject } from "rxjs";
import { LegalEntity } from "../models/info";
import { BankSuggest, OrgSuggest } from "../models/orgs";
import { CaliderResponse, TodayTomorrowResponse } from "../models/user";
import AuthStore from "./AuthStore";
import { postData } from "./BaseStore";

class InfoStore {
  private static _instance: InfoStore;
  public static get Instance() {
    return this._instance || (this._instance = new InfoStore());
  }

  private currentSessionToken = "";
  private lastCaliderReqTime?: number;
  private lastTTReqTime?: number;
  public constructor() {
    AuthStore.Instance.getUser().subscribe((x) => {
      this.currentSessionToken = x?.SessionToken || "";
    });
  }

  private caliderSubject = new BehaviorSubject<CaliderResponse>({
    inProcess: false,
  });
  private ttSubject = new BehaviorSubject<TodayTomorrowResponse>({
    inProcess: false,
  });

  public getCalider() {
    return this.caliderSubject.asObservable();
  }

  public getCurrentCalider() {
    return this.caliderSubject.value;
  }

  public loadCalider() {
    if (this.lastCaliderReqTime) {
      const rnow = new Date().getTime();
      if (rnow - this.lastCaliderReqTime < 300000) {
        return;
      }
    }
    const error = (err: string) => {
      this.caliderSubject.next({ inProcess: false, ErrorMessage: err });
    };
    this.caliderSubject.next({ inProcess: true, ErrorMessage: "", events: [] });
    postData(`${process.env.REACT_APP_BASE_URL}/f/calider`, {
      SessionToken: this.currentSessionToken,
    })
      .ready.then((x) => {
        if (!x.ok) {
          error(x.statusText);
        } else {
          return x.json();
        }
      })
      .then((x: CaliderResponse) => {
        if (x) {
          if (x.ErrorMessage) {
            error(x.ErrorMessage);
          } else {
            this.caliderSubject.next({
              inProcess: false,
              ErrorMessage: "",
              events: x.events || [],
            });
            this.lastCaliderReqTime = new Date().getTime();
          }
        }
      })
      .catch((error) => {
        error(error);
      });
  }

  public getTT() {
    return this.ttSubject.asObservable();
  }

  public getCurrentTT() {
    return this.ttSubject.value;
  }

  public loadTT() {
    if (this.lastTTReqTime) {
      const rnow = new Date().getTime();
      if (rnow - this.lastTTReqTime < 300000) {
        return;
      }
    }
    const error = (err: string) => {
      this.ttSubject.next({ inProcess: false, ErrorMessage: err });
    };
    this.ttSubject.next({ inProcess: true, ErrorMessage: "" });
    postData(`${process.env.REACT_APP_BASE_URL}/f/todaytomorrow`, {
      SessionToken: this.currentSessionToken,
    })
      .ready.then((x) => {
        if (!x.ok) {
          error(x.statusText);
        } else {
          return x.json();
        }
      })
      .then((x: TodayTomorrowResponse) => {
        if (x) {
          if (x.ErrorMessage) {
            error(x.ErrorMessage);
          } else {
            this.ttSubject.next({
              inProcess: false,
              ErrorMessage: "",
              today: x.today,
              tomorrow: x.tomorrow,
            });
            this.lastTTReqTime = new Date().getTime();
          }
        }
      })
      .catch((error) => {
        error(error);
      });
  }

  private currentSuggestByINN?: { abort: () => void; ready: Promise<Response> };
  private suggestByINNSubject = new BehaviorSubject<
    { org?: OrgSuggest; bank?: BankSuggest; error?: string } | undefined
  >(undefined);
  private suggestByINNLoadingSubject = new BehaviorSubject<boolean>(false);
  public getSuggestByInn() {
    return this.suggestByINNSubject.asObservable();
  }
  public getSuggestByInnLoading() {
    return this.suggestByINNLoadingSubject.asObservable();
  }
  public suggestByInn(query: string, bik: string) {
    if (this.currentSuggestByINN) {
      this.currentSuggestByINN.abort();
    }
    const fin = () => {
      this.currentSuggestByINN = undefined;
      this.suggestByINNLoadingSubject.next(false);
    };
    this.suggestByINNLoadingSubject.next(true);
    this.currentSuggestByINN = postData(
      `${process.env.REACT_APP_BASE_URL}/f/suggestbyinn`,
      {
        SessionToken: this.currentSessionToken,
        query: query,
        bik: bik,
      }
    );
    this.currentSuggestByINN.ready
      .then((x) => (x.ok ? x.json() : fin()))
      .then(
        (x: {
          inn: { suggestions?: OrgSuggest[]; message?: string };
          bik: { suggestions?: BankSuggest[]; message?: string };
        }) => {
          if (x.inn.message || x.bik.message) {
            this.suggestByINNSubject.next({
              error: x.inn.message + "; " + x.bik.message,
            });
          } else {
            let org =
              x.inn.suggestions && x.inn.suggestions.length > 0
                ? x.inn.suggestions[0]
                : undefined;
            let bank =
              x.bik.suggestions && x.bik.suggestions.length > 0
                ? x.bik.suggestions[0]
                : undefined;

            this.suggestByINNSubject.next(
              org ? { org: org, bank: bank } : undefined
            );
          }

          fin();
        }
      )
      .catch((error) => {
        this.suggestByINNSubject.next({ error: JSON.stringify(error) });
        fin();
      });

    /* {"message":"connect ETIMEDOUT 185.129.103.75:443","name":"Error","stack":"Error: connect ETIMEDOUT 185.129.103.75:443\n    at TCPConnectWrap.afterConnect [as oncomplete] (net.js:1148:16)","config":{"url":"https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party","method":"post","data":"{\"query\":\"7707083893\",\"branch_type\":\"MAIN\"}","headers":{"Accept":"application/json","Content-Type":"application/json","Authorization":"Token dc648e2b1aa4b0dd96a414cfd5cddc9de7918891","User-Agent":"axios/0.21.1","Content-Length":43},"transformRequest":[null],"transformResponse":[null],"timeout":0,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1},"code":"ETIMEDOUT"} */
  }

  private currentGetLegal?: { abort: () => void; ready: Promise<Response> };
  private getLegalSubject = new BehaviorSubject<
    { ents?: Array<LegalEntity>; error?: string } | undefined
  >(undefined);
  private getLegalLoadingSubject = new BehaviorSubject<boolean>(false);
  public getGetLegal() {
    return this.getLegalSubject.asObservable();
  }
  public getGetLegalLoading() {
    return this.getLegalLoadingSubject.asObservable();
  }
  public getLegal() {
    if (this.currentGetLegal) {
      this.currentGetLegal.abort();
    }
    const fin = () => {
      this.currentGetLegal = undefined;
      this.getLegalLoadingSubject.next(false);
    };
    if (this.currentSessionToken) {
      this.getLegalLoadingSubject.next(true);

      this.currentGetLegal = postData(
        `${process.env.REACT_APP_BASE_URL}/f/getlegal`,
        {
          SessionToken: this.currentSessionToken,
        }
      );

      this.currentGetLegal.ready
        .then((x) => (x.ok ? x.json() : fin()))
        .then(
          (x: { LegalEntities: Array<LegalEntity>; ErrorMessage: string }) => {
            if (x.ErrorMessage) {
              this.getLegalSubject.next({ error: x.ErrorMessage });
            } else {
              this.getLegalSubject.next({ ents: x.LegalEntities });
            }
            fin();
          }
        )
        .catch((error) => {
          this.getLegalSubject.next({ error: JSON.stringify(error) });
          fin();
        });
    }
  }
}

export default InfoStore;
