import { DebetItem } from "../../../../models/info";
import { MONTHS } from "../../../../utils/const";
import styles from "./TodayTomorrowItem.module.css";
interface TodayTomorrowItemProps {
  debet: DebetItem;
  isTomorrow: number;
}

export const TodayTomorrowItem = (props: TodayTomorrowItemProps) => {
  const { debet, isTomorrow } = props;

  const currentDay = new Date();
  const currentMonth = new Date(
    new Date(currentDay).setDate(currentDay.getDate() + 1)
  );
  const todayDate = `${currentDay.getDate().toString()} ${
    MONTHS[currentDay.getMonth()]
  } ${currentDay.getFullYear().toString()} года`;
  const tomorrowDate = `${currentMonth.getDate().toString()} ${
    MONTHS[currentMonth.getMonth()]
  } ${currentMonth.getFullYear().toString()} года`;

  return (
    <div className={styles.wrapper}>
      <div className={styles.topBlock}>
        <div className={styles.row}>
          <span className={styles.title}>
            {isTomorrow ? "Завтра" : "Сегодня"}
          </span>
          <span className={styles.date}>
            {isTomorrow ? tomorrowDate : todayDate}
          </span>
        </div>
      </div>
      <div className={styles.infoBlock}>
        <div className={styles.cash}>
          {debet.need_to_pay >= 0 && (
            <div className={styles.row}>
              <span className={styles.cashItemName}>
                Необходимо срочно погасить задолженность
              </span>
              <span className={styles.cashItemSum}>
                {debet.need_to_pay}&nbsp;₽
              </span>
            </div>
          )}
          {debet.expectation_to_pay >= 0 && (
            <div className={styles.row}>
              <span className={styles.cashItemName}>
                Ожидаем оплату на сумму
              </span>
              <span className={styles.cashItemSum}>
                {debet.expectation_to_pay}&nbsp;₽
              </span>
            </div>
          )}
          {debet.near_to_pay >= 0 && (
            <div className={styles.row}>
              <span className={styles.cashItemName}>Ближайший платёж</span>
              <span className={styles.cashItemSum}>
                {debet.near_to_pay}&nbsp;₽
              </span>
            </div>
          )}
          {debet.full_debet >= 0 && (
            <div className={styles.row}>
              <span className={styles.cashItemName}>Итого</span>
              <span className={styles.cashItemSum}>
                {debet.full_debet}&nbsp;₽
              </span>
            </div>
          )}
        </div>
        {/* TODO: debet links */}
        {/* <div className={styles.links}></div> */}
      </div>
    </div>
  );
};
